import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { Crumb } from 'src/app/domain/domain-shell/domain-shell.component';

// The VizToolkit breadcrumb component was difficult to use b/c we need a step to insert custom logic for resolving param names
@Component({
  selector: 'app-breadcrumbs',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./breadcrumbs.component.scss'],
  imports: [NgFor,NgIf, RouterModule, MatIconModule],
  template: `
    <div *ngIf="breadcrumbs" class="breadcrumb">
      <ng-container *ngFor="let crumb of breadcrumbs; trackBy: trackByUrl;">
        <a [routerLink]="crumb.link" routerLinkActive="active-link">{{ crumb.label }}</a>
        <div id="chevron-spacer">&gt;</div>
      </ng-container>
    </div>
  `,
  standalone: true,
})
export class BreadCrumbsComponent {
  @Input() breadcrumbs: Crumb[];

  constructor() {}

  trackByUrl(index, crumb) {
    return crumb.link;
  }
}
