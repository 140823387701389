import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import { LayoutService } from 'src/app/services';
import { SharedModule } from 'src/app/shared';

type ViewStyle = 'list' | 'grid';

@Component({
  selector: 'app-list-or-card-toggle',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './list-or-card-toggle.component.html',
  styleUrls: ['./list-or-card-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListOrCardToggleComponent {
  readonly viewMode$ = this.layoutService.viewMode$;

  constructor(private readonly layoutService: LayoutService) { }

  toggleListView() {
    this.layoutService.changeViewMode('list');
  }

  toggleGridView() {
    this.layoutService.changeViewMode('grid');
  }
}
