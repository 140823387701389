import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, of } from 'rxjs';
import {
  NotificationDto,
  ScheduleDto,
  ScheduleTrackingStatusUpdateDto,
  DepScheduleStatusIdType,
} from 'src/app/models';
import { DepFrequencyPipe, ScheduleTrackingStatusPipe } from 'src/app/pipes';
import { AuthService } from 'src/app/services';
import { DataShareService, ScheduleService, UserNotificationService, UserService, } from 'src/app/services';
import { ResponseStatus } from 'src/app/enums';
import { SharedModule } from 'src/app/shared';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  imports: [
    SharedModule,
    DepFrequencyPipe,
    ScheduleTrackingStatusPipe,
  ],
})
export class DashboardComponent implements OnInit {
  authenticated$: Observable<boolean>;
  userId: number;
  isAuthenticated$: Observable<boolean>;

  dataSetSchedules: MatTableDataSource<ScheduleDto>;
  dataSetExtracts: MatTableDataSource<ScheduleTrackingStatusUpdateDto>;
  notifications: MatTableDataSource<NotificationDto>;

  userExtractView$: Observable<boolean> = of(false);

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    readonly authService: AuthService,
    private readonly scheduleService: ScheduleService,
    private readonly userService: UserService,
    private readonly notificationService: UserNotificationService,
    private readonly dataShareService: DataShareService
  ) {
    this.isAuthenticated$ = authService.authenticated$;
  }

  ngOnInit(): void {
    this.userService.getLoggedInUser().subscribe(
      user => {
        this.userId = user.userId;

        this.scheduleService
          .getTopSchedules(this.userId, null, 10)
          .subscribe(schedules => {
            if (schedules && schedules.length > 0) {
              this.dataSetSchedules = new MatTableDataSource<ScheduleDto>(schedules);
            }

            // for UI to display startdate for enddate for Now and One-time
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let index = 0; index < schedules.length; index++) {
              // 1 = Now | 2 = One-time
              if (
                schedules[index].depFrequencyCodeId === 1 ||
                schedules[index].depFrequencyCodeId === 2
              ) {
                schedules[index].frequencyEndDt = schedules[index].frequencyStartDt;
              }

              // Filter schedules status by status Id
              schedules[index].depScheduleStatusStatus =
                DepScheduleStatusIdType[schedules[index].depScheduleStatusId.toString()] === 'Canceled'
                  ? 'Completed'
                  : DepScheduleStatusIdType[schedules[index].depScheduleStatusId.toString()];
            }
          });

        this.scheduleService
          .getExtracts(this.userId, 10)
          .subscribe(extracts => {
            if (extracts && extracts.length > 0) {
              this.dataSetExtracts = new MatTableDataSource<ScheduleTrackingStatusUpdateDto>(extracts);
            }
          });

        this.notificationService
          .getNotifications(this.userId, 10)
          .subscribe(notifications => {
            if (notifications && notifications.length > 0) {
              this.notifications = new MatTableDataSource<NotificationDto>(notifications);
            }
          });
      },
      (error) => {
        if (error.status === ResponseStatus.Unauthorized) {
          this.dataShareService.setSessionExpired(true);
          this.router.navigate(['/']);
        }
      }
    );

  }

  viewExtract(extract: ScheduleDto): void {
    this.userExtractView$ = of(true);

    this.router.navigate(
      ['../schedules',extract.scheduleId],
      {
        relativeTo: this.route,
        state: {
          datasetId: extract.datasetId,
          isView: true,
        },
      }
    );
  }
}
