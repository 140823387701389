<ng-container [formGroup]="filterForm">

  <mat-form-field appearance="fill">
    <mat-label>{{isMultiple ? 'Multiple': 'Single'}} Selection</mat-label>

    <mat-chip-list #chipList>
      <mat-chip
        *ngFor="
        let value of filterForm.controls.userValue.value?.constructor.name === 'Array' ?
        filterForm.controls.userValue.value :
        filterForm.controls.userValue.value ? 
        [ filterForm.controls.userValue.value ] : []"
        (removed)="removeUserValue(value)"
      >
        {{ value }}
        <button matChipRemove *ngIf="! isViewEnabled">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
      <input
        [formControl]="filterInput"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [value]="filterInput.value"
        *ngIf="! isViewEnabled"
        matInput
        >
    </mat-chip-list>

    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="onOptionClick($event)"
    >
      <mat-option
        *ngFor="let i of filteredValues"
        [value]="i.value"
        matTooltip="{{ i.uivalue }}"
      >
        {{ i.uiValue }}
      </mat-option>
    </mat-autocomplete>

    <mat-error *ngIf="filterForm.controls.userValue.errors?.['required'] && (filterForm.controls.userValue.dirty || filterForm.controls.userValue.touched)">
      Please select a selection filter
    </mat-error>
  </mat-form-field>

</ng-container>
