<div *ngIf="(viewMode$ | async) === 'grid'; else listView">
  <a data-test="list-icon" (click)="toggleListView()">
    <mat-icon
      fontSet="fa"
      fontIcon="fa-list"
      ngClass="icon-inactive"
    ></mat-icon>
  </a>

  <a data-test="grid-icon" (click)="toggleGridView()">
    <mat-icon
      fontSet="fa"
      fontIcon="fa-th-large"
      ngClass="icon-active"
    ></mat-icon>
  </a>
</div>

<ng-template #listView>
  <a (click)="toggleListView()">
    <mat-icon fontSet="fa" fontIcon="fa-list" ngClass="icon-active"></mat-icon>
  </a>

  <a (click)="toggleGridView()">
    <mat-icon
      fontSet="fa"
      fontIcon="fa-th-large"
      ngClass="icon-inactive"
    ></mat-icon>
  </a>
</ng-template>
