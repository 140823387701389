import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
 // TODO: not sure these actually need to be imported here
import { CardTitleComponent, DomainCollectionDescriptionComponent } from './components-standalone';
import { VizAuthInterceptorService } from './interceptors';
import { SharedModule } from './shared';
import { AuthService } from './services';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { COMPONENTS } from './components';
import { APP_ROUTES } from './app.routes';

@NgModule({
  declarations: [AppComponent, ...COMPONENTS],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: VizAuthInterceptorService,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: async () => {
        await inject(AuthService).initAuthService();
      },
    },
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    // TODO; should we have this legacy value or can this be removed?
    RouterModule.forRoot(APP_ROUTES, { relativeLinkResolution: 'legacy' }),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CardTitleComponent,
    DomainCollectionDescriptionComponent,
    HttpClientModule,
    SharedModule,
    SafeUrlPipe,
  ],
})
export class AppModule {}
