/* eslint-disable max-len */
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  forwardRef,
} from '@angular/core';

import {
  FiltersColumnsComponent
} from '../filters-columns/filters-columns.component';
import { DatasetFieldService, NotificationService } from 'src/app/services';
import {
  DatasetField,
  SubDataset,
  DepFilterCodeIdType,
  DatasetFieldUserDefinitionDto,
} from 'src/app/models';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup, NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors,
  Validator,
  Validators} from '@angular/forms';
import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { SharedModule } from 'src/app/shared';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-filters-tab-content',
  standalone: true,
  templateUrl: './filters-tab-content.component.html',
  styleUrls: ['./filters-tab-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FiltersTabContentComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FiltersTabContentComponent),
      multi: true,
    },
  ],
  animations: [
    trigger('slideStatus', [
      transition('* => active', [
        animate(
          '2s',
          keyframes([
            style({
              backgroundColor: '#7f5eba',
              offset: 0,
              marginTop: '5px',
              color: 'white',
            }),
            style({
              backgroundColor: '#9273c9',
              offset: 0.5,
              marginTop: '5px',
              color: 'rgb(0,0,0,0)',
            }),
            style({
              backgroundColor: 'rgb(0,0,0,0)',
              offset: 1.0,
              marginTop: '5px',
            }),
          ])
        ),
      ]),
    ]),
  ],
  imports: [
    SharedModule,
    FiltersColumnsComponent,
  ],
})
export class FiltersTabContentComponent implements OnInit, OnDestroy, Validator, ControlValueAccessor {
  @Input() isCommon!: boolean;
  @Input() datasetFields!: DatasetField[];
  @Input() subdataset: SubDataset;
  @Input() datasetFieldsUserDefinition: DatasetFieldUserDefinitionDto[] | undefined;

  status = 'open';
  filtersTabContentForm: FormGroup;
  isViewEnabled = true;

  private componentDestroyed$: Subject<boolean>;

  constructor(
    private readonly notificationService: NotificationService,
    private readonly formBuilder: FormBuilder,
    private readonly datasetFieldService: DatasetFieldService
  ) {
    this.componentDestroyed$ = new Subject();

    this.datasetFieldService
        .commonDatasetFieldChanged$
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          commonDatasetFieldValue => this.updateDatasetField(commonDatasetFieldValue)
        );
  }

  private updateDatasetField(commonDatasetFieldValue: { dbFieldName: string; selectedFilterCodeId: string; userValue: any }): void {
    if(this.isCommon) //prevents the common tab from overwriting its own changes
      return;

    const filtersForms = ((this.filtersTabContentForm.controls.filtersFormArray as FormArray).controls as FormGroup[]);
    const filterFormForDbFieldName = filtersForms.find(filterForm => filterForm.controls.dbFieldName.value === commonDatasetFieldValue.dbFieldName);

    if (filterFormForDbFieldName.controls.commonFilter.value) { //this flag indicates if filter values will be updated when main common filter change
      filterFormForDbFieldName.controls.selectedFilterCodeId.setValue(commonDatasetFieldValue.selectedFilterCodeId);
      filterFormForDbFieldName.controls.userValue.setValue(commonDatasetFieldValue.userValue);
      filterFormForDbFieldName.controls.userValue.setErrors(null);
      filterFormForDbFieldName.controls.userValue.markAsPristine();
      filterFormForDbFieldName.controls.userValue.markAsUntouched();
    }
  }

  ngOnInit(): void {
    if (history.state.isCreate) {
      this.isViewEnabled = false;
    } else {
      this.isViewEnabled = history.state.isView ?? true;
    }

    this.buildFiltersTabContentForm();

    this.datasetFields.forEach(datasetField => {
      if (this.datasetFieldIsFilterable(datasetField)) {
        if (this.datasetFieldsUserDefinition && this.datasetFieldsUserDefinition.length) //only for "view/update extract" mode
          this.fillFilteredDatasetFieldValue(datasetField, this.datasetFieldsUserDefinition);

        if (datasetField.mandatoryFilter)
          this.datasetFieldService.updateMandatoryFilter(datasetField.uiName, false);

        (this.filtersTabContentForm.controls.filtersFormArray as FormArray).push(this.buildAndSetUpFilterForm(datasetField));
      }
    });
  }

  private buildFiltersTabContentForm() {
    this.filtersTabContentForm = this.formBuilder.group({
      filtersFormArray: this.formBuilder.array([]),
      subDatasetId: this.isCommon ? undefined : this.subdataset.subDatasetId,
      subDatasetName: this.isCommon ? undefined : this.subdataset.uiName,
    });
  }

  private datasetFieldIsFilterable(datasetField: DatasetField): boolean {
    return datasetField.depFilterCodeId && Object.values(DepFilterCodeIdType).includes(datasetField.depFilterCodeId);
  }

  private fillFilteredDatasetFieldValue(datasetField: DatasetField, datasetFieldsUserDefinition: DatasetFieldUserDefinitionDto[]): void {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const predicate = datasetFieldsUserDefinition[0]['datasetFieldId']
      ? fdf => fdf.datasetFieldId === datasetField.datasetFieldId //DatasetFieldDto type
      : fdf => fdf.datasetFieldDbFieldName === datasetField.dbFieldName; //ScheduleCommonDatasetFieldUserDefinitionDto type

    const datasetFieldUserDefinition = datasetFieldsUserDefinition.find(predicate);

    if (datasetFieldUserDefinition) {
      datasetField.selectedFilterCodeId = datasetFieldUserDefinition.selectedFilterCodeId.toString();
      datasetField.userValue = datasetFieldUserDefinition.userValue.toString();
    }
  }

  private buildAndSetUpFilterForm(datasetField: DatasetField): FormGroup {
    const filterForm = this.formBuilder.group({
      isContainedInACommonFiltersTab: this.isCommon,

      //DatasetField props
      datasetFieldId: datasetField.datasetFieldId,
      subDatasetId: datasetField.subDatasetId,
      depFilterCodeId: datasetField.depFilterCodeId,
      uiName: datasetField.uiName,
      dbFieldName: datasetField.dbFieldName,
      uiDescription: datasetField.uiDescription,
      fieldTextLength: datasetField.fieldTextLength,
      uiFilterHelpText: datasetField.uiFilterHelpText,
      datasetFieldMultivalues: [{ value: datasetField.datasetFieldMultivalues }],
      uiHeaderText: datasetField.uiHeaderText,
      mandatoryFilter: datasetField.mandatoryFilter,
      commonFilter: datasetField.commonFilter,

      //DatasetFieldUserDefinition props
      selectedFilterCodeId: [{value: datasetField.selectedFilterCodeId, disabled: this.isViewEnabled }],
      userValue: [{ value: datasetField.userValue, disabled: this.isViewEnabled }],
    });

    if (datasetField.mandatoryFilter) {
      filterForm.controls.userValue.setValidators([Validators.required]);
    }

    filterForm.valueChanges.subscribe(value => {
      const fieldName = filterForm.value.uiName;
      if (filterForm.value.mandatoryFilter) {
        // If val is empty or undefined or null
        if (typeof value.userValue === 'string') {
          if(!value.userValue) {
            // If it is not in the map, add it
            this.datasetFieldService.updateMandatoryFilter(fieldName, false);
          } else {
            this.datasetFieldService.updateMandatoryFilter(fieldName, true);
          }
        } else if (Array.isArray(value.userValue)) {
          if ((value.userValue as Array<any>).length === 0) {
            this.datasetFieldService.updateMandatoryFilter(fieldName, false);
          } else {
            this.datasetFieldService.updateMandatoryFilter(fieldName, true);
          }
        }
      }
    });

    return filterForm;
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  toogleView() {
    if (this.status === 'open') {
      this.status = 'close';
      this.notificationService.panelMessage('close');
    } else {
      this.status = 'open';
      this.notificationService.panelMessage('open');
    }
  }

  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    if (val) {
      this.filtersTabContentForm.setValue(val, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.filtersTabContentForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled)
      this.filtersTabContentForm.disable();
    else
      this.filtersTabContentForm.enable();
  }

  validate(control: AbstractControl<any, any>): ValidationErrors {
    return this.filtersTabContentForm.valid
      ? null
      : {
          invalidFOrm: {
            valid: false,
            message: 'filtersTabContentForm fields are invalid',
          },
        };
  }
}
