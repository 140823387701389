<h3>Dashboard</h3>

<!-- Schedules Card -->
<label class="header-label">Schedules</label>
<br />
<mat-table [dataSource]="dataSetSchedules" class="mat-elevation-z8">

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef class="center">Name</mat-header-cell>
    <mat-cell *matCellDef="let extract" class="center"><a (click)="viewExtract(extract)" class="title">
      {{ extract.name }}
    </a></mat-cell>
  </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
      <mat-cell *matCellDef="let extract">{{ extract.description }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="depFrequencyCodeId">
      <mat-header-cell *matHeaderCellDef class="center">Frequency</mat-header-cell>
      <mat-cell *matCellDef="let extract" class="center">{{ extract.depFrequencyCodeId | depFrequency}}</mat-cell>
    </ng-container>    

    <ng-container matColumnDef="depScheduleStatusStatus">
      <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
      <mat-cell *matCellDef="let extract">{{ extract.depScheduleStatusStatus }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="frequencyStartDt">
      <mat-header-cell *matHeaderCellDef>Frequency Start Date</mat-header-cell>
      <mat-cell *matCellDef="let extract">{{ extract.frequencyStartDt | date: 'shortDate' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="frequencyEndDt">
      <mat-header-cell *matHeaderCellDef>Frequency End Date</mat-header-cell>
      <mat-cell *matCellDef="let extract">{{ extract.frequencyEndDt | date: 'shortDate' }}</mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="['name', 'description', 'depScheduleStatusStatus', 'depFrequencyCodeId', 'frequencyStartDt', 'frequencyEndDt']"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: ['name', 'description', 'depScheduleStatusStatus', 'depFrequencyCodeId', 'frequencyStartDt', 'frequencyEndDt']"></mat-row>
  </mat-table>
<br />

<!-- Extracts Card -->
<label class="header-label">Extracts</label><br />
<mat-table [dataSource]="dataSetExtracts" class="mat-elevation-z8">

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef class="center">Name</mat-header-cell>
    <mat-cell *matCellDef="let extract" class="center"><a (click)="viewExtract(extract)" class="title">
      {{ extract.name }}
    </a></mat-cell>
  </ng-container>

  <ng-container matColumnDef="depScheduleTrackingStatusId">
    <mat-header-cell *matHeaderCellDef class="center">Status</mat-header-cell>
    <mat-cell *matCellDef="let extract" class="center">
      {{ extract.depScheduleTrackingStatusId | depScheduleTrackingStatus}}
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="['name','depScheduleTrackingStatusId']"></mat-header-row>
  <mat-row *matRowDef="let row; columns: ['name','depScheduleTrackingStatusId']"></mat-row>
</mat-table>
<br />

<!-- Notifications Card -->
<label class="header-label">Notifications</label><br />
<mat-table [dataSource]="notifications" class="mat-elevation-z8">

  <ng-container matColumnDef="createdDtTm">
    <mat-header-cell *matHeaderCellDef class="center date-column">Date</mat-header-cell>
    <mat-cell *matCellDef="let notification" class="center date-column">{{ notification.createdDtTm | date: "MM-dd-yyyy h:mm a"}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="message">
    <mat-header-cell *matHeaderCellDef class="center notification-message-column ">Message</mat-header-cell>
    <mat-cell *matCellDef="let notification" class="center notification-message-column"><label>{{notification.subject}}
      </label></mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="['createdDtTm','message']"></mat-header-row>
  <mat-row *matRowDef="let row; columns: ['createdDtTm','message']"></mat-row>
</mat-table>