import { Injectable } from '@angular/core';
import { PassingComponentData } from '../models';

/**
 * Used to write and read from local browser storage
 * and consumed by components
 */
@Injectable({
  providedIn: 'root',
})
export class SessionManagementService {
  /**
   * sets the datasetId
   *
   * @param id - domain id
   */
  setDatasetId(id: number) {
    localStorage.setItem('datasetId', JSON.stringify(id));
  }

  /**
   * gets the datasetId
   */
  getDatasetId() {
    return localStorage.getItem('datasetId');
  }

  /**
   * removes domainId and datasetId
   */
  removeAll() {
    localStorage.removeItem('domainId');
    localStorage.removeItem('datasetId');
  }

  /**
   * sets the object
   *
   * @param data - interface object to be saved
   */
  setPassingComponentData(data: PassingComponentData) {
    localStorage.setItem('passingComponentData', JSON.stringify(data));
  }

  /**
   * gets the object
   */
  getPassingComponentData(): PassingComponentData {
    return JSON.parse(localStorage.getItem('passingComponentData')) as PassingComponentData;
  }
}
