<!-- <ng-container *ngIf="(subDomainView$ | async) === false; else subDomainView"> -->
  <div data-test="title" class="viz-h3 justify-space-between">
    {{ welcomeText }}
  </div>

  <br />

  <div data-test="sub-title" class="viz-body-1">
    {{ welcomeDescription }}
  </div>

  <br />

  <div id="layout-actions-row" class="justify-space-between">
    <app-list-or-card-toggle></app-list-or-card-toggle>

    <app-expand-or-compress-toggle 
      *ngIf="(viewStyle$ | async)! === 'list'" 
      (expandOrContract)="expandOrContract($event)"
    ></app-expand-or-compress-toggle>
  </div>

  <br />

  <div *ngIf="(viewStyle$ | async)! === 'grid'; else listView" class="api-collections-wrapper flex-wrap" style="display: flex">
    <div *ngFor="let domaincollection of domainCollections | filterDomains" style="margin: 10px">
      <app-collection-card [domain]="domaincollection" (openSubDomainView)="navigateToDomain($event)"></app-collection-card>
    </div>
  </div>

  <ng-template #listView>
    <mat-accordion multi>
      <div *ngFor="let domaincollection of domainCollections | filterDomains" style="margin: 10px">
        <app-collection-panel (openSubDomainView)="navigateToDomain($event)" [domain]="domaincollection"></app-collection-panel>
      </div>
    </mat-accordion>
  </ng-template>
<!-- </ng-container> -->

<!-- <ng-template #subDomainView>
  <router-outlet (deactivate)="onDeactivateRouterOutlet()"></router-outlet>
</ng-template> -->