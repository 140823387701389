<ng-container [formGroup]="filtersTabContentForm">

  <ng-container *ngIf="this.filtersTabContentForm.get('filtersFormArray')" formArrayName="filtersFormArray">

    <div class="row">
        <app-filters-column
          [filtersForms]="leftFiltersForms"
          [filtersTabContentForm]="filtersTabContentForm"
        ></app-filters-column>

        <app-filters-column
          [filtersForms]="rightFiltersForms"
          [filtersTabContentForm]="filtersTabContentForm"
        ></app-filters-column>
    </div>

  </ng-container>

</ng-container>
