
import { Inject } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationDto } from 'src/app/models';

@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss']
})
export class NotificationPopupComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public notification: NotificationDto,
    private dialogRef: MatDialogRef<NotificationPopupComponent>
  ) { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

