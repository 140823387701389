/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/prefer-for-of */
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of, Subscription, forkJoin, BehaviorSubject } from 'rxjs';
import {
  Dataset,
  DatasetField,
  DatasetFieldDto,
  IList,
  ScheduleDto,
  SubDataset,
  DomainData,
  IDatasetFrequencyCode,
  DepFrequencyCodeIdType,
  DepChannelIdType,
  UserProductRole,
  DepRollingDateFrequencyCodeIdType,
  DepFilterCodeIdType,
  DepScheduleStatusIdType,
  ScheduleCommonDatasetFieldUserDefinitionDto,
  DatasetFieldUserDefinitionDto,
  DatasetChannelDto,
  DatasetOutputFileCompressionFormatDto,
  DatasetOutputFileTypeDto,
} from 'src/app/models';
import {
  FiltersTabContentComponent,
  OutputChannelComponent,
  ScheduleComponent,
  FieldsTabContentComponent,
  MissingSetUpDataDialogComponent
} from 'src/app/components-standalone';
import { DepChannel, DepFrequencyCode, ProductMetisId } from 'src/app/enums';
import { AuthService } from 'src/app/services';
import { DatasetFieldService, DataSetService, DataShareService, ScheduleService, UserService } from 'src/app/services';
import { groupBy, SharedModule } from 'src/app/shared';
import { HeaderLessTabsDirective } from 'src/app/directives';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Location } from '@angular/common';

@Component({
  selector: 'app-user-extract',
  standalone: true,
  templateUrl: './user-extract.component.html',
  styleUrls: ['./user-extract.component.scss'],
  imports: [
    SharedModule,
    ReactiveFormsModule,
    FiltersTabContentComponent,
    OutputChannelComponent,
    ScheduleComponent,
    FieldsTabContentComponent,
    HeaderLessTabsDirective
  ],
})
export class UserExtractComponent implements OnInit, OnChanges {
  @ViewChild('stepper') private stepper: MatStepper;

  readonly commonTabLabel = 'Common';
  commonDatasetFields: DatasetField[] = [];
  scheduleStepForm: FormControl;
  datasetId: number;
  extractId: number;
  labelPosition: 'after';
  subDatasets: SubDataset[] = [];
  datasetFieldsBySubDatasetId: Map<number, DatasetField[]> = new Map();;
  isEdit: boolean;
  saveDraftButtonText = 'Save as Draft';
  responseMessage = '';
  isViewEnabled = true;
  form: FormGroup;
  userId: number;
  summaryStepView: IList[] = [];

  stepSetupCompleted = false;
  stepScheduleCompleted = false;
  stepFieldsCompleted = false;
  stepFiltersCompleted = false;
  setStepFiltersCompleted = false;
  stepOutputCompleted = false;

  scheduleDepFrequencyCodeId: number;
  private readonly loading = new BehaviorSubject<boolean>(false);
  readonly loading$ = this.loading.asObservable();
  formChanged: boolean;
  datasetName: string;
  datasetDescription: string;
  getDatasetFrequenciesSub: Subscription;
  frequencies: IDatasetFrequencyCode[] = [];
  depChannelIdType = DepChannelIdType;
  requiredIncludedDatasetFieldsTabs: IList[] = [];
  resetflag = true;
  members: Observable<UserProductRole[]>;
  depRollingDateFrequencyCodeIdType = DepRollingDateFrequencyCodeIdType;
  dataSetRollingDates;
  minDate: Date;
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  isDisableDateRange = true;
  isDisableRollingDate = true;
  isCheckedRollingDateOneTime = false;
  isCheckedRollingDateRecurring = false;
  clients: Observable<any[]>;
  schedule: ScheduleDto;
  productId: number;
  dataset: Dataset;
  memberShortName: string;
  stepFiltersCompleted$: Observable<boolean>;

  channels: DatasetChannelDto[];
  fileTypes: DatasetOutputFileTypeDto[];
  fileCompressionFormats: DatasetOutputFileCompressionFormatDto[];

  ProductMetisId = ProductMetisId;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly datasetService: DataSetService,
    private readonly scheduleService: ScheduleService,
    private readonly activatedRoute: ActivatedRoute,
    readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly dataShareService: DataShareService,
    private readonly cdr: ChangeDetectorRef,
    private readonly datasetFieldService: DatasetFieldService,
    private readonly dialog: MatDialog,
    private readonly location: Location
  ) {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDay = new Date().getDate();
    this.minDate = new Date(currentYear - 10, currentMonth, currentDay);

    this.datasetFieldService.emptyMandatoryFilters();

    this.stepFiltersCompleted$ = this.datasetFieldService.checkIfAllMandatoryFieldsAreTrue$();
    this.stepFiltersCompleted$.subscribe(stepFiltersCompleted => this.setStepFiltersCompleted = stepFiltersCompleted);
  }

  ngOnChanges(): void {
    this.onChanges();

    this.datasetFieldService.emptyMandatoryFilters();
  }

  ngOnInit(): void {
    const parentData = this.activatedRoute.parent.snapshot.data;
    const domainData = parentData['domainData'] as DomainData;

    const routeParams = this.activatedRoute.snapshot.params;
    this.loading.next(true);

    // Do not allow direct navigation to this page.
    // TODO: we should consider adding a compliance guard to this page
    if (!history.state.isCreate && !history.state.isEdit && !history.state.isView) {
      this.location.back();
    }

    if (history.state.isCreate) {
      this.isEdit = false;
      this.isViewEnabled = false;
    } else {
      this.isEdit = history.state.isEdit;
      this.isViewEnabled = history.state.isView ?? true;
    }

    // get user id
    this.userService.getLoggedInUser().subscribe(user => this.userId = user.userId);

    this.activatedRoute.params.subscribe(({ scheduleId }) => {
      if (scheduleId) {//"view/update extract" mode
        this.extractId = scheduleId;
        this.loadScheduleFromWebApi(this.extractId);
      } else {//"create extract" mode
        // this.datasetId = history.state.datasetId;  // TODO: this needs to be refactored, this is why direct navigation to subpages is not working
        this.datasetId = routeParams['datasetId'];
        this.loadDatasetDependenciesFromWebApi(this.datasetId);
      }
    });

    this.datasetName = domainData?.domainName;
    this.datasetDescription = domainData?.domainDescription;
    // // get data from local storage
    // this.dataShareService.domainData$.subscribe((domainData: DomainData) => {
    //   this.datasetName = domainData.domainName;
    //   this.datasetDescription = domainData.domainDescription;
    // });
  }

  @HostListener('window:beforeunload')
  updatedForm(e) {
    if (this.formChanged) {
      (e || window.event).returnValue = true;
    }
  }

  onChanges() {
    this.formChanged = true;
  }

  private loadScheduleFromWebApi(extractId: number){
    this.scheduleService.getById(extractId).subscribe(schedule => {
      this.schedule = schedule;

      this.datasetId = this.schedule.datasetId;

      this.loadDatasetDependenciesFromWebApi(this.datasetId);
    });
  }

  private loadDatasetDependenciesFromWebApi(datasetId: number) {
    forkJoin(
      [
        this.datasetService.getSubDataSetsApi(datasetId),
        this.datasetService.getDataSetApi(datasetId),
        this.datasetService.getDatasetRollingDates(datasetId),
        this.datasetService.getDatasetFrequencies(datasetId),
        this.datasetService.getDatasetChannels(datasetId),
        this.datasetService.getDatasetOutputFileTypes(datasetId),
        this.datasetService.getDatasetOutputFileCompressionFormats(datasetId),
      ]
    ).subscribe(
      (
        [
          subDataSets,
          dataset,
          rollingDates,
          frequencies,
          channels,
          fileTypes,
          fileCompressionFormats,
        ]
      ) => {
        this.subDatasets = subDataSets;

        this.dataset = dataset;

        this.dataSetRollingDates = rollingDates.filter(
          rd => rd.depRollingDateFrequencyCodeId !== 5
        );

        this.validateDependecies(
          frequencies,
          channels,
          fileTypes,
          fileCompressionFormats,
        );

        this.frequencies = frequencies;

        this.channels = channels;

        this.fileTypes = fileTypes;

        this.fileCompressionFormats = fileCompressionFormats;

        this.buildAndSetUpForm();

        const clientsOrMembersObservable = this.datasetIsClientIdBased(dataset)
          ? this.userService.getLoggedInUserProductClients(this.dataset.productId)
          : this.userService.getLoggedInUserProductRoles(this.dataset.productId, this.dataset.roleId);

        clientsOrMembersObservable.subscribe(clientsOrMembers => {
          // TODO; move this logic out of the component and into a routing guard
          if (clientsOrMembers.length === 0) {
            this.dialog.open(
              MissingSetUpDataDialogComponent,
              {
                data: 'No client IDs are currently associated with your profile.',
                disableClose: true
              }
            );
            return;
          }

          if (this.datasetIsClientIdBased(dataset)) {
            this.clients = of(clientsOrMembers);

            if (clientsOrMembers.length === 1) {
              this.memberShortName = clientsOrMembers[0].uiValue;
            }
          } else {
            this.members = of(clientsOrMembers);

            if (clientsOrMembers.length === 1) {
              this.memberShortName = clientsOrMembers[0].member_name;
            }
          }
          if (this.schedule) { //"view/update extract" mode
            // actual patching of data to form(s) fields and "Summary Step View"
            this.form.patchValue({
              extractName: this.schedule.name,
              notes: this.schedule.description,
              memberId: this.schedule.memberId,
              rollingDateType: this.schedule.subDefinitions[0].rollingDateType,
              rollingDateRange: this.assignRollingDateRange(this.schedule),
              scheduleStepForm: {
                scheduleRadioValue: this.schedule.depFrequencyCodeId,
                selectedDateOneTime: this.schedule.oneTimeDt,
                selectedDatQuarterly:
                  this.schedule.depFrequencyCodeId === DepFrequencyCode.Quarterly
                    ? this.schedule.frequencyStartDt
                    : null,
                selectedDateDaily:
                  this.schedule.depFrequencyCodeId === DepFrequencyCode.Daily
                    ? this.schedule.frequencyStartDt
                    : null,
                selectedDatMonthly:
                  this.schedule.depFrequencyCodeId === DepFrequencyCode.Monthly
                    ? this.schedule.frequencyStartDt
                    : null,
                scheduledEndDate: this.schedule.frequencyEndDt,
              },
              outputStepForm: {
                channelName: this.channels.find(c => c.depChannelId === this.schedule.depChannelId).uiName,
                storageAccountId: this.schedule.depChannelId === DepChannel.MemberSFTP ? this.schedule.storageAccountId : undefined,
                azureBlobStorageAccountId: this.schedule.depChannelId === DepChannel.AzureBlobStorage ? this.schedule.storageAccountId : undefined,
                fileType: this.fileTypes.find(ft => ft.depOutputFileTypeId === this.schedule.depOutputFileTypeId).uiName,
                fileCompressionFormat: this.fileCompressionFormats.find(fcf => fcf.depOutputFileCompressionFormatId === this.schedule.depOutputFileCompressionFormatId).uiName,
                fileName: this.schedule.fileName,
              },
            });

            // in disable mode
            if (this.isViewEnabled) {
              this.f.extractName.disable();
              this.f.notes.disable();
              this.f.extractName.disable();
              this.f.memberId.disable();
              this.isDisableDateRange = true;
              this.isDisableRollingDate = true;
            }

            // this will be passed into nexted controls to patch
            this.scheduleDepFrequencyCodeId = this.schedule.depFrequencyCodeId;

            this.checkRollingDateRadioBtn(this.schedule);

            // on patch/edit set button text (hidden if isViewEnabled)
            this.saveDraftButtonText = 'Update draft';
          } else {
            if (clientsOrMembers.length === 1) {
              this.f.memberId.setValue(// sets the single value in dropdown as default
                this.datasetIsClientIdBased(dataset)
                  ? clientsOrMembers[0].value
                  : clientsOrMembers[0].member_id
              );
            }
          }

          this.loadDatasetFieldsFromWebApi(this.subDatasets, this.f.memberId.value);
        });

        // add item to subDataset required dataset fields indicator
        this.requiredIncludedDatasetFieldsTabs = this.subDatasets.map(s =>  ({ key: 'reqIncDsField', value: '*' }));
    });
  }

  private validateDependecies(
    frequencies: IDatasetFrequencyCode[],
    channels: DatasetChannelDto[],
    fileTypes: DatasetOutputFileTypeDto[],
    fileCompressionFormats: DatasetOutputFileCompressionFormatDto[]
  ) {
    let errorMsg = '';
    errorMsg += !frequencies || (frequencies && frequencies.length === 0) ? 'No "Frequencies" are currently associated with this Dataset. ' : '';
    errorMsg += !channels || (channels && channels.length === 0) ? 'No "Delivery channels" are currently associated with this Dataset. ' : '';
    errorMsg += !fileTypes || (fileTypes && fileTypes.length === 0) ? 'No "File types" are currently associated with this Dataset. ' : '';
    errorMsg += !fileCompressionFormats || (fileCompressionFormats && fileCompressionFormats.length === 0) ? 'No "File compression formats" are currently associated with this Dataset. ' : '';

    if (errorMsg) {
      this.dialog.open(
        MissingSetUpDataDialogComponent,
        {
          data: errorMsg,
          disableClose: true
        }
      );
    }
  }

  private loadDatasetFieldsFromWebApi(subDatasets: SubDataset[], memberId: number) {
    let subDatasetsToProcess = subDatasets.length;
    subDatasets.forEach(subdataset => {
      this.datasetFieldService.get(
        subdataset.subDatasetId,
        false,
        memberId
      ).subscribe(datasetFields => {
        this.datasetFieldsBySubDatasetId.set(subdataset.subDatasetId, datasetFields);
        subDatasetsToProcess--;

        if (subDatasetsToProcess === 0) {
          this.setCommonDatasetFields(this.datasetFieldsBySubDatasetId);
          this.loading.next(false);
        }
      });
    });
  }

  private setCommonDatasetFields(datasetFieldsBySubDatasetId: Map<number, DatasetField[]>) {
    let allDatasetFields: DatasetField[] = [];
    for (const datasetFields of datasetFieldsBySubDatasetId.values()) {
      allDatasetFields = allDatasetFields.concat(datasetFields.filter(df => df.depFilterCodeId));
    }

    const datasetFieldsByDbFieldName = groupBy(allDatasetFields, datasetField => datasetField.dbFieldName);
    for (const datasetFields of datasetFieldsByDbFieldName.values()) {
      if(datasetFields.length >= 2 && this.atLeastOneDatasetFieldIsCommon(datasetFields)) {
        const commonDatasetField = datasetFields[0];

        if(
          (
            commonDatasetField.depFilterCodeId === DepFilterCodeIdType.SINGLE_UI_SELECTION ||
            commonDatasetField.depFilterCodeId === DepFilterCodeIdType.MULTI_UI_SELECTION
          ) &&
          this.allSelectionValuesAreEqual(datasetFields)
        )
          this.commonDatasetFields.push(commonDatasetField);
        else //process all other commonDatasetField.depFilterCodeId types
          this.commonDatasetFields.push(commonDatasetField);
      }
    }
  }

  private atLeastOneDatasetFieldIsCommon(datasetFields: DatasetField[]): boolean {
    return datasetFields.some(df => df.commonFilter);
  }

  private allSelectionValuesAreEqual(datasetFields: DatasetField[]): boolean {
    datasetFields.forEach(datasetField => {
      if(JSON.stringify(datasetFields[0].datasetFieldMultivalues.map(dfm => dfm.uiValue)) !== JSON.stringify(datasetField.datasetFieldMultivalues.map(dfm => dfm.uiValue)))
        return false;
    });

    return true;
  }

  // TODO: this should be DB driven
  datasetIsClientIdBased(dataset: Dataset) {
    return dataset.productId === ProductMetisId.ProviderConnections
    || dataset.productId === ProductMetisId.ShareOfCare
    || dataset.productId === ProductMetisId.MarketDemographics;
  }

  /**
   * Selects the rolling date radio button
   */
  private checkRollingDateRadioBtn(schedule) {
    if (schedule.subDefinitions[0].rollingDateRange !== null) {
      this.isCheckedRollingDateOneTime = true;
    }

    if (schedule.subDefinitions[0].rollingDateType !== null) {
      this.isCheckedRollingDateRecurring = true;
    }
  }

  /**
   * Assigns the rolling date range to control
   */
  private assignRollingDateRange(schedule) {
    if (schedule.subDefinitions[0].rollingDateRange !== null) {
      const dStart = new Date(schedule.subDefinitions[0].rollingDateRange[0]);
      dStart.setDate(dStart.getDate());
      this.fRange.start.setValue(dStart);

      const dEnd = new Date(schedule.subDefinitions[0].rollingDateRange[1]);
      dEnd.setDate(dEnd.getDate());
      this.fRange.end.setValue(dEnd);
    }
  }

  private getKeyValue(key: string, value: string) {
    const currentItem: IList = {
      key,
      value: key === 'scheduleRadioValue' ? this.getFrequencyName(value) : value
    };

    this.summaryStepView.push(currentItem);
  }

  private getFrequencyName(value: string): string {
    return value ? this.frequencies.filter(f => f.depFrequencyCodeId === Number(value))[0].uiName : undefined;
  }

  private addFiltersFormArrayValuesToSummaryStepView(filtersFormArrayValues: any[]) {
    for (const filtersFormArrayValue of filtersFormArrayValues) {
      if (filtersFormArrayValue.userValue) {
        if (Array.isArray(filtersFormArrayValue.userValue) && filtersFormArrayValue.userValue.length === 0) {
          continue;
        }

        if (
          filtersFormArrayValue.userValue &&
          filtersFormArrayValue.userValue instanceof Date &&
          !isNaN(filtersFormArrayValue.userValue)
        ) {
          const formattedDate = filtersFormArrayValue.userValue.toLocaleDateString('en-US');
          filtersFormArrayValue.userValue = formattedDate;
        }

        this.summaryStepView.push(
          {
            key: 'datasetfilter',
            value: filtersFormArrayValue,
          }
        );
      }
    }
  }

  private getFieldIncludedFields(key: string, includedFields: DatasetField[]) {
    for (const item of includedFields) {
      const currentItem: IList = {
        key,
        value: item.uiName,
      };
      this.summaryStepView.push(currentItem);
    }
  }

  // default form group
  secondFormGroup = this.formBuilder.group({
    secondCtrl: ['', Validators.required],
  });
  isLinear = false;

  private buildAndSetUpForm() {
    this.form = this.formBuilder.group({
      extractName: ['', Validators.required],
      notes: [''],
      memberId: ['', [Validators.required]],
      rollingDateRange: new FormControl(),
      rollingDateType: new FormControl(),

      scheduleStepForm: new FormControl(),

      fieldsTabContentForm0: new FormControl(),
      fieldsTabContentForm1: new FormControl(),
      fieldsTabContentForm2: new FormControl(),
      fieldsTabContentForm3: new FormControl(),
      fieldsTabContentForm4: new FormControl(),

      filtersTabContentFormCommon: new FormControl(),
      filtersTabContentForm0: new FormControl(),
      filtersTabContentForm1: new FormControl(),
      filtersTabContentForm2: new FormControl(),
      filtersTabContentForm3: new FormControl(),
      filtersTabContentForm4: new FormControl(),

      outputStepForm: new FormControl(),
    });

    this.form.valueChanges.subscribe(() => {
      this.setSummaryStepView();
      this.setStepsStatus();
    });
  }

  /**
   * Fires off each time a value is changed on the form
   */
  private setSummaryStepView() {
    // builds out summaryStepView
    // display it on the submit tab
    this.summaryStepView = [];

    Object.keys(this.form.controls).forEach(key => {
      const current = this.form.get(key);
      if (key === 'extractName' || key === 'notes' || key === 'memberId' || key === 'rollingDateType' || key === 'rollingDateRange') {
        let value = current.value;

        if (key === 'memberId') {
          value = this.memberShortName;
        }

        if (this.dataSetRollingDates) {
          if (
            key === 'rollingDateType' &&
            this.dataSetRollingDates.length > 0 &&
            value
          ) {
            const filterRollingDate = this.dataSetRollingDates.filter(
              x => x.depRollingDateFrequencyCodeId === value
            );
            value = filterRollingDate[0].uiName;
          }
        }

        if (this.dataSetRollingDates) {
          if (
            key === 'rollingDateRange' &&
            this.dataSetRollingDates.length > 0 &&
            value
          ) {
            const startDate = this.saveFormatDate(this.range.value.start);
            const endDate = this.saveFormatDate(this.range.value.end);

            value = startDate + ',' + endDate;
          }
        }

        const currentItem: IList = {
          key,
          value,
        };
        this.summaryStepView.push(currentItem);
      }

      // field tab (up to 5 form)
      // field tab first form
      if (key === 'fieldsTabContentForm0') {
        if (this.f.fieldsTabContentForm0.value) {
          if (this.f.fieldsTabContentForm0.value) {
            this.getKeyValue(
              'subDatasetNameField',
              this.f.filtersTabContentForm0.value?.subDatasetName
            );
          }

          if (this.f.fieldsTabContentForm0.value.includedDatasetFields) {
            this.getFieldIncludedFields(
              'includedField',
              this.f.fieldsTabContentForm0.value.includedDatasetFields
            );
          }
        }
      }

      // field tab second form
      if (key === 'fieldsTabContentForm1') {
        if (this.f.fieldsTabContentForm1.value) {
          if (this.f.fieldsTabContentForm1.value) {
            this.getKeyValue(
              'subDatasetNameField',
              this.f.fieldsTabContentForm1.value.subDatasetName
            );
          }

          if (this.f.fieldsTabContentForm1.value.includedDatasetFields) {
            this.getFieldIncludedFields(
              'includedField',
              this.f.fieldsTabContentForm1.value.includedDatasetFields
            );
          }
        }
      }

      // field tab third form
      if (key === 'fieldsTabContentForm2') {
        if (this.f.fieldsTabContentForm2.value) {
          if (this.f.fieldsTabContentForm2.value) {
            this.getKeyValue(
              'subDatasetNameField',
              this.f.fieldsTabContentForm2.value.subDatasetName
            );
          }

          if (this.f.fieldsTabContentForm2.value.includedDatasetFields) {
            this.getFieldIncludedFields(
              'includedField',
              this.f.fieldsTabContentForm2.value.includedDatasetFields
            );
          }
        }
      }

      // field tab forth form
      if (key === 'fieldsTabContentForm3') {
        if (this.f.fieldsTabContentForm3.value) {
          if (this.f.fieldsTabContentForm3.value) {
            this.getKeyValue(
              'subDatasetNameField',
              this.f.fieldsTabContentForm3.value.subDatasetName
            );
          }

          if (this.f.fieldsTabContentForm3.value.includedDatasetFields) {
            this.getFieldIncludedFields(
              'includedField',
              this.f.fieldsTabContentForm3.value.includedDatasetFields
            );
          }
        }
      }

      // field tab fifth form
      if (key === 'fieldsTabContentForm4') {
        if (this.f.fieldsTabContentForm4.value) {
          if (this.f.fieldsTabContentForm4.value) {
            this.getKeyValue(
              'subDatasetNameField',
              this.f.fieldsTabContentForm4.value.subDatasetName
            );
          }

          if (this.f.fieldsTabContentForm4.value.includedDatasetFields) {
            this.getFieldIncludedFields(
              'includedField',
              this.f.fieldsTabContentForm4.value.includedDatasetFields
            );
          }
        }
      }

      // filter tab (up to 5 form)
      // filter tab first form
      if (key === 'filtersTabContentForm0') {
        if (this.f.filtersTabContentForm0.value) {
          this.getKeyValue(
            'subDatasetNameFilter',
            this.f.filtersTabContentForm0.value.subDatasetName
          );
          if (this.f.filtersTabContentForm0.value.filtersFormArray) {
            this.addFiltersFormArrayValuesToSummaryStepView(
              this.f.filtersTabContentForm0.value.filtersFormArray
            );
          }
        }
      }

      // filter tab second form
      if (key === 'filtersTabContentForm1') {
        if (this.f.filtersTabContentForm1.value) {
          this.getKeyValue(
            'subDatasetNameFilter',
            this.f.filtersTabContentForm1.value.subDatasetName
          );
          if (this.f.filtersTabContentForm1.value.filtersFormArray) {
            this.addFiltersFormArrayValuesToSummaryStepView(
              this.f.filtersTabContentForm1.value.filtersFormArray
            );
          }
        }
      }

      // filter tab third form
      if (key === 'filtersTabContentForm2') {
        if (this.f.filtersTabContentForm2.value) {
          this.getKeyValue(
            'subDatasetNameFilter',
            this.f.filtersTabContentForm2.value.subDatasetName
          );
          if (this.f.filtersTabContentForm2.value.filtersFormArray) {
            this.addFiltersFormArrayValuesToSummaryStepView(
              this.f.filtersTabContentForm2.value.filtersFormArray
            );
          }
        }
      }

      // filter tab forth form
      if (key === 'filtersTabContentForm3') {
        if (this.f.filtersTabContentForm3.value) {
          this.getKeyValue(
            'subDatasetNameFilter',
            this.f.filtersTabContentForm3.value.subDatasetName
          );
          if (this.f.filtersTabContentForm3.value.filtersFormArray) {
            this.addFiltersFormArrayValuesToSummaryStepView(
              this.f.filtersTabContentForm3.value.filtersFormArray
            );
          }
        }
      }

      // filter tab fifth form
      if (key === 'filtersTabContentForm4') {
        if (this.f.filtersTabContentForm4.value) {
          this.getKeyValue(
            'subDatasetNameFilter',
            this.f.filtersTabContentForm4.value.subDatasetName
          );
          if (this.f.filtersTabContentForm4.value.filtersFormArray) {
            this.addFiltersFormArrayValuesToSummaryStepView(
              this.f.filtersTabContentForm4.value.filtersFormArray
            );
          }
        }
      }

      // schedule form
      if (key === 'scheduleStepForm') {
        this.getKeyValue(
          'scheduleRadioValue',
          this.f.scheduleStepForm.value?.scheduleRadioValue !== undefined
            ? this.f.scheduleStepForm.value.scheduleRadioValue
            : ''
        );
        this.getKeyValue(
          'scheduledEndDate',
          this.f.scheduleStepForm.value?.scheduledEndDate !== undefined
            ? this.f.scheduleStepForm.value.scheduledEndDate
            : ''
        );
        this.getKeyValue(
          'selectedDateOneTime',
          this.f.scheduleStepForm.value?.selectedDateOneTime !== undefined
            ? this.f.scheduleStepForm.value.selectedDateOneTime
            : ''
        );
        this.getKeyValue(
          'selectedDateDaily',
          this.f.scheduleStepForm.value?.selectedDateDaily !== undefined
            ? this.f.scheduleStepForm.value.selectedDateDaily
            : ''
        );
        this.getKeyValue(
          'selectedDatQuarterly',
          this.f.scheduleStepForm.value?.selectedDatQuarterly !== undefined
            ? this.f.scheduleStepForm.value.selectedDatQuarterly
            : ''
        );
        this.getKeyValue(
          'selectedDatMonthly',
          this.f.scheduleStepForm.value?.selectedDatMonthly !== undefined
            ? this.f.scheduleStepForm.value.selectedDatMonthly
            : ''
        );
      }

      // output form
      if (key === 'outputStepForm') {
        this.getKeyValue(
          'channelName',
          this.f.outputStepForm.value?.channelName
            ? this.f.outputStepForm.value.channelName +
                (
                  this.f.outputStepForm.value.storageAccountName
                    ? ' - ' + this.f.outputStepForm.value.storageAccountName
                    : ''
                )
            : ''
        );

        this.getKeyValue(
          'fileType',
          this.f.outputStepForm.value?.fileType
            ? this.f.outputStepForm.value.fileType
            : ''
        );

        this.getKeyValue(
          'fileCompressionFormat',
          this.f.outputStepForm.value?.fileCompressionFormat
            ? this.f.outputStepForm.value.fileCompressionFormat
            : ''
        );

        this.getKeyValue(
          'fileName',
          this.f.outputStepForm.value?.fileName
            ? this.f.outputStepForm.value.fileName
            : ''
        );
      }
    });
  }

  /**
   * Fires off each time a value is changed on the form.
   * Checks each stage/step before moving on to next.
   *
   * @returns
   */
  private setStepsStatus() {
    if (this.isViewEnabled || this.isEdit) {
      this.markAllStepsAsCompleted();
      return;
    }

    this.setStepSetupCompletedValue();

    this.setStepScheduleCompletedValue();

    // only running these when its their steps as these could be marked as completed when displaying the form for the first time
    switch (this.stepper?.selected.label) {
      case 'Fields':
        this.setStepFieldsCompletedValue();
        break;
      case 'Filters':
        this.setStepFiltersCompletedValue();
        break;
    }

    this.setStepOutputCompletedValue();
  }

  private markAllStepsAsCompleted() {
    this.stepSetupCompleted = true;
    this.stepScheduleCompleted = true;
    this.stepFieldsCompleted = true;
    this.stepFiltersCompleted = true;
    this.stepOutputCompleted = true;
  }

  private setStepSetupCompletedValue() {
    if (this.f.extractName.status === 'VALID' &&
    this.f.memberId.status === 'VALID' &&
    this.f.rollingDateRange.status === 'VALID' &&
    this.f.rollingDateType.status === 'VALID') {
      this.stepSetupCompleted = true;
      return;
    }
    this.stepSetupCompleted = false;
  }

  private setStepScheduleCompletedValue() {
    if (this.f.scheduleStepForm.value) {
      if (this.f.scheduleStepForm.value.scheduleRadioValue) {
        if (this.f.scheduleStepForm.status === 'INVALID') {
          this.stepScheduleCompleted = false;
        } else {
          this.stepScheduleCompleted = true;
        }
      } else {
        this.stepScheduleCompleted = false;
      }
    }
  }

  private setStepFieldsCompletedValue() {
    let requiredIncludedFieldsCounter = this.requiredIncludedDatasetFieldsTabs.length;
    // loop through all the subdataset requires
    for (let i = 0; i < this.requiredIncludedDatasetFieldsTabs.length; i++) {
      if (this.f[`fieldsTabContentForm${i}`]?.value?.includedDatasetFields?.length > 0) {
        // set new value
        this.requiredIncludedDatasetFieldsTabs[i].value = '';
        requiredIncludedFieldsCounter -= 1;
      } else {
        // reset
        this.requiredIncludedDatasetFieldsTabs[i].value = '*';
      }
    }

    // all the required includedFields condition meet
    if (requiredIncludedFieldsCounter === 0) {
      this.stepFieldsCompleted = true;
    } else {
      this.stepFieldsCompleted = false;
    }
  }

  private setStepFiltersCompletedValue() {
    this.stepFiltersCompleted = this.setStepFiltersCompleted;
  }

  private setStepOutputCompletedValue() {
    const outputStepForm = this.form.controls.outputStepForm as FormControl;
    if (outputStepForm.value) {
      if (
        outputStepForm.status === 'INVALID' ||
        outputStepForm.value.channelName === 'Member SFTP' && !this.f.outputStepForm.value.storageAccountId ||
        outputStepForm.value.channelName === 'Azure Blob Storage' && !this.f.outputStepForm.value.azureBlobStorageAccountId
      )
        this.stepOutputCompleted = false;
      else
        this.stepOutputCompleted = true;
    }
  }

  submitSchedule(){
    this.saveOrSubmitSchedule(DepScheduleStatusIdType.Active);
  }

  saveSchedule(){
    this.saveOrSubmitSchedule(DepScheduleStatusIdType.Draft);
  }

  private saveOrSubmitSchedule(depScheduleStatusId: number) {
    this.loading.next(true);

    // set the dto with all the values need to submit the form
    const newOrUpdatedSchedule: ScheduleDto = {
      scheduleId: this.schedule ? this.schedule.scheduleId : undefined,
      userId: this.userId ? this.userId : 1,
      datasetId: this.datasetId,
      memberId: this.f.memberId.value,
      depFrequencyCodeId: this.form.controls.scheduleStepForm.value.scheduleRadioValue,
      name: this.form.controls.extractName.value,
      description: this.form.controls.notes.value,
      oneTimeDt: this.form.controls.scheduleStepForm.value.selectedDateOneTime
        ? this.form.controls.scheduleStepForm.value.selectedDateOneTime
        : null,
      frequencyStartDt: this.getfrequeancyStartDate(this.form.controls.scheduleStepForm),
      frequencyEndDt: this.form.controls.scheduleStepForm.value.scheduledEndDate
        ? new Date(this.form.controls.scheduleStepForm.value.scheduledEndDate).toISOString()
        : null,
      depScheduleStatusId,

      subDefinitions: [],
      commonDatasetFieldsUserDefinition: this.getFilteredDatasetFields(this.form.controls.filtersTabContentFormCommon as FormGroup) as ScheduleCommonDatasetFieldUserDefinitionDto[],

      depChannelId: this.channels.find(c => c.uiName === this.form.controls.outputStepForm.value.channelName).depChannelId,
      storageAccountId: undefined,
      depOutputFileTypeId: this.fileTypes.find(c => c.uiName === this.form.controls.outputStepForm.value.fileType).depOutputFileTypeId,
      depOutputFileCompressionFormatId: this.fileCompressionFormats.find(c => c.uiName === this.form.controls.outputStepForm.value.fileCompressionFormat).depOutputFileCompressionFormatId,
      fileName: this.form.controls.outputStepForm.value.fileName,
    };

    if (newOrUpdatedSchedule.depChannelId === DepChannel.MemberSFTP)
      newOrUpdatedSchedule.storageAccountId = this.form.controls.outputStepForm.value.storageAccountId;
    if (newOrUpdatedSchedule.depChannelId === DepChannel.AzureBlobStorage)
      newOrUpdatedSchedule.storageAccountId = this.form.controls.outputStepForm.value.azureBlobStorageAccountId;

    // gets the values from fields and filters forms (one per subDataset)
    for (let index = 0; index < this.subDatasets.length; index++) {
      newOrUpdatedSchedule.subDefinitions.push(
        {
          subDatasetId: this.subDatasets[index].subDatasetId,
          includeInExtract: true,
          rollingDateRange: this.form.controls.rollingDateRange.value,
          rollingDateType: this.form.controls.rollingDateType.value,
          includedFields: this.getIncludedDatasetFieldsIds(this.form.controls['fieldsTabContentForm' + index] as FormGroup),
          datasetFields: this.getFilteredDatasetFields(this.form.controls['filtersTabContentForm' + index] as FormGroup) as DatasetFieldDto[]
        }
      );
    }

    const scheduleServiceAction = this.schedule
      ? this.scheduleService.update(newOrUpdatedSchedule)
      : this.scheduleService.create(newOrUpdatedSchedule);

    scheduleServiceAction.subscribe({
      next: () => {
        this.loading.next(false);
        this.showScheduleServiceActionResultMsg('successfully');
        this.router.navigate(['app/dashboard']);
      },
      error: () => {
        this.loading.next(false);
        this.showScheduleServiceActionResultMsg('failed');
      },
    });
  }

  private getfrequeancyStartDate(scheduleStepForm: any): string {
    let date: string;
    switch (scheduleStepForm.value.scheduleRadioValue) {
      case DepFrequencyCodeIdType.Quarterly:
        date = scheduleStepForm.value.selectedDatQuarterly;
        break;
      case DepFrequencyCodeIdType.Monthly:
        date = scheduleStepForm.value.selectedDatMonthly;
        break;
      case DepFrequencyCodeIdType.Daily:
        date = scheduleStepForm.value.selectedDateDaily;
        break;
      case DepFrequencyCodeIdType['One-time']:
        date = scheduleStepForm.value.selectedDateOneTime;
        break;
      case DepFrequencyCodeIdType.Now:
        date = new Date().toISOString();
        break;
      default:
        date = null;
        break;
    }

    return date;
  }

  private getIncludedDatasetFieldsIds(fieldsTabContentForm: FormGroup): number[] {
    return fieldsTabContentForm && fieldsTabContentForm.value && fieldsTabContentForm.value.includedDatasetFields
      ? fieldsTabContentForm.value.includedDatasetFields.map(idf => idf.datasetFieldId)
      : [];
  }

  private getFilteredDatasetFields(filtersTabContentForm: FormGroup): DatasetFieldDto[] | ScheduleCommonDatasetFieldUserDefinitionDto[]{
    return filtersTabContentForm && filtersTabContentForm.value && filtersTabContentForm.value.filtersFormArray
      ? filtersTabContentForm.value.filtersFormArray.filter(
          filtersFormArrayValue => filtersFormArrayValue.userValue && filtersFormArrayValue.userValue.length > 0
        ).map(
          filtersFormArrayValue => {
            let userValues: string[] = [];
            if (typeof filtersFormArrayValue.userValue === 'string') {
              userValues = filtersFormArrayValue.userValue.split(',').map(value => value.trim());
            } else if (Array.isArray(filtersFormArrayValue.userValue)) {
              userValues = filtersFormArrayValue.userValue.map(value => value.toString());
            } else if (typeof filtersFormArrayValue.userValue === 'number') {
              userValues.push(filtersFormArrayValue.userValue.toString());
            } else {
              return;
            }

            return {
              datasetFieldId: !filtersFormArrayValue.isContainedInACommonFiltersTab ? filtersFormArrayValue.datasetFieldId : undefined,
              datasetFieldDbFieldName: filtersFormArrayValue.isContainedInACommonFiltersTab ? filtersFormArrayValue.dbFieldName : undefined,
              selectedFilterCodeId: filtersFormArrayValue.selectedFilterCodeId
                ? filtersFormArrayValue.selectedFilterCodeId
                : filtersFormArrayValue.depFilterCodeId,
              userValue: userValues,
            };
          }
        )
      : [];
  }

  private showScheduleServiceActionResultMsg(status: string){
    this.snackBar.open(`Schedule ${this.schedule ? 'update' : 'submit'} ${status}`, null, {
      duration: 5 * 1000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

  /**
   * Return the form controls
   */
  get f() {
    return this.form.controls;
  }

  /**
   * Return range control
   */
  get fRange() {
    return this.range.controls;
  }

  getFiltersTabsLabels(): string[] {
    const subDatasetsUiNames = this.subDatasets.map(sd => sd.uiName);

    return this.commonDatasetFields.length
      ? [this.commonTabLabel].concat(subDatasetsUiNames)
      : subDatasetsUiNames;
  }

  getFiltersTabContentDatasetFields(label: string): DatasetField[] {
    return label === this.commonTabLabel
      ? this.commonDatasetFields
      : this.datasetFieldsBySubDatasetId.get(this.subDatasets.find(sd => sd.uiName === label).subDatasetId);
  }

  getFiltersTabContentSubDataset(label: string): SubDataset {
    return label === this.commonTabLabel
      ? undefined
      : this.subDatasets.find(sd => sd.uiName === label);
  }

  getFiltersTabContentDatasetFieldsUserDefinition(label: string): DatasetFieldUserDefinitionDto[] | undefined {
    return label === this.commonTabLabel
      ? this.schedule?.commonDatasetFieldsUserDefinition
      : this.getSubDatasetDefinition(this.subDatasets.find(sd => sd.uiName === label).subDatasetId)?.datasetFields;
  }

  getFiltersTabContentFormControlName(label: string, labelIndex: number): string {
    return label === this.commonTabLabel
      ? 'filtersTabContentFormCommon'
      : this.commonDatasetFields.length ? `filtersTabContentForm${labelIndex - 1}` : `filtersTabContentForm${labelIndex}`;
  }

  getSubDatasetDefinition(subDatasetId: number) {
    return this.schedule?.subDefinitions.find(x =>  x.subDatasetId === subDatasetId);
  }

  resetForm() {
    this.dataShareService.emitResetFormButtonClicked();
    this.resetflag = false;
    this.cdr.detectChanges();
    this.resetflag = true;
    this.cdr.detectChanges();
  }

  /**
   * Event emitter for date range change filter
   */
  onDateChange() {
    if (this.range.value.end) {
      const startDate = this.saveFormatDate(this.range.value.start);
      const endDate = this.saveFormatDate(this.range.value.end);
      this.f.rollingDateRange.setValue([startDate, endDate]);
    }
  }

  private saveFormatDate(ctrlDate): string {
    const year = ctrlDate.getFullYear();
    const month = ctrlDate.getMonth() + 1;
    const date = ctrlDate.getDate();

    return `${year}-${month}-${date}`;
  }


  selectRollingDates() {
    this.isDisableDateRange = false;
    this.isDisableRollingDate = true;

    this.f.rollingDateRange.setValidators(Validators.required);
    this.f.rollingDateType.removeValidators(Validators.required);

    this.form.updateValueAndValidity();

    this.clearDateValues();
  }

  selectRecurringDates() {
    this.isDisableDateRange = true;
    this.isDisableRollingDate = false;

    this.f.rollingDateRange.removeValidators(Validators.required);
    this.f.rollingDateType.setValidators(Validators.required);

    this.form.updateValueAndValidity();

    this.clearDateValues();
  }

  selectNoDates() {
    this.isDisableDateRange = true;
    this.isDisableRollingDate = true;

    this.f.rollingDateRange.removeValidators(Validators.required);
    this.f.rollingDateType.removeValidators(Validators.required);

    this.form.updateValueAndValidity();

    this.clearDateValues();
  }

  clearDateValues(){
    this.range.controls.start.setValue(null);
    this.range.controls.end.setValue(null);
    this.f.rollingDateRange.setValue(null);
    this.f.rollingDateType.setValue(null);
  }

  /**
   * Event emitter for setting the value of the rolling date type
   */
  rollingDateRecurringTypeChange() {
    const currentrollingDateRecurringType = this.dataSetRollingDates.filter(
      (x) => x.depRollingDateFrequencyCodeId === this.f.rollingDateType.value
    );
    this.f.rollingDateType.setValue(
      currentrollingDateRecurringType[0].depRollingDateFrequencyCodeId
    );
  }

  updateDatasetFieldMultiValues() {
    if (this.datasetIsClientIdBased(this.dataset)) {
      this.clients.subscribe(clients => this.memberShortName = clients.find(m => m.value === this.f.memberId.value).uiValue);
    } else {
      this.members.subscribe(members => this.memberShortName = members.find(m => m.member_id === this.f.memberId.value).member_name);
    }

    this.datasetFieldsBySubDatasetId.forEach((datasetFields, subDatasetId) => {
      const datasetFieldsWithMultiValues = datasetFields.filter(
        df => df.depFilterCodeId === DepFilterCodeIdType.SINGLE_UI_SELECTION || df.depFilterCodeId === DepFilterCodeIdType.MULTI_UI_SELECTION
      );

      if(datasetFieldsWithMultiValues.length === 0) return;

      this.datasetFieldService.getMultiValues(
        datasetFieldsWithMultiValues.map(df => df.datasetFieldId).join(','),
        this.f.memberId.value
      ).subscribe(
        datasetFieldMultiValuesByDatasetFieldId => this.datasetFieldService.emitDatasetFieldMultiValuesChanged(
          { [subDatasetId]: datasetFieldMultiValuesByDatasetFieldId }
        )
      );
    });
  }

  updateFileNameOnOutputStepForm(event: any) {
    const newValue = event.target.value;
    this.form.patchValue({
      outputStepForm: {
        fileName: newValue.replace(/[^A-Za-z0-9 _-]+/g, '').replace(/ /g, '_').replace(/^_+|_+$/g, '')
      }
    });
  }

  moveToNextStep(currentStep: string) {
    switch (currentStep) {
      case 'Fields':
        for (let i = 0; i < this.requiredIncludedDatasetFieldsTabs.length; i++) {
          this.highlightFieldsTabHeader(
            document.getElementById(`fields-tab-header-${i}`),
            !this.f[`fieldsTabContentForm${i}`].value || this.f[`fieldsTabContentForm${i}`].value.includedDatasetFields?.length === 0
          );
        }

        break;

      case 'Filters':
        if (!this.stepFiltersCompleted)
          this.datasetFieldService.emitMoveToNextOnIncompletedStepClicked(currentStep);

        break;

      default:
        break;
    }
  }

  private highlightFieldsTabHeader(fieldsTabHeader: HTMLElement, tabIncompleted: boolean, ) {
    fieldsTabHeader.style['border'] = tabIncompleted ? '3px solid red' : '';
    fieldsTabHeader.style['box-shadow'] = tabIncompleted ? '4px 2px 10px rgba(200, 0, 0, 0.85)' : '';
    fieldsTabHeader.style['padding'] = tabIncompleted ? '8px 4px 8px 4px' : '';
  }

  onStepChange(event: StepperSelectionEvent) {
    switch (event.selectedStep.label) {
      case 'Fields':
        this.setStepFieldsCompletedValue();
        break;
      case 'Filters':
        this.setStepFiltersCompletedValue();
        break;
    }
  }
}
