import {Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ScheduleItemTrackingDto,
  ScheduleTrackingDto,
} from 'src/app/models';
import { ScheduleService } from 'src/app/services';
import { ScheduleTrackingStatusPipe } from 'src/app/pipes';
import { SharedModule } from 'src/app/shared';

@Component({
  selector: 'app-schedule-popup-details',
  templateUrl: './schedule-popup-details.component.html',
  styleUrls: ['./schedule-popup-details.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    ScheduleTrackingStatusPipe,
  ],
})
export class SchedulePopupDetailsComponent {
  scheduleItemTrackingDto: ScheduleItemTrackingDto;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ScheduleTrackingDto,
    private readonly dialogRef: MatDialogRef<SchedulePopupDetailsComponent>,
    private readonly scheduleService: ScheduleService
  ) {
    this.loadData(data);
  }

  /**
   * Loads the data to be displayed on UI
   *
   * @param data - selected valued passed in from parent grid
   */
  loadData(data: ScheduleTrackingDto) {
    this.scheduleService
      .findScheduleTrackingsForSchedule(data.userId, data.scheduleTrackingId)
      .subscribe(scheduleTracking => this.scheduleItemTrackingDto = scheduleTracking);
  }

  /**
   * close pop up
   */
  closeDialog(): void {
    this.dialogRef.close();
  }
}
