import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SharedModule } from 'src/app/shared';

@Component({
  selector: 'app-delete-dialog',
  standalone: true,
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
  imports: [SharedModule],
})
export class DeleteDialogComponent {
  constructor(public dialogSubmit: MatDialogRef<DeleteDialogComponent>) {}

  confirm() {
    this.dialogSubmit.close(true);
  }

  cancel() {
    this.dialogSubmit.close(false);
  }
}
