<div style="margin-bottom: 40px;">
  <h4 style="margin:0px">My Notifications</h4>
</div>
<div>
  <mat-table [dataSource]="userNotifications" style="width:100%" class="mat-elevation-z8">
    
    <ng-container matColumnDef="subject">
      <mat-header-cell *matHeaderCellDef class="center notification-message-column ">Subject</mat-header-cell>
      <mat-cell *matCellDef="let notification" class="center notification-message-column">
        <div class="cursor-pointer" (click)="viewExtract(notification)" class="title">
          {{ notification.subject }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="createdDtTm">
      <mat-header-cell *matHeaderCellDef class="center date-column">Date</mat-header-cell>
      <mat-cell *matCellDef="let notification" class="center date-column">
        <a class="cursor-pointer" (click)="viewExtract(notification)">
          {{ notification.createdDtTm | date: "MM-dd-yyyy h:mm a" }}
        </a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Name">
      <mat-header-cell *matHeaderCellDef class="center notification-message-column ">Notification Type</mat-header-cell>
      <mat-cell *matCellDef="let notification" class="center notification-message-column">
        <a class="cursor-pointer" (click)="viewExtract(notification)">
          {{notification.notificationTypeName}}
        </a>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="['subject','Name','createdDtTm']"></mat-header-row>
    <mat-row *matRowDef="let row; columns: ['subject','Name','createdDtTm']"></mat-row>
  </mat-table>
  <mat-paginator #paginator [length]="totalItems" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
    (page)="onPageChange($event)"></mat-paginator>
</div>