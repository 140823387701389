import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StorageAccount, StorageAccountPassword } from 'src/app/models';
import { StorageAccountService } from 'src/app/services';
import { SharedModule } from 'src/app/shared';

@Component({
  selector: 'app-storage-account-edit-password-popup',
  standalone: true,
  templateUrl: './storage-account-edit-password-popup.component.html',
  styleUrls: ['./storage-account-edit-password-popup.component.scss'],
  imports: [SharedModule],
})
export class StorageAccountEditPasswordPopupComponent implements OnInit {
  submitButtonText = 'Save';
  viewOnlyMode = true;
  editOnlyMode = true;
  loading = false;
  storageAccountServerStored: StorageAccount;

  securityForm: FormGroup = new FormGroup({
    name: new FormControl(''),
    description: new FormControl(''),
    oldPassword: new FormControl('', [
      Validators.required,
      Validators.pattern('[a-zA-Z0-9@#&$!*^%+]+'),
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.pattern('[a-zA-Z0-9@#&$!*^%+]+'),
    ]),
    confirmPassword: new FormControl('', [
      Validators.required,
      Validators.pattern('[a-zA-Z0-9@#&$!*^%+]+'),
    ]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public storageAccount: StorageAccount,
    private dialogRef: MatDialogRef<StorageAccountEditPasswordPopupComponent>,
    private storageAccountService: StorageAccountService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.securityForm.get('name').disable();
    this.securityForm.get('description').disable();

    this.viewOnlyMode = true;

    this.securityForm.patchValue({
      name: this.storageAccount.name,
      description: this.storageAccount.description,
      oldPassword: '',
      password: '',
      confirmPassword: '',
    });

    this.storageAccountService
      .getById(this.storageAccount.storageAccountId)
      .subscribe(storageAccount => {
        this.storageAccountServerStored = storageAccount;
      });
  }

  /**
   * Reset form values on reset button.
   */
  reset() {
    this.securityForm.reset();
    this.securityForm.markAllAsTouched();
  }

  /**
   * Remove spaces from server name.
   */
  onServerInputChange() {
    const strWithOutSpaces = this.securityForm.controls.serverIp.value
      .split(' ')
      .join('');
    this.securityForm.controls.serverIp.setValue(strWithOutSpaces);
  }

  /**
   * Remove spaces from username.
   */
  onUserNameInputChange() {
    const strWithOutSpaces = this.securityForm.controls.userName.value
      .split(' ')
      .join('');
    this.securityForm.controls.userName.setValue(strWithOutSpaces);
  }

  /**
   * Remove spaces from password.
   */
  onPasswordInputChange() {
    if (this.securityForm.controls.password.value[0] === ' ') {
      const stringWithoutFirstSpace = this.securityForm.controls.password.value.slice(1);
      this.securityForm.controls.password.setValue(stringWithoutFirstSpace);
    }
    if (
      this.securityForm.controls.password.value[
        this.securityForm.controls.password.value.length - 1
      ] === ' '
    ) {
      const stringWithoutFirstSpace = this.securityForm.controls.password.value.substring(
        0,
        this.securityForm.controls.password.value.length - 1
      );
      this.securityForm.controls.password.setValue(stringWithoutFirstSpace);
    }
  }

  updatePassword() {
    this.loading = true;
    if (this.securityForm.valid) {
      const storageAccountPassword: StorageAccountPassword = {
        oldPassword: this.securityForm.controls.oldPassword.value,
        newPassword: this.securityForm.controls.password.value,
        StorageAccountId: this.storageAccountServerStored.storageAccountId,
        userId: this.storageAccountServerStored.userId,
        keyVaultId: this.storageAccountServerStored.keyVaultId,
      };

      this.storageAccountService
        .updatePassword(
          storageAccountPassword,
          this.storageAccountServerStored.storageAccountId
        )
        .subscribe(
          (response) => {
            this.loading = false;
            if (response.code === '00001') {
              this.snackBar.open(
                'Member SFTP password udpated successfully',
                null,
                {
                  duration: 5 * 1000,
                  horizontalPosition: 'end',
                  verticalPosition: 'top',
                }
              );
              this.loading = false;
              this.closeDialog();
            } else if (response.code === '00002') {
              this.snackBar.open(
                'An error occured while udpating password',
                null,
                {
                  duration: 5 * 1000,
                  horizontalPosition: 'end',
                  verticalPosition: 'top',
                }
              );
            } else if (response.code === '00003') {
              this.snackBar.open(
                'You have entered incorrect old password',
                null,
                {
                  duration: 5 * 1000,
                  horizontalPosition: 'end',
                  verticalPosition: 'top',
                }
              );
            }
          },
          (error) => {
            this.snackBar.open('Failed to update', null, {
              duration: 5 * 1000,
              horizontalPosition: 'end',
              verticalPosition: 'top',
            });
            this.loading = false;
          }
        );
    } else {
      this.securityForm.markAllAsTouched();
    }
  }

  /**
   * Disable form if the fields are invalid.
   */
  isUpdateDisabled() {
    return this.securityForm.controls.password.invalid ||
      this.securityForm.controls.oldPassword.invalid ||
      this.securityForm.controls.confirmPassword.invalid ||
      this.securityForm.controls.password.value !== this.securityForm.controls.confirmPassword.value;;
  }

  /**
   * Close dialog
   */
  closeDialog(): void {
    this.dialogRef.close();
  }
}

