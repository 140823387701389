<ng-container [formGroup]="filterForm">

  <div style="display: flex; align-items: flex-start;">
    <div *ngIf="isStringWithSelect || isIntegerWithSelect || isDateWithSelect" style="flex: 1; margin-right: 1px; width: 30%;">
      <mat-form-field appearance="fill" style="width: 80%;">
        <mat-select 
          formControlName="selectedFilterCodeId" 
          (selectionChange)="filterForm.controls.isContainedInACommonFiltersTab.value && notifyCommonDatasetFieldChanged()"
        >
          <mat-option *ngFor="let filterOption of filterOptions" [value]="filterOption.key">
            {{ filterOption.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    
    <div style="flex: 1; width: 70%;">
      <mat-form-field appearance="fill">
        <mat-label *ngIf="isDate || isDateWithSelect || isDateRange">Select a date {{isDateRange ? 'range' : ''}}</mat-label>

        <textarea
          *ngIf="isString || isInteger || isStringWithSelect || isIntegerWithSelect"
          matInput 
          formControlName="userValue"  
          [attr.maxlength]="lengthMax" 
          [rows]="textAreaRow" 
          [cols]="textAreaCol" 
          [matTooltip]="filterForm.controls.uiFilterHelpText?.value"
          (keypress)="isString || isInteger && keepOnlyNumbers($event) || isStringWithSelect || isIntegerWithSelect && keepOnlyNumbers($event)" 
          (input)="filterForm.controls.isContainedInACommonFiltersTab.value && notifyCommonDatasetFieldChanged()"
        ></textarea>
        <input 
          *ngIf="isDate || isDateWithSelect"
          matInput 
          [matDatepicker]="matDatepicker" 
          [min]="minDate" 
          formControlName="userValue"
          (dateChange)="filterForm.controls.isContainedInACommonFiltersTab.value && notifyCommonDatasetFieldChanged()"
        >
        <mat-date-range-input 
          *ngIf="isDateRange" 
          [formGroup]="dateRangeForm" 
          [min]="minDate" 
          [rangePicker]="rangePicker"
        >
          <input matStartDate formControlName="start" placeholder="Start date" (dateChange)="updateUserValueForDateRange()">
          <input matEndDate formControlName="end" placeholder="End date" (dateChange)="updateUserValueForDateRange()">
        </mat-date-range-input>

        <mat-hint>{{hint}}</mat-hint>

        <mat-datepicker-toggle *ngIf="isDate || isDateWithSelect" matSuffix [for]="matDatepicker"></mat-datepicker-toggle>
        <mat-datepicker-toggle *ngIf="isDateRange"  matSuffix [for]="rangePicker"></mat-datepicker-toggle>

        <mat-datepicker #matDatepicker></mat-datepicker>
        <mat-date-range-picker #rangePicker></mat-date-range-picker>
  
        <mat-error *ngIf="filterForm.controls.userValue.errors?.['required'] && (filterForm.controls.userValue.dirty || filterForm.controls.userValue.touched)">
          Please enter a value
        </mat-error>
      </mat-form-field>
    </div>
  </div>

</ng-container>
