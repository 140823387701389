import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'depFrequency',
  standalone: true,
})
export class DepFrequencyPipe  implements PipeTransform {
  transform(value: number, ...args: unknown[]): unknown {
    switch(value) {
      case 1: return 'Now';
      case 2: return 'OneTime';
      case 3: return 'Daily';
      case 4: return 'Monthly';
      case 5: return 'Quarterly';
    }
  }
}
