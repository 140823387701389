import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatAccordion } from '@angular/material/expansion';

import { Domain } from 'src/app/models';
import {
  ExpandOrCompressToggleComponent,
  ListOrCardToggleComponent,
  CollectionCardComponent,
  CollectionPanelComponent,
} from 'src/app/components-standalone';
import { FilterDomainsPipe } from 'src/app/pipes';
import {
  DomainsService,
  LayoutService,
  SessionManagementService,
} from 'src/app/services';
import staticData from '../../../assets/data/data.json';
import { SharedModule } from 'src/app/shared';
import { HomePageService } from './home-page.service';

@Component({
  standalone: true,
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [
    SharedModule,
    ListOrCardToggleComponent,
    ExpandOrCompressToggleComponent,
    CollectionCardComponent,
    CollectionPanelComponent,
    FilterDomainsPipe,
  ],
})
export class HomePageComponent implements OnInit {
  @ViewChild(MatAccordion) accordion!: MatAccordion;

  domainCollections: Domain[];
  // subDomainView$ = of(false);
  viewStyle$ = this.layoutService.viewMode$;

  welcomeText: string;
  welcomeDescription: string;
  private readonly hardData = staticData.titleValues;

  constructor(
    private readonly domainsService: DomainsService,
    private readonly layoutService: LayoutService,
    readonly homePageService: HomePageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly sessionManagementService: SessionManagementService
  ) {
    const title = this.hardData[0].headerTitle;
    this.welcomeText = 'Welcome to ' + title;
    this.welcomeDescription = `${title} is your one-stop shop for requesting data across Vizient solutions`;

    // this is not really related to this component, this logic just needs to run every
    // time the user opens the website. feel free to move to a better spot
    homePageService.loadUserRolesIfExpiredOrNotPresent();
  }

  ngOnInit(): void {
    this.domainsService
      .getDomainCollectionApi()
      .subscribe((domains) => (this.domainCollections = domains));
  }

  navigateToDomain(domain: Domain) {
    // we should reconsider the session management service in general
    this.sessionManagementService.removeAll();

    this.router
      .navigate(['/domain/', domain.domainId], {
        relativeTo: this.route,
      });
      // .then((isRouted) => {
      //   if (isRouted) this.subDomainView$ = of(true);
      // });
  }

  expandOrContract(option: 'expand' | 'compress') {
    if (option === 'expand') {
      this.accordion.openAll();
    } else {
      this.accordion.closeAll();
    }
  }

  // onDeactivateRouterOutlet() {
  //   this.subDomainView$ = of(false);
  // }
}
