import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
  forwardRef
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder, FormGroup, NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgForm, ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { DepFrequencyCodeIdType, IDatasetFrequencyCode } from 'src/app/models';
import { DataShareService } from 'src/app/services';
import { SharedModule } from 'src/app/shared';

@Component({
  selector: 'app-schedule',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ScheduleComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ScheduleComponent),
      multi: true,
    },
  ],
})
export class ScheduleComponent implements OnInit, Validator, ControlValueAccessor {
  @Input() scheduleDepFrequencyCodeId: number;
  @Input() frequencies: IDatasetFrequencyCode[];

  @ViewChild('scheduleForm') scheduleForm: NgForm;
  minDate: Date;
  yearOutDate: Date;
  scheduleRadioValue: string;
  dayOfTheMonthQuartly: string;
  scheduledEndDate: string;
  scheduleEndDateMaxMinusOne: Date;
  scheduleEndDateMin: Date;
  onDemandNow: boolean;
  scheduleStepForm: FormGroup;
  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth();
  currentDay = new Date().getDate();
  isViewEnabled = true;
  depFrequencyCodeIdType = DepFrequencyCodeIdType;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly dataShareService: DataShareService
  ) {
    this.buildForm();

    this.minDate = new Date(
      this.currentYear - 1,
      this.currentMonth,
      this.currentDay
    );
    this.yearOutDate = new Date(
      this.currentYear + 1,
      this.currentMonth,
      this.currentDay
    );
    this.scheduledEndDate = new Date(
      this.currentYear + 1,
      this.currentMonth,
      this.currentDay
    ).toString();
    this.scheduleEndDateMin = this.minDate;
    this.scheduleEndDateMaxMinusOne = new Date(
      this.currentYear + 5,
      this.currentMonth,
      this.currentDay
    );
    this.f.scheduledEndDate.setValue(this.scheduledEndDate);

    this.dataShareService.resetFormButtonClicked$.subscribe(() => this.scheduleForm.reset());
  }

  private buildForm() {
    if (history.state.isCreate){
      this.isViewEnabled = false;
    } else {
      this.isViewEnabled = history.state.isView ?? true;
    }

    this.scheduleStepForm = this.formBuilder.group({
      scheduleRadioValue: [{ value: '', disabled: this.isViewEnabled }],
      selectedDateOneTime: [{ value: '', disabled: this.isViewEnabled }],
      selectedDateDaily: [{ value: '', disabled: this.isViewEnabled }],
      selectedDatMonthly: [{ value: '', disabled: this.isViewEnabled }],
      selectedDatQuarterly: [{ value: '', disabled: this.isViewEnabled }],
      scheduledEndDate: [{ value: '', disabled: this.isViewEnabled }],
    });
  }

  get f() {
    return this.scheduleStepForm.controls;
  }

  onScheduledEndDate(event) {
    this.f.scheduledEndDate.setValue(this.scheduledEndDate);
  }

  ngOnInit(): void {
    if (this.scheduleDepFrequencyCodeId !== undefined) {
      if (this.isViewEnabled === false) {
        if (
          this.scheduleDepFrequencyCodeId === DepFrequencyCodeIdType.Monthly ||
          this.scheduleDepFrequencyCodeId === DepFrequencyCodeIdType.Quarterly
        ) {
          this.enableScheduleEnd();
        } else {
          this.disableScheduleEnd();
        }
      }
    }
  }

  onlyNumberKey(event) {
    return event.charCode === 8 || event.charCode === 0
      ? null
      : event.charCode >= 48 && event.charCode <= 57;
  }

  radioChange($event: MatRadioChange) {
    this.disableScheduleEnd();

    if ($event.value) {
      this.resetControls();
    }

    if ($event.value === DepFrequencyCodeIdType['One-time']) {
      this.enableScheduleEnd();
      this.f.selectedDateOneTime.setValidators([Validators.required]);
      this.f.selectedDateOneTime.markAsTouched();
      this.f.selectedDateOneTime.updateValueAndValidity();
    } else {
      this.f.selectedDateOneTime.clearValidators();
      this.f.selectedDateOneTime.updateValueAndValidity();
    }

    if ($event.value === DepFrequencyCodeIdType.Daily) {
      this.enableScheduleEnd();
      this.f.selectedDateDaily.setValidators([Validators.required]);
      this.f.selectedDateDaily.markAsTouched();
      this.f.selectedDateDaily.updateValueAndValidity();
    } else {
      this.f.selectedDateDaily.clearValidators();
      this.f.selectedDateDaily.updateValueAndValidity();
    }

    if ($event.value === DepFrequencyCodeIdType.Quarterly) {
      this.enableScheduleEnd();
      this.f.selectedDatQuarterly.setValidators([Validators.required]);
      this.f.selectedDatQuarterly.markAsTouched();
      this.f.selectedDatQuarterly.updateValueAndValidity();
    } else {
      this.f.selectedDatQuarterly.clearValidators();
      this.f.selectedDatQuarterly.updateValueAndValidity();
    }

    // monthly
    if ($event.value === DepFrequencyCodeIdType.Monthly) {
      this.enableScheduleEnd();
      this.f.selectedDatMonthly.setValidators([Validators.required]);
      this.f.selectedDatMonthly.markAsTouched();
      this.f.selectedDatMonthly.updateValueAndValidity();
    } else {
      this.f.selectedDatMonthly.clearValidators();
      this.f.selectedDatMonthly.updateValueAndValidity();
    }
  }

  disableScheduleEnd() {
    // disable Schedule End
    this.scheduleEndDateMaxMinusOne = new Date(
      this.currentYear,
      this.currentMonth,
      this.currentDay - 1
    );
    this.scheduleEndDateMin = new Date(
      this.currentYear,
      this.currentMonth,
      this.currentDay
    );
  }

  enableScheduleEnd() {
    // enable Schedule End
    this.scheduleEndDateMaxMinusOne = new Date(
      this.currentYear + 5,
      this.currentMonth,
      this.currentDay
    );
    this.scheduleEndDateMin = this.minDate;
  }

  private resetControls() {
    this.f.selectedDateOneTime.setValue('');
    this.f.selectedDateDaily.setValue('');
    this.f.selectedDatQuarterly.setValue('');
    this.f.selectedDatMonthly.setValue('');
  }

  public onTouched: () => void = () => {};

  writeValue(value: any): void {
    if (value) {
      this.scheduleStepForm.setValue(value, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.scheduleStepForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled)
      this.scheduleStepForm.disable();
    else
      this.scheduleStepForm.enable();
  }

  validate(control: AbstractControl<any, any>): ValidationErrors {
    return this.scheduleStepForm.valid
      ? null
      : {
          invalidFOrm: {
            valid: false,
            message: 'scheduleStepForm fields are invalid',
          },
        };
  }
}
