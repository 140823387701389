/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StorageAccount, StorageAccountType } from 'src/app/models';
import { StorageAccountService } from 'src/app/services';
import { DepStorageAccountStatus } from 'src/app/models';
import { SharedModule } from 'src/app/shared';

@Component({
  selector: 'app-storage-account',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './storage-account.component.html',
  styleUrls: ['./storage-account.component.scss'],
})

export class StorageAccountComponent implements OnInit {
  submitButtonText = 'Save';
  viewOnlyMode: boolean;
  editOnlyMode: boolean;
  loading = false;
  storageAccountServerStored: StorageAccount;
  storageAccountType: typeof StorageAccountType = StorageAccountType;
  storageAccountTypeSelected = StorageAccountType.MemberSftp;

  readonly pageHistoryStatesByStorageAccountUrlKey = 'pageHistoryStatesByStorageAccountUrl';

  storageAccountForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl(''),

    serverIp: new FormControl('', [
      Validators.required,
      Validators.pattern(/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)+([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$/),
    ]),
    azureStorageAccountName: new FormControl('', [Validators.required]),

    userName: new FormControl('', [
      Validators.required,
    ]),
    container: new FormControl('', [
      Validators.required,
      Validators.pattern('[a-z][a-z0-9]*'),
    ]),

    password: new FormControl('', [
      Validators.required,
      Validators.pattern('[a-zA-Z0-9@#&$!*^%+\/=]+'),
    ]),
    confirmPassword: new FormControl('', [
      Validators.required,
      Validators.pattern('[a-zA-Z0-9@#&$!*^%+\/=]+'),
    ]),

    folderName: new FormControl(''),
    hospitalContact: new FormControl(''),
  });

  constructor(
    private readonly storageAccountService: StorageAccountService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const pageHistoryState = ('isView' in history.state) && ('isEdit' in history.state)
      ? history.state
      : JSON.parse(localStorage.getItem(this.pageHistoryStatesByStorageAccountUrlKey))[window.location.href];//manual navigation or page refresh

    if (pageHistoryState) {
      this.viewOnlyMode = pageHistoryState.isView;
      this.editOnlyMode = pageHistoryState.isEdit;
    } else {
      this.viewOnlyMode = true;
      this.editOnlyMode = false;
    }

    // Disable all fields in View Mode
    if (this.viewOnlyMode) {
      this.disableFields();
    }

    this.activatedRoute
        .params
        .subscribe(({ id }) => {
          this.storePageHistoryState();

          if(id === 'new') {return;}

          this.storageAccountService
              .getById(id)
              .subscribe(storageAccount => {
                this.storageAccountServerStored = storageAccount;

                if (this.editOnlyMode) {
                  this.editMode();
                }

                this.storageAccountForm.patchValue({
                  name: storageAccount.name,
                  description: storageAccount.description,
                  serverIp: storageAccount.serverNameIp,
                  azureStorageAccountName: storageAccount.serverNameIp,
                  userName: storageAccount.username,
                  container: storageAccount.username,
                  password: storageAccount.password,
                  confirmPassword: storageAccount.password,
                  folderName: storageAccount.destinationpath,
                  hospitalContact: storageAccount.clientContact,
                });
                this.storageAccountTypeSelected = storageAccount.type;
              });
        });
  }

  /**
   * Disable all form fields in view mode.
   */
  private disableFields(): void {
    Object.keys(this.storageAccountForm.controls).forEach((key) => {
      this.storageAccountForm.controls[key].disable();
    });
  }

  private storePageHistoryState(): void {
    let pageHistoryStatesByStorageAccountUrl = JSON.parse(localStorage.getItem(this.pageHistoryStatesByStorageAccountUrlKey));

    if(!pageHistoryStatesByStorageAccountUrl) {
      pageHistoryStatesByStorageAccountUrl = {};
    }

    pageHistoryStatesByStorageAccountUrl[window.location.href] = {
      isView: this.viewOnlyMode,
      isEdit: this.editOnlyMode,
    };

    localStorage.setItem(
      this.pageHistoryStatesByStorageAccountUrlKey,
      JSON.stringify(pageHistoryStatesByStorageAccountUrl)
    );
  }

  /**
   * Only allow the user to update password if the storageAcountStatus is verified else disable the option to change password.
   */
  private editMode(): void {
    this.submitButtonText = 'Update';

    if (this.storageAccountServerStored.statusId !== DepStorageAccountStatus.Verified) {
      this.storageAccountForm.controls.password.disable();
      this.storageAccountForm.controls.confirmPassword.disable();
    }
  }

  validateUserName(){
    if (!this.storageAccountForm.controls.userName.value)
      return;

    if (
      !this.storageAccountForm.controls.userName.value[0].charIsLetterOrNumber() ||
      (!this.userNameIsValid(this.storageAccountForm.controls.userName.value) && !this.emailIsValid(this.storageAccountForm.controls.userName.value)) ||
      this.storageAccountForm.controls.userName.value.containsAtLeastOneCharTogether(['.', '-', '_']) ||
      this.storageAccountForm.controls.userName.value.endsWith('_')
    ) {
      this.storageAccountForm.controls.userName.setErrors({pattern: true});
    }
  }

  private userNameIsValid = (email: string): boolean => email.match(/^[a-zA-Z0-9]([a-zA-Z0-9._-]{0,62}[a-zA-Z0-9])?$/) !== null;

  private emailIsValid = (email: string): boolean => email.match(/^(?:\w+|\w+([+\.-]?\w+)*@\w+([\.-]?\w+)*(\.[a-zA-z]{2,4})+)$/g) !== null;

  save() {
    this.loading = true;

    const storageAccount: StorageAccount = {
      name: this.storageAccountForm.controls.name.value,
      description: this.storageAccountForm.controls.description.value,
      type: this.storageAccountTypeSelected,
      serverNameIp: this.storageAccountTypeSelected === StorageAccountType.MemberSftp
        ? this.storageAccountForm.controls.serverIp.value
        : this.storageAccountForm.controls.azureStorageAccountName.value,
      username: this.storageAccountTypeSelected === StorageAccountType.MemberSftp
        ? this.storageAccountForm.controls.userName.value
        : this.storageAccountForm.controls.container.value,
      password: this.storageAccountForm.controls.password.value,
      destinationpath: this.storageAccountForm.controls.folderName.value,
      clientContact: this.storageAccountForm.controls.hospitalContact.value
    };

    const storageAccountServiceAction = this.editOnlyMode
      ? this.storageAccountService.update(this.storageAccountServerStored.storageAccountId, storageAccount)
      : this.storageAccountService.create(storageAccount);

    storageAccountServiceAction.subscribe({
      next: () => {
        this.loading = false;
        this.showStorageAccountServiceActionResultMsg('successed');
        this.router.navigate(['app/storage-accounts']);
      },
      error: () => {
        this.loading = false;
        this.showStorageAccountServiceActionResultMsg('failed');
      },
    });
  }

  private showStorageAccountServiceActionResultMsg(status: string){
    this.snackBar.open(`Storage account ${this.editOnlyMode ? 'update' : 'submit'} ${status}`, null, {
      duration: 5 * 1000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

  /**
   * Reset form values.
   */
  clear() {
    this.storageAccountForm.reset();
    this.storageAccountForm.markAllAsTouched();
  }

  navigateToStorageAccountsRoute(): void {
    this.router.navigate(['app/storage-accounts']);
  }

  /**
   * Disable form if the fields are invalid.
   */
  isSaveDisabled() {
    const isInvalid =
      this.storageAccountForm.controls.name.invalid ||
      (
        this.storageAccountTypeSelected === StorageAccountType.MemberSftp &&
          (this.storageAccountForm.controls.serverIp.invalid || this.storageAccountForm.controls.userName.invalid)
      ) ||
      (
        this.storageAccountTypeSelected === StorageAccountType.AzureBlobStorage &&
          (this.storageAccountForm.controls.azureStorageAccountName.invalid || this.storageAccountForm.controls.container.invalid)
      ) ||
      this.storageAccountForm.controls.password.invalid ||
      this.storageAccountForm.controls.password.value !== this.storageAccountForm.controls.confirmPassword.value;

    return isInvalid || this.viewOnlyMode;
  }
}
