/* eslint-disable max-len */
import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';
import {
  FormArray,
  FormGroup
} from '@angular/forms';
import { FiltersColumnComponent } from '../filters-column/filters-column.component';
import { SharedModule } from 'src/app/shared';

@Component({
  selector: 'app-filters-columns',
  standalone: true,
  templateUrl: './filters-columns.component.html',
  styleUrls: ['./filters-columns.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SharedModule,
    FiltersColumnComponent,
  ],
})
export class FiltersColumnsComponent  {
  @Input() filtersTabContentForm: FormGroup;

  isViewEnabled = true;

  constructor() {
    if (history.state.isCreate) {
      this.isViewEnabled = false;
    } else {
      this.isViewEnabled = history.state.isView ?? true;
    }
  }

  get leftFiltersForms() {
    const filtersForms = ((this.filtersTabContentForm.controls.filtersFormArray as FormArray).controls as FormGroup[]);
    if (this.filtersTabContentForm.controls.subDatasetName.value !== 'Provider Connections') {
      return filtersForms.slice(0, filtersForms.length / 2);
    } else {
      return filtersForms.slice(
        0,
        filtersForms.findIndex(
          fc => fc.value.uiName === 'Service Area Provider 2'
        ) + 1
      );
    }
  }

  get rightFiltersForms() {
    const filtersForms = (this.filtersTabContentForm.controls.filtersFormArray as FormArray).controls;
    if (this.filtersTabContentForm.controls.subDatasetName.value !== 'Provider Connections') {
      return filtersForms.slice(
        filtersForms.length / 2,
        filtersForms.length
      );
    } else {
      return [
        filtersForms.find(fc => fc.value.uiName === 'Month')
      ].concat(filtersForms.filter(fc =>fc.value.uiDescription.includes('REFERRED')));
    }
  }
}
