<h3 mat-dialog-title class="boldheading">Notification</h3>
<div mat-dialog-content class="fontSize">
  <div class="row">
    <b class="boldheading">From :</b> {{notification.from}}
  </div>
  <div class="row">
    <b class="boldheading">Sent :</b> {{notification.createdDtTm }}
  </div>
  <div class="row">
    <b class="boldheading">To :</b> {{notification.to}}
  </div>
  <div class="row">
    <b class="boldheading">Subject :</b> {{notification.subject}}
  </div>
  <div>
    <p [innerHTML]="notification.body"></p>
  </div>
</div>
<div class="action-buttons">
  <button mat-button (click)="closeDialog()" class="close-button">Close</button>
</div>
