<ng-container [formGroup]="filtersTabContentForm">

  <ng-container *ngIf="this.filtersTabContentForm.get('filtersFormArray')" formArrayName="filtersFormArray">

    <div class="column">
      <div *ngFor="let filtersForm of filtersForms; let i=index" style="padding: 5px;" [formGroupName]="i">
        <app-filter
          [filterForm]="filtersForm"
        ></app-filter>        
      </div>
    </div>

  </ng-container>

</ng-container>
