import { Pipe, PipeTransform } from '@angular/core';

/**
 * A custom Angular pipe for transforming numeric dependency status values into human-readable strings.
 */
@Pipe({
  name: 'depScheduleTrackingStatus',
  standalone: true,
})
export class ScheduleTrackingStatusPipe implements PipeTransform {

  /**
   * Transforms a numeric dependency status value into a corresponding string representation.
   *
   * @param value - The numeric dependency status value to transform.
   * @param args - Optional additional arguments (not used in this implementation).
   * @returns The string representation of the transformed status value.
   */
  transform(value: number, ...args: unknown[]): unknown {
    switch (value) {
      case 1: return 'Pending';
      case 2: return 'Running';
      case 3: return 'Succeeded';
      case 4: return 'Failed';
      case 5: return 'Canceled';
      case 6: return 'Forbidden';
      default: return `Unknown (code=${value})`; // Add a default case in case of an unrecognized value
    }
  }
}
