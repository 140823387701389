/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import {
  Component,
  OnInit,
  forwardRef,
  Input,
  ViewChild,
} from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup, NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgForm, ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import {
  DatasetChannelDto,
  DatasetOutputFileTypeDto,
  DepChannelIdType,
  StorageAccountType,
  DepStorageAccountStatus,
  ScheduleDto,
  DatasetOutputFileCompressionFormatDto,
  StorageAccountSelectOption
} from 'src/app/models';
import { DataShareService, StorageAccountService } from 'src/app/services';
import { SharedModule } from 'src/app/shared';
import { DepChannel, DepOutputFileType } from 'src/app/enums';

@Component({
  selector: 'app-output-channel',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './output-channel.component.html',
  styleUrls: ['./output-channel.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OutputChannelComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OutputChannelComponent),
      multi: true,
    },
  ],
})
export class OutputChannelComponent implements OnInit, Validator, ControlValueAccessor {
  @Input() datasetId: number;
  @Input() schedule: ScheduleDto;

  private _channels: DatasetChannelDto[];
  get channels(): DatasetChannelDto[] | null {
    return this._channels;
  }
  @Input() set channels(channels: DatasetChannelDto[] | null) {
    this._channels = channels;
    this.sortChannels();
  }

  @Input() fileTypes: DatasetOutputFileTypeDto[];
  @Input() fileCompressionFormats: DatasetOutputFileCompressionFormatDto[];

  @ViewChild('outputForm') outputForm: NgForm;
  outputStepForm: FormGroup;
  memberSftpStorageAccounts: StorageAccountSelectOption[];
  absStorageAccounts: StorageAccountSelectOption[];
  isViewEnabled = true;
  depChannelIdType = DepChannelIdType;

  disableMemberSFTPddl = true;
  disableAzureBlobStorageddl = true;
  disableFileCompressionFormatRadioGroup = false;

  requiredChannel = '*';
  requiredFileType = '*';

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly storageAccountService: StorageAccountService,
    private readonly dataShareService: DataShareService,
  ) {
    this.dataShareService.resetFormButtonClicked$.subscribe(() => this.outputForm.reset());
  }

  ngOnInit(): void {
    if (history.state.isCreate){
      this.isViewEnabled = false;
    } else {
      this.isViewEnabled = history.state.isView ?? true;
    }

    this.buildForm();

    this.storageAccountService.getByStatusId(DepStorageAccountStatus.Verified).subscribe(
      verifiedStorageAccounts => {
        this.memberSftpStorageAccounts = verifiedStorageAccounts.some(x => x.type === StorageAccountType.MemberSftp)
          ? verifiedStorageAccounts.filter(x => x.type === StorageAccountType.MemberSftp)
          : [{ storageAccountId: 0,  name: 'None SFTP storage account set up'}];

        this.absStorageAccounts = verifiedStorageAccounts.some(x => x.type === StorageAccountType.AzureBlobStorage)
          ? verifiedStorageAccounts.filter(x => x.type === StorageAccountType.AzureBlobStorage)
          : [{ storageAccountId: 0,  name: 'None ABS storage account set up'}];
      }
    );
  }

  private buildForm() {
    this.outputStepForm = this.formBuilder.group({
      channelName: [{ value: '', disabled: this.isViewEnabled }, Validators.required],
      storageAccountId: [{ value: '', disabled: this.isViewEnabled }],
      azureBlobStorageAccountId: [{ value: '', disabled: this.isViewEnabled }],
      storageAccountName: [''],
      fileType: [{ value: '', disabled: this.isViewEnabled }, Validators.required],
      fileCompressionFormat: [{ value: '', disabled: this.isViewEnabled }, Validators.required],
      fileName: [{ value: '', disabled: this.isViewEnabled }, Validators.required],
    });
  }

  private sortChannels() {
    this._channels = [
      this._channels.find(c => c.depChannelId === DepChannel.SDE),
      this._channels.find(c => c.depChannelId === DepChannel.VizSFTP),
      this._channels.find(c => c.depChannelId === DepChannel.MemberSFTP),
      this._channels.find(c => c.depChannelId === DepChannel.AzureBlobStorage),
    ].filter(c => c);
  }

  radioChannelNameChange($event: MatRadioChange) {
    // something selected remove required *
    this.requiredChannel = '';

    // assign value to radio
    this.outputStepForm.controls.channelName.setValue($event.value);

    if (
      this.memberSftpStorageAccounts?.length > 0 &&
      (this.outputStepForm.controls.storageAccountId.value === '' || this.outputStepForm.controls.storageAccountId.value === 0)
    ) {
      this.outputStepForm.controls.storageAccountId.setValue(this.memberSftpStorageAccounts[0].storageAccountId);
      this.outputStepForm.controls.storageAccountName.setValue(this.memberSftpStorageAccounts[0].name);
    }

    if (
      this.absStorageAccounts?.length > 0 &&
      (this.outputStepForm.controls.azureBlobStorageAccountId.value === '' || this.outputStepForm.controls.azureBlobStorageAccountId.value === 0)
    ) {
      this.outputStepForm.controls.azureBlobStorageAccountId.setValue(this.absStorageAccounts[0].storageAccountId);
      this.outputStepForm.controls.storageAccountName.setValue(this.absStorageAccounts[0].name);
    }

    switch ($event.value) {
      case DepChannelIdType[DepChannelIdType['Member SFTP']]:
        this.disableMemberSFTPddl = this.memberSftpStorageAccounts.some(sa => sa.storageAccountId === 0);
        this.disableAzureBlobStorageddl = true;
        this.outputStepForm.controls.azureBlobStorageAccountId.setValue('');
        this.outputStepForm.controls.azureBlobStorageAccountId.removeValidators(Validators.required);
        this.outputStepForm.controls.storageAccountId.setValidators([Validators.required]);
        break;

      case DepChannelIdType[DepChannelIdType['Azure Blob Storage']]:
        this.disableAzureBlobStorageddl = this.absStorageAccounts.some(sa => sa.storageAccountId === 0);
        this.disableMemberSFTPddl = true;
        this.outputStepForm.controls.storageAccountId.setValue('');
        this.outputStepForm.controls.storageAccountId.removeValidators(Validators.required);
        this.outputStepForm.controls.azureBlobStorageAccountId.setValidators([Validators.required]);
        break;

      case DepChannelIdType[DepChannelIdType['VizSFTP']]:
      case DepChannelIdType[DepChannelIdType['SDE']]:
        //if VizSFTP or SDE selected, clear the storageAccountName. storageAccountName is not required for VizSFTP or SDE and is used in the summary page
        //to display the name of the selected value from the dropdown
        this.outputStepForm.controls.storageAccountName.setValue('');
        this.disableMemberSFTPddl = true;
        this.disableAzureBlobStorageddl = true;
        this.outputStepForm.controls.storageAccountId.setValue('');
        this.outputStepForm.controls.azureBlobStorageAccountId.setValue('');
        this.outputStepForm.controls.storageAccountId.removeValidators(Validators.required);
        this.outputStepForm.controls.azureBlobStorageAccountId.removeValidators(Validators.required);
        break;

      default:
        throw new Error(`Invalid DepChannelIdType=${$event.value}`);
    }

    this.outputStepForm.controls.azureBlobStorageAccountId.updateValueAndValidity();
    this.outputStepForm.controls.storageAccountId.updateValueAndValidity();
  }

  radioFileTypeChange($event: MatRadioChange) {
    // something selected remove required *
    this.requiredFileType = '';

    if($event.value === DepOutputFileType[DepOutputFileType.Parquet]) {
      this.outputStepForm.controls.fileCompressionFormat.setValue('No compression');
      this.disableFileCompressionFormatRadioGroup = true;
    } else {
      this.outputStepForm.controls.fileCompressionFormat.setValue('');
      this.disableFileCompressionFormatRadioGroup = false;
    }
  }

  memberSftpStorageAccountChange() {
    const currentMemberSftpStorageAccount = this.memberSftpStorageAccounts.find(
      (x) => x.storageAccountId === this.outputStepForm.controls.storageAccountId.value
    );
    this.outputStepForm.controls.storageAccountName.setValue(currentMemberSftpStorageAccount.name);
  }

  absStorageAccountChange() {
    const currentAbsStorageAccount = this.absStorageAccounts.find(
      (x) => x.storageAccountId === this.outputStepForm.controls.azureBlobStorageAccountId.value
    );
    this.outputStepForm.controls.storageAccountName.setValue(currentAbsStorageAccount.name);
  }

  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    if (val) {
      this.outputStepForm.patchValue(val, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.outputStepForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled)
      this.outputStepForm.disable();
    else
      this.outputStepForm.enable();
  }

  validate(control: AbstractControl<any, any>): ValidationErrors {
    return this.outputStepForm.valid
      ? null
      : {
          invalidFOrm: {
            valid: false,
            message: 'outputStepForm fields are invalid',
          },
        };
  }

  removeSpecialCharacters(event) {
    this.outputStepForm.controls.fileName
      .setValue(event.target.value.replace(/[^A-Za-z0-9_]+/g, '')
      .replace(/\s/g, '_')
      .replace(/^_+|_+$/g, ''));
  }
}
