import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, of } from 'rxjs';
import {
  DatasetField,
  DepScheduleStatusIdType,
  ScheduleTrackingDto,
} from 'src/app/models';
import { DataShareService, PassingComponentDataService, ScheduleService, UserService } from 'src/app/services';
import staticData from '../../../assets/data/data.json';
import { ResponseStatus } from 'src/app/enums';
import { ScheduleTrackingStatusPipe } from 'src/app/pipes';
import { SchedulePopupDetailsComponent } from 'src/app/components-standalone';
import { SharedModule } from 'src/app/shared';

/**
 * Display details for any given schedule
 */
@Component({
  selector: 'app-my-schedules-tracking-list',
  standalone: true,
  templateUrl: './my-schedules-tracking-list.component.html',
  styleUrls: ['./my-schedules-tracking-list.component.scss'],
  imports: [
    SharedModule,
    ScheduleTrackingStatusPipe,
  ],
})
export class MySchedulesTrackingListComponent implements OnInit {
  domainId: number;
  subDomainName: string;
  subDomainDescription: string;
  datasetId: number;
  dataSetFields$: Observable<DatasetField[]>;
  userExtractView$: Observable<boolean> = of(false);
  pageSize = 5;
  pageSizeOptions: number[] = [];
  totalItems = 0;
  currentPage = 0;
  email$: Observable<string>;
  email: string;
  userId: number;
  private hardData = staticData;
  scheduleId: number;
  scheduleName: string;

  @ViewChild('paginator') paginator: MatPaginator;
  dataSetSchedules: MatTableDataSource<ScheduleTrackingDto>;
  depScheduleStatusIdType = DepScheduleStatusIdType;

  constructor(
    private readonly dataShareService: DataShareService,
    private readonly scheduleService: ScheduleService,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly dialog: MatDialog,
    private readonly passingCompomentDataService: PassingComponentDataService
  ) {}

  /**
   * Init
   */
  ngOnInit(): void {
    const passedInValues = this.passingCompomentDataService.getAllData();
    this.scheduleId = passedInValues.scheduleId;
    this.scheduleName = passedInValues.scheduleName;

    this.pageSize = this.hardData.pageSize.scheduleGrid;
    this.pageSizeOptions = this.hardData.pageSizeOptions;

    this.email$.subscribe(email => {
      this.email = email;

      this.userService.getLoggedInUser().subscribe(
        user => {
          this.userId = user.userId;

          this.loadScheduleDetails();
        },
        error => {
          if (error.status === ResponseStatus.Unauthorized) {
            this.dataShareService.setSessionExpired(true);
            this.router.navigate(['/']);
          }
        }
      );
    });
  }

  /**
   * Calls api to get records from db
   */
  loadScheduleDetails() {
    this.scheduleService
      .getScheduleDetails(this.userId, this.scheduleId)
      .subscribe(
        result => {
          this.dataSetSchedules = new MatTableDataSource<ScheduleTrackingDto>(
            result
          );
          this.dataSetSchedules.paginator = this.paginator;
        },
        error => {
          console.log(error);
        }
      );
  }

  /**
   * Views a extract details in a popup dialog.
   *
   * @param extract - The extract to be viewed.
   */
  viewPopupDetails(scheduleTrackingDto: ScheduleTrackingDto): void {
    scheduleTrackingDto.userId = this.userId;
    scheduleTrackingDto.scheduleName = this.scheduleName;

    const dialogRef = this.dialog.open(SchedulePopupDetailsComponent, {
      width: '900px',
      data: scheduleTrackingDto,
    });

    dialogRef.afterClosed().subscribe(() => {});
  }

  /**
   * Back to the schedule grid
   */
  goBack() {
    this.router.navigate(['dashboard/schedules']);
  }

  /**
   * Sets the pageIndex and pageSize for the grid
   *
   * @param event - pageIndex and pageSize
   */
  onPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
  }
}
