<ng-container [formGroup]="outputStepForm" #outputForm="ngForm">

  <div class="output-cards-radio-group">
    <mat-card class="card-radio-group">
      <mat-card-title>
        <label id="radio-group-label">Delivery channel<span class="redStar">{{requiredChannel}}</span></label>
      </mat-card-title>
  
      <mat-card-content>
        <mat-radio-group 
          aria-labelledby="radio-group-label" 
          class="radio-group"
          formControlName="channelName" 
          (change)="radioChannelNameChange($event)"
        >
          <table>
            <ng-container *ngFor="let channel of channels">
              <tr>
                <td colspan="2" class="tdPadding">
                  <mat-radio-button [value]="channel.uiName">{{channel.uiName}}</mat-radio-button>
                </td>
  
                <td *ngIf="channel.depChannelId === depChannelIdType['Member SFTP']">
                  <mat-form-field class="ddl">
                    <mat-select formControlName="storageAccountId" [disabled]="disableMemberSFTPddl" (selectionChange)="memberSftpStorageAccountChange()">
                      <mat-option *ngFor="let memberSftpStorageAccount of memberSftpStorageAccounts" [value]="memberSftpStorageAccount.storageAccountId">
                        {{memberSftpStorageAccount.name}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="channel.depChannelId === depChannelIdType['Member SFTP'] && !outputStepForm.controls.storageAccountId.value">
                      Please select a Member SFTP storage account
                    </mat-error>
                  </mat-form-field>
                </td>
    
                <td *ngIf="channel.depChannelId === depChannelIdType['Azure Blob Storage']">
                  <mat-form-field class="ddl">
                    <mat-select formControlName="azureBlobStorageAccountId" [disabled]="disableAzureBlobStorageddl" (selectionChange)="absStorageAccountChange()">
                      <mat-option *ngFor="let absStorageAccount of absStorageAccounts" [value]="absStorageAccount.storageAccountId">
                        {{absStorageAccount.name}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="channel.depChannelId === depChannelIdType['Azure Blob Storage'] && !outputStepForm.controls.azureBlobStorageAccountId.value">
                      Please select an Azure blob storage account
                    </mat-error>
                  </mat-form-field>
                </td>
              </tr>
            </ng-container>
          </table>
        </mat-radio-group>
      </mat-card-content>
    </mat-card>
    
    <mat-card class="card-radio-group">
      <mat-card-header>
        <mat-card-title>
          <label id="radio-group-label">File type <span class="redStar">{{requiredFileType}}</span></label>
        </mat-card-title>
      </mat-card-header>
  
      <mat-card-content>
        <mat-radio-group 
          aria-labelledby="radio-group-label" 
          class="radio-group"  
          (change)="radioFileTypeChange($event)"
          formControlName="fileType"
        >
          <mat-radio-button 
            *ngFor="let filetype of fileTypes"
            [value]="filetype.uiName"  
            class="radio-button" 
          >
            {{filetype.uiName}}
          </mat-radio-button>
        </mat-radio-group>
      </mat-card-content>
    </mat-card>
    
    <mat-card class="card-radio-group">
      <mat-card-header>
        <mat-card-title>
          <label id="radio-group-label">File compression format <span class="redStar">*</span></label>
        </mat-card-title>
      </mat-card-header>
  
      <mat-card-content>
        <mat-radio-group
          aria-labelledby="radio-group-label" 
          class="radio-group" 
          formControlName="fileCompressionFormat"
          [disabled]="disableFileCompressionFormatRadioGroup"
        >
          <mat-radio-button 
            *ngFor="let fileCompressionFormat of fileCompressionFormats"
            [value]="fileCompressionFormat.uiName"
            [matTooltip]="fileCompressionFormat.uiHelpText"
            matTooltipPosition="after"
            class="radio-button" 
          >
            {{fileCompressionFormat.uiName}}
          </mat-radio-button>
        </mat-radio-group>
      </mat-card-content>
    </mat-card>
  </div>
  <br>
  
  <mat-form-field [style.width.px]=800>
    <mat-label>File name prefix</mat-label>
    <input matInput formControlName="fileName" (keydown)="this.removeSpecialCharacters($event)"/>
    <mat-hint>(Only letters, numbers, and underscores are permitted. Special characters are not allowed.)</mat-hint>
  </mat-form-field>

</ng-container>
