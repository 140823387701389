import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';
import {
  FormGroup,
} from '@angular/forms';

import { SharedModule } from 'src/app/shared';
import { FilterComponent } from '../filter/filter.component';

@Component({
  selector: 'app-filters-column',
  standalone: true,
  imports: [
    SharedModule,
    FilterComponent
],
  templateUrl: './filters-column.component.html',
  styleUrls: ['./filters-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersColumnComponent {
  @Input() filtersForms: FormGroup[];
  @Input() filtersTabContentForm: FormGroup;
}
