import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {
  MatPaginator, PageEvent
} from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { Observable } from 'rxjs';
import staticData from '../../../assets/data/data.json';
import {
  DepStorageAccountStatus,
  StorageAccount,
} from 'src/app/models';
import { ResponseStatus } from 'src/app/enums';
import { AuthService, DataShareService, StorageAccountService, UserService } from 'src/app/services';
import { StorageAccountEditPasswordPopupComponent } from 'src/app/components-standalone';
import { SharedModule } from 'src/app/shared';

@Component({
  selector: 'app-storage-accounts',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './storage-accounts.component.html',
  styleUrls: ['./storage-accounts.component.scss'],
})
export class StorageAccountsComponent implements OnInit {
  storageAccountTableDataSource: MatTableDataSource<StorageAccount>;
  email$: Observable<string>;
  userId: number;
  totalItems = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [];
  currentPage = 0;

  @ViewChild('paginator') paginator: MatPaginator;

  constructor(
    private readonly router: Router,
    private readonly storageAccountService: StorageAccountService,
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly dataShareService: DataShareService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly dialog: MatDialog
  ) {
    this.email$ = authService.email$;
  }

  ngOnInit(): void {
    this.pageSize = staticData.pageSize.memberSftpGrid;
    this.pageSizeOptions = staticData.pageSizeOptions;

    this.email$.subscribe(() => {
      this.userService.getLoggedInUser().subscribe(
        (user) => {
          this.userId = user.userId;

          this.storageAccountService
            .getPaged(this.userId, this.currentPage + 1, this.pageSize)
            .subscribe(storageAccountPagedResult => {
              if (storageAccountPagedResult && storageAccountPagedResult.totalCount > 0) {
                this.storageAccountTableDataSource =
                  new MatTableDataSource<StorageAccount>(
                    storageAccountPagedResult.storageAccounts
                  );

                // loop and assign status based on storageAccountStatusId
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (
                  let index = 0;
                  index < this.storageAccountTableDataSource.filteredData.length;
                  index++
                ) {
                  this.storageAccountTableDataSource.filteredData[
                    index
                  ].status =
                    DepStorageAccountStatus[
                      this.storageAccountTableDataSource.filteredData[
                        index
                      ].statusId.toString()
                    ];
                }
                this.totalItems = storageAccountPagedResult.totalCount;
                this.storageAccountTableDataSource.paginator = this.paginator;
              }
            });
        },
        (error) => {
          if (error.status === ResponseStatus.Unauthorized) {
            this.dataShareService.setSessionExpired(true);
            this.router.navigate(['/']);
          }
        }
      );
    });
  }

  /**
   * Handles pagination changes for storage accounts.
   * Updates the current page and page size and fetches the data for the current page.
   *
   * @param event - The PageEvent object containing pagination information.
   */
  onPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.storageAccountService
      .getPaged(this.userId, this.currentPage + 1, this.pageSize)
      .subscribe(storageAccountPagedResult => {
        if (storageAccountPagedResult && storageAccountPagedResult.totalCount > 0) {
          this.storageAccountTableDataSource = new MatTableDataSource<StorageAccount>(
            storageAccountPagedResult.storageAccounts
          );
        }
      });
  }

  addStorageAccount() {
    this.router.navigate(['new'], {
      relativeTo: this.activatedRoute,
      state: {
        isView: false,
        isEdit: false,
      },
    });
  }

  editStorageAccount(storageAccount: StorageAccount) {
    this.router.navigate([storageAccount.storageAccountId], {
      relativeTo: this.activatedRoute,
      state: {
        isView: false,
        isEdit: true,
      },
    });
  }

  viewStorageAccount(storageAccount: StorageAccount): void {
    this.router.navigate([storageAccount.storageAccountId], {
      relativeTo: this.activatedRoute,
      state: {
        isView: true,
        isEdit: false,
      },
    });
  }

  showStorageAccountEditPasswordPopupComponent(storageAccount: StorageAccount): void {
    const dialogRef = this.dialog.open(
      StorageAccountEditPasswordPopupComponent,
      {
        width: '950px',
        data: storageAccount,
      }
    );

    dialogRef.afterClosed().subscribe((result) => {});
  }
}
