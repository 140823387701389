import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

type ViewMode = 'grid' | 'list';

@Injectable({providedIn: 'root'})
export class LayoutService {
  private readonly viewMode = new BehaviorSubject<ViewMode>('grid');
  public readonly viewMode$ = this.viewMode.asObservable();

  changeViewMode(mode: ViewMode) {
    this.viewMode.next(mode);
  }
}
