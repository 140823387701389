<h3 mat-dialog-title class="boldheading">Extract Details</h3>
<div mat-dialog-content class="fontSize" id="extractDetailsPopup">
  <div class="row">
    <b class="boldheading">Domain name</b>
    <hr />
    {{scheduleItemTrackingDto.domainName}}
    <br /><br />
  </div>

  <div class="row">
    <b class="boldheading">Subdomain name</b>
    <hr />
    {{scheduleItemTrackingDto.subDomainName}}
    <br /><br />
  </div>

  <div class="row">
    <b class="boldheading">Extract name</b>
    <hr />
    {{data.scheduleName}}
    <br /><br />
  </div>

  <div class="row">
    <b class="boldheading">Member short name</b>
    <hr />
    {{scheduleItemTrackingDto.memberShortName}}
    <br /><br />
  </div>

  <div class="row">
    <b class="boldheading">Start time</b>
    <hr />
    {{scheduleItemTrackingDto.startTime | date:'medium'}}
    <br /><br />
  </div>

  <div class="row">
    <b class="boldheading">Execution time</b>
    <hr />
    {{scheduleItemTrackingDto.executionTime | date:'medium'}}
    <br /><br />
  </div>

  <div class="row">
    <b class="boldheading">Delivery time</b>
    <hr />
    {{scheduleItemTrackingDto.deliveryTime | date:'medium'}}
    <br /><br />
  </div>

  <div class="row">
    <b class="boldheading">Channel name</b>
    <hr />
    {{scheduleItemTrackingDto.channelName}}
    <br /><br />
  </div>

  <div class="row">
    <b class="boldheading">File name prefix</b>
    <hr />
    {{scheduleItemTrackingDto.fileName}}
    <br /><br />
  </div>

  <div class="row">
    <b class="boldheading">File type</b>
    <hr />
    {{scheduleItemTrackingDto.fileType}}
    <br /><br />
  </div>

  <div class="row">
    <b class="boldheading">Destination fileName</b>
    <hr />
    {{scheduleItemTrackingDto.destinationFileName}}
    <br /><br />
  </div>

  <div class="row">
    <b class="boldheading">Number of records</b>
    <hr />
    {{scheduleItemTrackingDto.numberOfRecords}}
    <br /><br />
  </div>

  <div class="row">
    <b class="boldheading">Tracking status</b>
    <hr />
    {{scheduleItemTrackingDto.trackingStatus}}
    <br /><br />
  </div>

  <div class="row">
    <div style="display: flex; width: 100%;">
      <div style="width:50%">
        <b class="boldheading">Included fields</b>
        <hr />
        <div class="includedFields-container">
          <ng-container *ngFor="let item of scheduleItemTrackingDto.includedFields">
            <label class="summary-value included-field"> {{item}}</label>
            <br />
          </ng-container>
        </div>
        <br /><br />
      </div>
      <div style="width:50%">
        <b class="boldheading">Filters</b>
        <hr />
        <div class="includedFields-container">
          <ng-container *ngFor="let item of scheduleItemTrackingDto.filters">
            <label class="summary-value included-field"> {{item}}</label>
            <br />
          </ng-container>
        </div>
        <br /><br />
      </div>
    </div>
  </div>

</div>
<div class="action-buttons">
  <button mat-button (click)="closeDialog()" class="close-button">Close</button>
</div>