import { Injectable } from '@angular/core';
import { retry, catchError } from 'rxjs';
import { EnvironmentUrlService } from './environment-url.services';
import { HttpClient } from '@angular/common/http';
import { UserDto, UserProductRole, UserProductRoleMember } from '../models';
import { ResponseStatus } from '../enums';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly resourceUrl: string;

  constructor(
    private envUrl: EnvironmentUrlService,
    private http: HttpClient
  ) {
    this.resourceUrl = `${this.envUrl.serverUrl}/v1/users`;
  }

  saveLoggedInUser() {
    return this.http.put(
      `${this.resourceUrl}/logged-in`,
      {}
    ).pipe(retry(1));
  }

  getLoggedInUser() {
    return this.http.get<UserDto>(
      `${this.resourceUrl}/logged-in`
    ).pipe(
      retry(1),
      catchError((error: any) => {
        if (error.status === ResponseStatus.Unauthorized) {
          console.log('Unauthenticated request');
        }
        throw error;
      })
    );
  }

  getLoggedInUserRoles() {
    return this.http.get<UserProductRoleMember[]>(
      `${this.resourceUrl}/logged-in/products/roles`
    ).pipe(retry(1));
  }

  getLoggedInUserProductClients(productId: number) {
    return this.http.get<any[]>(
      `${this.resourceUrl}/logged-in/products/${productId}/clients`
    ).pipe(retry(1));
  }

  getLoggedInUserProductRoles(productId: number, roleId: number){
    return this.http.get<UserProductRole[]>(
      `${this.resourceUrl}/logged-in/product-roles?productId=${productId}&roleId=${roleId}`
    ).pipe(retry(1));
  }
}
