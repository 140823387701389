<button mat-flat-button color="primary" (click)="addStorageAccount()">
  Add Storage Account
</button>

<div style="margin-bottom: 40px">
  <h4 style="margin: 0px">Storage Accounts</h4>
</div>
<div>
  <mat-table [dataSource]="storageAccountTableDataSource" style="width: 100%" class="mat-elevation-z8">

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef class="center notification-message-column">Name</mat-header-cell>
      <mat-cell *matCellDef="let storageAccount" class="center notification-message-column title">
        <div class="cursor-pointer"><a (click)="viewStorageAccount(storageAccount)">
          {{ storageAccount.name }}</a>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef class="center date-column">Description</mat-header-cell>
      <mat-cell *matCellDef="let storageAccount" class="center date-column">
        <a class="cursor-pointer">
          {{ storageAccount.description }}
        </a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef class="center date-column">Status</mat-header-cell>
      <mat-cell *matCellDef="let storageAccount" class="center date-column">
        <a class="cursor-pointer">
          {{ storageAccount.status }}
        </a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="serverNameIp">
      <mat-header-cell *matHeaderCellDef class="center notification-message-column">Server</mat-header-cell>
      <mat-cell *matCellDef="let storageAccount" class="center notification-message-column">
        <a class="cursor-pointer">
          {{ storageAccount.serverNameIp }}
        </a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions" class="center">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
      <mat-cell *matCellDef="let storageAccount">
        <div class="button-container">
          <a data-test="grid-icon" class="edit-btn" (click)="editStorageAccount(storageAccount)">
            <i class="fas fa-pencil-alt"></i>
          </a>

          <a data-test="grid-icon" class="view-btn" (click)="showStorageAccountEditPasswordPopupComponent(storageAccount)">
            <i class="fas fa-key"></i>
          </a>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="['name', 'description', 'status', 'serverNameIp', 'actions']"
    ></mat-header-row>
    
    <mat-row 
      *matRowDef="let row; columns: ['name', 'description', 'status', 'serverNameIp', 'actions']"
    ></mat-row>
  </mat-table>

  <mat-paginator 
    #paginator 
    [length]="totalItems" 
    [pageSize]="pageSize" 
    [pageSizeOptions]="pageSizeOptions"
    (page)="onPageChange($event)">
  </mat-paginator>
</div>