<h2 class="heading">Storage account</h2>

<form [formGroup]="storageAccountForm">
  <mat-spinner class="floating-spinner" *ngIf="loading"></mat-spinner>

  <mat-card>
    <mat-card-content>
      <label>Name</label>
      <mat-form-field class="long-input-field" appearance="fill">
        <input matInput formControlName="name" maxlength="50" />
        <mat-hint>(Maximum length is 50 character.)</mat-hint>
      </mat-form-field>
      <div *ngIf="
              storageAccountForm.controls['name'].touched &&
              storageAccountForm.controls['name'].errors?.required
            " class="validation-messages">
        Required
      </div>
    </mat-card-content>

    <mat-card-content>
      <label> Description </label>
      <mat-form-field class="long-input-field" appearance="fill">
        <textarea matInput formControlName="description" maxlength="200" rows="1" cols="50">
            </textarea>
        <mat-hint>(Maximum length is 200 character.)</mat-hint>
      </mat-form-field>
    </mat-card-content>

    <mat-card-content>
      <label> Type</label>
      <mat-form-field appearance="fill">
        <mat-select [(value)]="storageAccountTypeSelected" [disabled]="viewOnlyMode">
          <mat-option [value]="storageAccountType.MemberSftp">Member SFTP</mat-option>
          <mat-option [value]="storageAccountType.AzureBlobStorage">Azure blob storage</mat-option>
          <!-- <mat-option [value]="storageAccountType.AmazonStorageBucket">Amazon Storage Bucket</mat-option>
          <mat-option [value]="storageAccountType.GoogleCloudPlatform">Google Cloud Platform</mat-option> -->
        </mat-select>
      </mat-form-field>
    </mat-card-content>

    <div *ngIf="storageAccountTypeSelected === storageAccountType.MemberSftp">
      <mat-card-content>
        <label> Server name / IP </label>
        <mat-form-field class="long-input-field" appearance="fill">
          <input matInput formControlName="serverIp" maxlength="50" />
          <mat-hint>(Maximum length is 50 character. Must be a valid IPv4 address within the range 1.1.1.1 to 255.255.255.255)</mat-hint>
        </mat-form-field>
        <div *ngIf="
                storageAccountForm.controls['serverIp'].touched &&
                storageAccountForm.controls['serverIp'].errors?.required
              " class="validation-messages">
          Required
        </div>
        &nbsp;
        <div *ngIf="
                storageAccountForm.controls['serverIp'].invalid &&
                storageAccountForm.controls['serverIp'].touched &&
                storageAccountForm.controls['serverIp'].errors?.pattern
              " class="validation-messages">
          Server name / IP is invalid.
        </div>
      </mat-card-content>

      <mat-card-content>
        <label> User name </label>
        <mat-form-field class="long-input-field" appearance="fill">
          <input matInput formControlName="userName" maxlength="50" (input)="validateUserName()"/>
          <mat-hint>(Maximum length is 50 character. Characters allowed: a-zA-Z.-_&#64;)</mat-hint>
        </mat-form-field>
        <div *ngIf="
                storageAccountForm.controls['userName'].touched &&
                storageAccountForm.controls['userName'].errors?.required
              " class="validation-messages">
          Required
        </div>
        &nbsp;
        <div *ngIf="
                storageAccountForm.controls['userName'].invalid &&
                storageAccountForm.controls['userName'].touched &&
                storageAccountForm.controls['userName'].errors?.pattern
              " class="validation-messages">
          User name is invalid.
        </div>
      </mat-card-content>
    </div>
    
    <div *ngIf="storageAccountTypeSelected === storageAccountType.AzureBlobStorage">
      <mat-card-content>
        <label> Storage account name </label>
        <mat-form-field class="long-input-field" appearance="fill">
          <input matInput formControlName="azureStorageAccountName" maxlength="50" />
          <mat-hint>(Maximum length is 50 character.)</mat-hint>
        </mat-form-field>
        <div *ngIf="
                storageAccountForm.controls['azureStorageAccountName'].touched &&
                storageAccountForm.controls['azureStorageAccountName'].errors?.required
              " class="validation-messages">
          Required
        </div>
        &nbsp;
        <div *ngIf="
                storageAccountForm.controls['azureStorageAccountName'].invalid &&
                storageAccountForm.controls['azureStorageAccountName'].touched &&
                storageAccountForm.controls['azureStorageAccountName'].errors?.pattern
              " class="validation-messages">
          Storage account name contains invalid characters.
        </div>
      </mat-card-content>
  
      <mat-card-content>
        <label> Container </label>
        <mat-form-field class="long-input-field" appearance="fill">
          <input matInput formControlName="container" maxlength="50" />
          <mat-hint>(Maximum length is 50 character. Characters allowed: a-z0-9)</mat-hint>
        </mat-form-field>
        <div *ngIf="
                storageAccountForm.controls['container'].touched &&
                storageAccountForm.controls['container'].errors?.required
              " class="validation-messages">
          Required
        </div>
        &nbsp;
        <div *ngIf="
                storageAccountForm.controls['container'].invalid &&
                storageAccountForm.controls['container'].touched &&
                storageAccountForm.controls['container'].errors?.pattern
              " class="validation-messages">
          Container name is invalid.
        </div>
      </mat-card-content>
    </div>

    <mat-card-content>
      <label> {{storageAccountTypeSelected === storageAccountType.AzureBlobStorage ? "Key" : "Password"}} </label>
      <mat-form-field class="long-input-field" appearance="fill">
        <input matInput formControlName="password" type="password" maxlength="100"/>
        <mat-hint>(Maximum length is 100 character. Characters allowed: a-zA-Z0-9&#64;#&$!*^%+/=)</mat-hint>
      </mat-form-field>
      <div *ngIf="
              storageAccountForm.controls['password'].touched &&
              storageAccountForm.controls['password'].errors?.required
            " class="validation-messages">
        Required
      </div>
      &nbsp;
      <div *ngIf="
              storageAccountForm.controls['password'].invalid &&
              storageAccountForm.controls['password'].touched &&
              storageAccountForm.controls['password'].errors?.pattern
            " class="validation-messages">
        {{storageAccountTypeSelected === storageAccountType.AzureBlobStorage ? "Key" : "Password"}} contains invalid characters.
      </div>
    </mat-card-content>

    <mat-card-content>
      <label> Confirm {{storageAccountTypeSelected === storageAccountType.AzureBlobStorage ? "key" : "password"}} </label>
      <mat-form-field class="long-input-field" appearance="fill">
        <input matInput formControlName="confirmPassword" type="password" maxlength="100"/>
        <mat-hint>Please confirm your {{storageAccountTypeSelected === storageAccountType.AzureBlobStorage ? "key" : "password"}}</mat-hint>
      </mat-form-field>
      <div *ngIf="
              storageAccountForm.controls['confirmPassword'].touched &&
              storageAccountForm.controls['confirmPassword'].errors?.required
            " class="validation-messages">
        Required
      </div>
      &nbsp;
      <div *ngIf="
              storageAccountForm.controls['confirmPassword'].invalid &&
              storageAccountForm.controls['confirmPassword'].touched &&
              storageAccountForm.controls['confirmPassword'].errors?.pattern
            " class="validation-messages">
        {{storageAccountTypeSelected === storageAccountType.AzureBlobStorage ? "Key" : "Password"}} contains invalid characters.
      </div>
      <div *ngIf="
              !storageAccountForm.controls['confirmPassword'].errors?.required &&
              storageAccountForm.get('confirmPassword').touched &&
              storageAccountForm.get('password').value !== storageAccountForm.get('confirmPassword').value
            " class="validation-messages">
        {{storageAccountTypeSelected === storageAccountType.AzureBlobStorage ? "Key" : "Password"}} doesn't match.
      </div>
    </mat-card-content>

    <mat-card-content>
      <label> Folder name </label>
      <mat-form-field class="long-input-field" appearance="fill">
        <input matInput formControlName="folderName" maxlength="50" />
        <mat-hint>(Maximum length is 50 character.)</mat-hint>
      </mat-form-field>
      <div *ngIf="
              storageAccountForm.controls['folderName'].invalid &&
              storageAccountForm.controls['folderName'].touched
            " class="validation-messages">
        Required
      </div>
    </mat-card-content>

    <mat-card-content>
      <label> Your hospital contact </label>
      <mat-form-field class="long-input-field" appearance="fill">
        <input matInput formControlName="hospitalContact" maxlength="50" />
        <mat-hint>(Maximum length is 50 character.)</mat-hint>
      </mat-form-field>
      <div *ngIf="
              storageAccountForm.controls['hospitalContact'].invalid &&
              storageAccountForm.controls['hospitalContact'].touched
            " class="validation-messages">
        Required
      </div>
    </mat-card-content>

    <br />

    <button mat-button class="btn" disabled color="primary" (click)="save()" [disabled]="isSaveDisabled()">
      {{ submitButtonText }}
    </button>
    <button mat-button class="btn" (click)="clear()" [disabled]="viewOnlyMode">
      Clear
    </button>
    <button mat-button class="btn" (click)="navigateToStorageAccountsRoute()">
      Back
    </button>
  </mat-card>
</form>
