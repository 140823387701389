// TODO: this should be DB driven
export enum ProductMetisId {
    CdbReportBuilder = 195,
    Risk = 1157,
    Vvi = 1155,
    VizMatch = 3620,
    ProviderConnections = 1163,
    Cpsc = 3330,
    Xref = 3608,
    VizMatchVendor = 3636,
    ShareOfCare = 3635,
    MarketDemographics = 3642,
}
