<ng-container [formGroup]="filtersTabContentForm">

  <button class="toogle-view-btn" (click)="toogleView()">
    <mat-icon *ngIf="status === 'open'">expand_less</mat-icon>
    <mat-icon *ngIf="status === 'close'">expand_more</mat-icon>
    <span *ngIf="status === 'open'">Collapse all</span>
    <span *ngIf="status === 'close'">Expand all</span>
  </button>
  
  <app-filters-columns
    [filtersTabContentForm]="filtersTabContentForm"
  ></app-filters-columns>

</ng-container>
