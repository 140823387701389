/* eslint-disable max-len */
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  forwardRef,
} from '@angular/core';
import { CdkDragDrop, DragDropModule,moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  DatasetField,
  SubDataset,
  SubDefinitionDto,
  IList,
} from 'src/app/models';
import { SharedModule } from 'src/app/shared';

@Component({
  selector: 'app-fields-tab-content',
  standalone: true,
  templateUrl: './fields-tab-content.component.html',
  styleUrls: ['./fields-tab-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FieldsTabContentComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FieldsTabContentComponent),
      multi: true,
    },
  ],
  animations: [
    trigger('slideStatus', [
      transition('* => active', [
        animate(
          '.4s',
          keyframes([
            style({
              backgroundColor: '#7f5eba',
              offset: 0,
              marginTop: '5px',
              color: 'white',
            }),
            style({
              backgroundColor: '#9273c9',
              offset: 0.5,
              marginTop: '5px',
              color: 'rgb(0,0,0,0)',
            }),
            style({
              backgroundColor: 'rgb(0,0,0,0)',
              offset: 1.0,
              marginTop: '5px',
            }),
          ])
        ),
      ]),
    ]),
  ],
  imports: [
    SharedModule,
    DragDropModule,
  ],
})
export class FieldsTabContentComponent implements OnInit {
  @Input() datasetFields!: DatasetField[];
  @Input() subDataset: SubDataset;
  @Input() subDatasetDefinition: SubDefinitionDto | undefined;
  @Input() requiredIncludedDatasetFieldsTab: IList;

  filtrableDatasetFields!: DatasetField[];

  availableDatasetFields: DatasetField[] = [];
  includedDatasetFields: DatasetField[] = [];

  selectedAvailableDatasetFields: string[] = [];
  selectedIncludedDatasetFields: string[] = [];

  fieldsTabContentForm: FormGroup;// default forms that are passed in from parent
  isViewEnabled = true;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    if (history.state.isCreate){
      this.isViewEnabled = false;
    } else {
      this.isViewEnabled = history.state.isView ?? true;
    }

    this.buildForm();

    this.filtrableDatasetFields = JSON.parse(JSON.stringify(this.datasetFields.filter(df => !df.mandatoryField)));

    this.availableDatasetFields = JSON.parse(JSON.stringify(this.filtrableDatasetFields));

    if (!this.subDatasetDefinition) {
      //"create extract" mode
      this.includedDatasetFields = JSON.parse(JSON.stringify(this.datasetFields.filter(df => df.mandatoryField)));
      if (this.includedDatasetFields.length > 0) {
        this.requiredIncludedDatasetFieldsTab.value = '';
      }
    } else {
      //"view/update extract" mode
      this.includedDatasetFields = this.getIncludedDatasetFieldsFromIds(this.subDatasetDefinition.includedFields);

      this.fieldsTabContentForm.controls.includedDatasetFields.setValue(this.includedDatasetFields);

      //removes the included dataset fields from the filtrable dataset fields
      //(included dataset fields are not filtrable)
      this.filtrableDatasetFields = this.filtrableDatasetFields.filter(
        fdf => !this.includedDatasetFields.some(idf => fdf.datasetFieldId === idf.datasetFieldId)
      );
    }

    // if the default selection is set, move all fields included fields
    if (this.subDataset.defaultSelection) {
      setTimeout(() => {
        this.moveAllDatasetFieldsToRight();
      }, 500); // wait until form is completely bound
    }

    if (this.includedDatasetFields.length > 0) {
      setTimeout(() => {
        this.updateFieldsTabContentForm('toRight', this.includedDatasetFields);
      }, 500);
    }
  }

  private buildForm() {
    this.fieldsTabContentForm = this.formBuilder.group({
      //global form props defined to be used in user-extract component
      includedDatasetFields: [],
      subDatasetId: this.subDataset.subDatasetId,
      subDatasetName: this.subDataset.uiName,

      //local form props defined to be used only in this component
      search: new FormControl({ value: '', disabled: this.isViewEnabled }, { nonNullable: true }),
      selectedAvailableDatasetFields: new FormControl([], { nonNullable: true }),
      selectedIncludedDatasetFields: new FormControl([], { nonNullable: true }),
    });

    this.fieldsTabContentForm.controls.search.valueChanges.subscribe(
      value => this.filterAvailableDatasetFieldsByUiName(value)
    );
  }

  private filterAvailableDatasetFieldsByUiName(searchText: string): void {
    this.availableDatasetFields = this.filtrableDatasetFields.filter(
      df => df.uiName.toLowerCase().includes(searchText.trim().toLowerCase())
    );
  }

  private getIncludedDatasetFieldsFromIds(includedDatasetFieldIds: number[]): DatasetField[] {
    const includedDatasetFields: DatasetField[] = [];

    for (const includedDatasetFieldId of includedDatasetFieldIds) {
      const datasetField = this.datasetFields.find(
        df => df.datasetFieldId === includedDatasetFieldId
      );

      if (datasetField) {
        includedDatasetFields.push(datasetField);
      }
    }

    return includedDatasetFields;
  }

  private updateFieldsTabContentForm(orientation: string, includedDatasetFields: DatasetField[]){
    this.fieldsTabContentForm?.controls.includedDatasetFields.setValue(includedDatasetFields);

    if (orientation === 'toRight')
      this.fieldsTabContentForm?.controls.selectedAvailableDatasetFields.reset();

    if (orientation === 'toLeft')
      this.fieldsTabContentForm?.controls.selectedIncludedDatasetFields.reset();

    this.fieldsTabContentForm?.controls.search.reset();
    if (this.filtrableDatasetFields.length === 0)
      this.fieldsTabContentForm?.controls.search.disable({ emitEvent: false });
    else
      this.fieldsTabContentForm?.controls.search.enable({ emitEvent: false });
  }

  /**
   * Moves the selected available value/s to the included section
   * using current search filter applied
   */
  moveDatasetFieldsToRight(): void {
    const selectedAvailableDatasetFields = this.fieldsTabContentForm.controls.selectedAvailableDatasetFields.value;

    this.includedDatasetFields.push(...selectedAvailableDatasetFields);

    this.filtrableDatasetFields = this.filtrableDatasetFields.filter(
      fdf => !selectedAvailableDatasetFields.some(sadf => fdf.datasetFieldId === sadf.datasetFieldId)
    );

    this.selectedAvailableDatasetFields = [];

    this.updateFieldsTabContentForm('toRight', this.includedDatasetFields);
  }

  /**
   * Moves all available values to the included section
   * using current search filter applied
   */
  moveAllDatasetFieldsToRight(): void {
    this.includedDatasetFields.push(...this.availableDatasetFields);

    this.filtrableDatasetFields = this.filtrableDatasetFields.filter(
      fdf => !this.availableDatasetFields.some(adf => fdf.datasetFieldId === adf.datasetFieldId)
    );

    this.selectedAvailableDatasetFields = [];

    this.updateFieldsTabContentForm('toRight', this.includedDatasetFields);
  }

  /**
   * Moves the selected included value/s to the available section
   * using current search filter applied
   */
  moveDatasetFieldsToLeft(): void {
    const selectedIncludedDatasetFields = this.fieldsTabContentForm.controls.selectedIncludedDatasetFields.value.filter(sidf => !sidf.mandatoryField);

    this.filtrableDatasetFields = this.filtrableDatasetFields.concat(selectedIncludedDatasetFields).sort((d1, d2) => {
      if (d1.datasetFieldId > d2.datasetFieldId) return 1;
      else return -1;
    });

    this.includedDatasetFields = this.includedDatasetFields.filter(
      idf => !selectedIncludedDatasetFields.some(sidf => idf.datasetFieldId === sidf.datasetFieldId)
    );

    this.selectedIncludedDatasetFields = [];

    this.updateFieldsTabContentForm('toLeft', this.includedDatasetFields);
  }

  /**
   * Moves all included value to the available section
   * using current search filter applied
   */
  moveAllDatasetFieldsToLeft(): void {
    this.filtrableDatasetFields = this.filtrableDatasetFields.concat(this.includedDatasetFields.filter(idf => !idf.mandatoryField)).sort((d1, d2) => {
      if (d1.datasetFieldId > d2.datasetFieldId) return 1;
      else return -1;
    });

    this.includedDatasetFields = this.includedDatasetFields.filter(idf => idf.mandatoryField);

    this.selectedIncludedDatasetFields = [];

    this.updateFieldsTabContentForm('toLeft', this.includedDatasetFields);
  }

  /**
   * Add or removes selected available field
   *
   * @param selectedAvailableField
   */
  toggleAvailableFieldSelection(selectedAvailableField: string): void {
    if (this.selectedAvailableDatasetFields.includes(selectedAvailableField))
      this.selectedAvailableDatasetFields = this.selectedAvailableDatasetFields.filter(saf => saf !== selectedAvailableField);
    else
      this.selectedAvailableDatasetFields.push(selectedAvailableField);
  }

  /**
   * Add or removes selected included field
   *
   * @param selectedIncludedField
   */
  toggleIncludedFieldSelection(selectedIncludedField: string): void {
    if (this.selectedIncludedDatasetFields.includes(selectedIncludedField))
      this.selectedIncludedDatasetFields = this.selectedIncludedDatasetFields.filter(sif => sif !== selectedIncludedField);
    else
      this.selectedIncludedDatasetFields.push(selectedIncludedField);
  }

  /**
   * Dragging availability for the included fields
   *
   * @param event
   */
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.includedDatasetFields,
      event.previousIndex,
      event.currentIndex
    );
  }

  /**
   * Default setup
   */
  public onTouched: () => void = () => {};

  /**
   * Default setup
   */
  writeValue(value: any): void {
    if (value) {
      this.fieldsTabContentForm.setValue(value, { emitEvent: false });
    }
  }

  /**
   * Default setup
   */
  registerOnChange(fn: any): void {
    this.fieldsTabContentForm.valueChanges.subscribe(fn);
  }

  /**
   * Default setup
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Default setup
   */
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled)
      this.fieldsTabContentForm.disable();
    else
      this.fieldsTabContentForm.enable();
  }

  /**
   * Default setup
   */
  validate(control: AbstractControl<any, any>): ValidationErrors {
    return this.fieldsTabContentForm.valid
      ? null
      : {
          invalidFOrm: {
            valid: false,
            message: 'fieldsTabContentForm is invalid',
          },
        };
  }
}
