<h2 class="heading">Update storage account password</h2>
<form [formGroup]="securityForm">
  <mat-spinner class="floating-spinner" *ngIf="loading"></mat-spinner>
  <mat-card>
    <mat-card-content>
      <label>Name</label>
      <mat-form-field class="long-input-field" appearance="fill">
        <input matInput formControlName="name" maxlength="50" />       
      </mat-form-field>     
    </mat-card-content>
    <mat-card-content>
      <label> Description </label>
      <mat-form-field class="long-input-field" appearance="fill">
        <textarea matInput formControlName="description"  maxlength="200" rows="1" cols="50">
            </textarea>      
      </mat-form-field>   
    </mat-card-content>   
    <mat-card-content>
      <label>Old password </label>
      <mat-form-field class="long-input-field" appearance="fill">
        <input matInput formControlName="oldPassword" type="password" maxlength="50" />
        <mat-hint>(Maximum length is 50 character. Characters allowed: a-zA-Z0-9&#64;#&$!)</mat-hint>
      </mat-form-field>
      <div *ngIf="
              securityForm.controls['oldPassword'].invalid &&
              securityForm.controls['oldPassword'].touched &&
              securityForm.controls['oldPassword'].errors?.required
            " class="validation-messages">
        Required
      </div>
      &nbsp;
      <div *ngIf="
              securityForm.controls['oldPassword'].invalid &&
              securityForm.controls['oldPassword'].touched &&
              securityForm.controls['oldPassword'].errors?.pattern
            " class="validation-messages">
        Password contains invalid characters.
      </div>
    </mat-card-content>
    <mat-card-content>
      <label>Password </label>
      <mat-form-field class="long-input-field" appearance="fill">
        <input matInput formControlName="password" type="password" maxlength="50" (input)="onPasswordInputChange()" />
        <mat-hint>(Maximum length is 50 character. Characters allowed: a-zA-Z0-9&#64;#&$!)</mat-hint>
      </mat-form-field>
      <div *ngIf="
              securityForm.controls['password'].invalid &&
              securityForm.controls['password'].touched &&
              securityForm.controls['password'].errors?.required
            " class="validation-messages">
        Required
      </div>
      &nbsp;
      <div *ngIf="
              securityForm.controls['password'].invalid &&
              securityForm.controls['password'].touched &&
              securityForm.controls['password'].errors?.pattern
            " class="validation-messages">
        Password contains invalid characters.
      </div>
    </mat-card-content>
    <mat-card-content>
      <label> Confirm password </label>
      <mat-form-field class="long-input-field" appearance="fill">
        <input matInput formControlName="confirmPassword" type="password" maxlength="50"
          (input)="onPasswordInputChange()" />
        <mat-hint>Please confirm your password</mat-hint>
      </mat-form-field>
      <div *ngIf="
              securityForm.controls['confirmPassword'].invalid &&
              securityForm.controls['confirmPassword'].touched &&
              securityForm.controls['confirmPassword'].errors?.required
            " class="validation-messages">
        Required
      </div>
      &nbsp;
      <div *ngIf="
              securityForm.controls['confirmPassword'].invalid &&
              securityForm.controls['confirmPassword'].touched &&
              securityForm.controls['confirmPassword'].errors?.pattern
            " class="validation-messages">
        Password contains invalid characters.
      </div>
      <div *ngIf="
              !securityForm.controls['confirmPassword'].errors?.required &&
              securityForm.get('confirmPassword').touched &&
              securityForm.get('password').value !==
                securityForm.get('confirmPassword').value
            " class="validation-messages">
        Password does not match.
      </div>
    </mat-card-content>  
    <br />
    <button mat-button class="btn" color="primary" [disabled]="isUpdateDisabled()" (click)="updatePassword()"  id="membersftpSave">
      Update
    </button>
    <button mat-button class="btn" (click)="closeDialog()" id="membersftpBack">
      Cancel
    </button>
  </mat-card>
</form>

