import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { DatasetField, NotificationDto } from 'src/app/models';
import { AuthService, DataShareService, UserNotificationService, UserService } from 'src/app/services';
import staticData from '../../../assets/data/data.json';
import { ResponseStatus } from 'src/app/enums';
import { NotificationPopupComponent } from 'src/app/components-standalone';
import { SharedModule } from 'src/app/shared';

@Component({
  selector: 'app-my-notifications',
  standalone: true,
  templateUrl: './my-notifications.component.html',
  styleUrls: ['./my-notifications.component.scss'],
  imports: [SharedModule],
  providers: [DatePipe],
})
export class MyNotificationsComponent implements OnInit {
  private hardData = staticData;
  domainId: number;
  subDomainName: string;
  subDomainDescription: string;
  datasetId: number;
  dataSetFields$: Observable<DatasetField[]>;
  userExtractView$: Observable<boolean> = of(false);
  pageSize = 10;
  pageSizeOptions: number[] = [];
  totalItems = 0;
  currentPage = 0;
  email$: Observable<string>;
  email: string;
  userId: number;
  @ViewChild('paginator') paginator: MatPaginator;
  userNotifications: MatTableDataSource<NotificationDto>;

  constructor(
    private readonly dataShareService: DataShareService,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly dialog: MatDialog,
    private readonly datePipe: DatePipe,
    private readonly authService: AuthService,
    private readonly notificationService: UserNotificationService
  ) {
    this.email$ = authService.email$;
  }

  /**
   * Initializes the component when it is first created.
   * Sets up initial values, handles user authentication, and fetches user notifications.
   */
  ngOnInit(): void {
    this.pageSize = this.hardData.pageSize.scheduleGrid;
    this.pageSizeOptions = this.hardData.pageSizeOptions;
    this.email$.subscribe(email => {
      this.email = email;
      this.userService.getLoggedInUser().subscribe(
        user => {
          this.userId = user.userId;

          this.notificationService
            .getPagedNotifications(
              this.userId,
              this.currentPage + 1,
              this.pageSize
            )
            .subscribe(schedules => {
              if (schedules && schedules.totalCount > 0) {
                this.userNotifications =
                  new MatTableDataSource<NotificationDto>(
                    schedules.notifications
                  );
                this.totalItems = schedules.totalCount;
                this.userNotifications.paginator = this.paginator;
              }
            });
        },
        error => {
          if (error.status === ResponseStatus.Unauthorized) {
            this.dataShareService.setSessionExpired(true);
            this.router.navigate(['/']);
          }
        }
      );
    });
  }

  /**
   * Views a notification extract in a popup dialog.
   *
   * @param notification - The notification to be viewed.
   */
  viewExtract(notification: NotificationDto): void {
    notification.createdDtTm = this.datePipe.transform(
      notification.createdDtTm,
      'EEEE, MMMM d, y h:mm a'
    );
    const dialogRef = this.dialog.open(NotificationPopupComponent, {
      width: '600px',
      data: notification,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  /**
   * Handles pagination changes for user notifications.
   * Updates the current page and page size and fetches the data for the current page.
   *
   * @param event - The PageEvent object containing pagination information.
   */
  onPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.notificationService
      .getPagedNotifications(this.userId, this.currentPage + 1, this.pageSize)
      .subscribe(notificationCollection => {
        if (notificationCollection && notificationCollection.totalCount > 0) {
          this.userNotifications = new MatTableDataSource<NotificationDto>(notificationCollection.notifications);
        }
      });
  }
}
