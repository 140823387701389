import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import {
  DatasetField,
  DepScheduleStatusIdType,
  ScheduleDto,
} from 'src/app/models';
import { AuthService, DataShareService, PassingComponentDataService, ScheduleService, UserService } from 'src/app/services';
import staticData from '../../../assets/data/data.json';
import { ResponseStatus } from 'src/app/enums';
import { CancelDialogComponent, DeleteDialogComponent } from 'src/app/components-standalone';
import { SharedModule } from 'src/app/shared';
import { DepFrequencyPipe } from 'src/app/pipes';

@Component({
  selector: 'app-my-schedules',
  standalone: true,
  templateUrl: './my-schedules.component.html',
  styleUrls: ['./my-schedules.component.scss'],
  imports: [SharedModule, DepFrequencyPipe],
})
export class MySchedulesComponent implements OnInit {
  domainId: number;
  subDomainName: string;
  subDomainDescription: string;
  datasetId: number;
  dataSetFields$: Observable<DatasetField[]>;
  userExtractView$: Observable<boolean> = of(false);
  pageSize = 5;
  pageSizeOptions: number[] = [];
  totalItems = 0;
  currentPage = 0;
  email$: Observable<string>;
  email: string;
  userId: number;
  private hardData = staticData;

  @ViewChild('paginator') paginator: MatPaginator;
  dataSetSchedules: MatTableDataSource<ScheduleDto>;
  depScheduleStatusIdType = DepScheduleStatusIdType;

  constructor(
    private readonly dataShareService: DataShareService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly iconRegistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer,
    private readonly userService: UserService,
    private readonly dialog: MatDialog,
    private readonly passingCompomentDataService: PassingComponentDataService,
    private readonly authService: AuthService,
    private readonly scheduleService: ScheduleService,
  ) {
    this.registerIcons();
    this.email$ = authService.email$;
  }

  ngOnInit(): void {
    this.pageSize = this.hardData.pageSize.scheduleGrid;
    this.pageSizeOptions = this.hardData.pageSizeOptions;

    this.email$.subscribe(email => {
      this.email = email;

      this.userService.getLoggedInUser().subscribe(
        user => {
          this.userId = user.userId;

          this.loadSchedules();
        },
        error => {
          if (error.status === ResponseStatus.Unauthorized) {
            this.dataShareService.setSessionExpired(true);
            this.router.navigate(['/']);
          }
        }
      );
    });
  }

  loadSchedules() {
    this.scheduleService
      .getSchedules(this.userId)
      .subscribe((schedules) => {
        this.dataSetSchedules = new MatTableDataSource<ScheduleDto>(schedules);
        this.dataSetSchedules.paginator = this.paginator;

        // for UI to display startdate for enddate for Now and One-time
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let index = 0; index < schedules.length; index++) {
          // 1 = Now | 2 = One-time
          if (
            schedules[index].depFrequencyCodeId === 1 ||
            schedules[index].depFrequencyCodeId === 2
          ) {
            schedules[index].frequencyEndDt = schedules[index].frequencyStartDt;
          }

          // Filter schedules status by status Id
          schedules[index].depScheduleStatusStatus = DepScheduleStatusIdType[schedules[index].depScheduleStatusId.toString()];
        }
      });
  }

  editExtract(extract: ScheduleDto) {
    this.userExtractView$ = of(true);
    this.router.navigate([extract.scheduleId], {
      relativeTo: this.route,
      state: {
        datasetId: extract.datasetId,
        isEdit: true,
        isView: false,
      },
    });
  }

  cancelExtract(extract: ScheduleDto): void {
    const dialogRef = this.dialog.open(CancelDialogComponent, {
      width: '350px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.scheduleService
          .updateScheduleState(
            extract.scheduleId,
            extract.datasetId,
            DepScheduleStatusIdType.Canceled
          )
          .subscribe(() => {
            // reload the grid data after deleting
            this.loadSchedules();
          });
      }
    });
  }

  deleteExtract(extract: ScheduleDto): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '350px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        extract.isDeleted = true;
        this.scheduleService.deleteSchedule(
          extract.scheduleId,
          extract.datasetId
        )
          .subscribe(() => {
            // reload the grid data after deleting
            this.loadSchedules();
          });
      }
    });
  }

  /**
   * This will route to Schedule Details component and
   * set values that are used in Schedule details
   *
   * @param extract - selected schedule dto
   */
  viewScheduleDetails(extract: ScheduleDto): void {
    this.passingCompomentDataService.setScheduleId(extract.scheduleId);
    this.passingCompomentDataService.setScheduleName(extract.name);

    this.router.navigate(['dashboard/schedule/tracking/list']);
  }

  viewExtract(extract: ScheduleDto): void {
    this.userExtractView$ = of(true);
    this.router.navigate([extract.scheduleId], {
      relativeTo: this.route,
      state: {
        datasetId: extract.datasetId,
        isEdit: false,
        isView: true,
      },
    });
  }

  onPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
  }

  private registerIcons(): void {
    this.iconRegistry.addSvgIcon(
      'pencil',
      this.sanitizer.bypassSecurityTrustResourceUrl('path/to/pencil-icon.svg')
    );
    this.iconRegistry.addSvgIcon(
      'eye',
      this.sanitizer.bypassSecurityTrustResourceUrl('path/to/eye-icon.svg')
    );
    this.iconRegistry.addSvgIcon(
      'trash',
      this.sanitizer.bypassSecurityTrustResourceUrl('path/to/trash-icon.svg')
    );
  }
}
