import { Location } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-missing-set-up-data-dialog',
  standalone: true,
  template: `
    <div mat-dialog-content>
      <p>
        {{data}}
        <br>
        Please contact
        <a href="mailto: membersupport-apis@vizientinc.com">
          membersupport-apis&#64;vizientinc.com
        </a>
        to resolve this issue.
      </p>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="okSelected()" cdkFocusInitial>Ok</button>
    </div>
  `,
  styles: [],
  imports: [RouterModule, MatDialogModule, MatButtonModule],
})
export class MissingSetUpDataDialogComponent {
  constructor(
    private readonly myself: MatDialogRef<MissingSetUpDataDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private location: Location
  ) {}

  okSelected() {
    this.location.back();
    this.myself.close();
  }
}
