import { DdpEnvironment } from './ddp-environment';

export const environment: DdpEnvironment = {
  production: true,
  okta: {
    clientId: '0oa109r59oisJYOEM0x8',
    issuer: 'https://login.alliancewebs.net/oauth2/ausmkot3jmgtQXrtZ0x7',
  },
  serverUrl: 'https://datadeliveryplatform-api.vizientinc.com',
  versionNumber:'1.2.7-Develop',
  termsUrl:'http://vizientinc.com',
  brandUrl:'http://vizientinc.com',
  complianceUrl: 'https://compliance.vizientinc.com',
  ga4EnterpriseId: 'GTM-WQJ8M4C5',
  contentHubUrl: 'https://notification.vizientinc.com',
};
