import { Observable } from 'rxjs';

//---------------------------Enumerator Values----------------------------------

/**
 * TODO: This enum is not being used anywhere.
 */
export enum UserStatus {
  Forbidden,
  None,
  Active,
  Cancelled,
}

/**
 * TODO: Why is the case used here different from other enums? Needs to be fixed
 * in C# code as well.
 *
 * This enumerator is used to describe the user state. It is used in
 * UserContractProperties.
 */
export enum UserState {
  active,
  blocked,
  deleted,
  pending,
}

/**
 * The Data Exchange Platform (DEP) has different types of filters ("where"
 * clause elements in SQL query) that are applied at the metadata level. This
 * enum is used to describe such filter types.
 */
export enum DepFilterCodeIdType {
  'STRING_EQUAL' = 1,
  'STRING_CONTAINS' = 1003,
  'INT_EQUAL' = 2,
  'DATE_EQUAL' = 4,
  'TEXT_MULTI_SELECTION' = 5,
  'DATE_RANGE' = 6,
  'SINGLE_SELECTION' = 10,
  'MULTI_SELECTION' = 11,
  'SINGLE_UI_SELECTION' = 12,
  'MULTI_UI_SELECTION' = 13,
  'STRING_MULTI' = 10001,
  'INT_MULTI' = 10002,
  'DATE_MULTI' = 10003,
}

/**
 * The Data Exchange Platform (DEP) has different types of status
 * for User Extracts to indicate wether its for Draft, Active, Canceled, Expired
 */
export enum DepScheduleStatusIdType {
  'Draft' = 1,
  'Active' = 2,
  'Canceled' = 3,
  'Expired' = 4,
  'Completed' = 5,
  'In Progress' = 6,
}

/**
 * DEP allows the user to save Schedule objects. These schedule objects in turn
 * specify a frequency in which the schedule should run. This enum is used to
 * describe those frequencies.
 */
export enum DepFrequencyCodeIdType {
  'Now' = 1,
  'One-time' = 2,
  'Daily' = 3,
  'Monthly' = 4,
  'Quarterly' = 5,
  'On-Data-Refresh' = 6,
}

/**
 * DEP allows the user to save Schedule objects. These schedule objects in turn
 * specify a channel in which the schedule is outputed. This enum is used to
 * describe those channels.
 */
export enum DepChannelIdType {
  'VizSFTP' = 1,
  'SDE' = 2,
  'Member SFTP' = 3,
  'Azure Blob Storage' = 4,
}

/**
 * DEP allows the user to save output objects. These output objects in turn
 * specify a channel type. This enum is used to
 * describe those channels.
 */
export enum DepStorageAccountStatus {
  'Registered' = 1,
  'InProcess' = 2,
  'Verified' = 3,
  'Denied' = 4,
  'Disabled' = 5,
  'Deleted' = 6,
}

/**
 * DEP allows the user to select filter type for string values
 * This enum is used to
 * describe those filter types.
 */
export enum StringMultiFilterOptions {
  'Equal' = 1,
  'Not Equal' = 101,
  'Begins With' = 1001,
  'Ends With' = 1002,
  'Contains' = 1003,
}

/**
 * DEP allows the user to select filter type for integer values
 * This enum is used to
 * describe those filter types.
 */
export enum IntMultiFilterOptions {
  'Equal' = 2,
  'Not Equal' = 102,
  'Greater Than' = 1004,
  'Greater or Equal' = 1005,
  'Less Than' = 1006,
  'Less or Equal' = 1007,
}

/**
 * DEP allows the user to select filter type for date values
 * This enum is used to
 * describe those filter types.
 */
export enum DateMultiFilterOptions {
  'Equal' = 4,
  'Not Equal' = 103,
  'Greater Than' = 1008,
  'Greater or Equal' = 1009,
  'Less Than' = 1010,
  'Less or Equal' = 1011,
}

/**
 * The Data Exchange Platform (DEP) has different types
 * of filter for Rolling Dates.This enum is used to
 * describe those filter types.
 */
export enum DepRollingDateFrequencyCodeIdType {
  'Previous Day' = 1,
  'Previous Month' = 2,
  'Previous Quarter' = 3,
  'Previous Year' = 4,
  'Date Range' = 5,
}

//------------------------------Interfaces--------------------------------------

/**
 * Generic interface used to describe key-value pairs.
 */
export interface IList {
  key: string;
  value: string;
}

/**
 * Basic user ID strcuture used in UserContractProperties below.
 */
export interface UserIdentityContract {
  id: string;
  provider: string;
}

/**
 * This interface is used to describe basic user attributes inside the
 * authentication and authorization context. It is used in UserContract below.
 */
export interface UserContractProperties {
  email: string;
  firstName: string;
  lastName: string;
  identities: UserIdentityContract[];
  note: string;
  registrationDate: string;
  state: UserState;
}

/**
 * TODO: If this interface is used in network communication with the API, it is
 * by definition a DTO so it should contain Dto in the name.
 *
 * This interface is used to specify a user contract inside the authentication
 * and authorization context.
 */
export interface UserContract {
  id: string;
  name: string;
  properties: UserContractProperties;
  type: string;
}

/**
 * TODO: If this interface is used in network communication with the API, it is
 * by definition a DTO so it should contain Dto in the name. Also it is unclear
 * what this is used for since it is imported but not used.
 */
export interface VizAuthConfig {
  clientId: string;
  issuer: string;
  redirectUri: string;
  logoutUrl: string;
  pkce: boolean;
}

/**
 * TODO: If this interface is used in network communication with the API, it is
 * by definition a DTO so it should contain Dto in the name.
 *
 * A Domain is the root object in the metadata hierarchy
 * (Domain->SubDomain->Dataset->SubDataset)
 */
export interface Domain {
  domainId: number;
  parentDomainId: number;
  name: string;
  description: string;
  ownerEmail: string;
  providerIamRoleId: number;
  uiName: string;
  uiDescription: string;
  uilearnMoreUrl: string;
  isComingSoon: boolean;
}

/**
 * TODO: This object should be merged with Domain above.
 *
 * This interface is used to specify a Domain object (Domains are the
 * root objects in the metadata hierarchy) in a Data Transfer Object. As you
 * can see, SubDomains are recursively specified as Domains inside this
 * interface, however, it is important to note that although this structure
 * allows it, subdomains cannot contain other subdomains (in other words,
 * the structure should only go 2 levels deep).
 */
export interface DomainCollection {
  readonly DomainId: number;
  readonly ParentDomainId: number;
  readonly subDomains: DomainCollection[];
  readonly Name: string;
  readonly DisplayName: string;
  readonly DisplayDescription: string;
  readonly DisplayUrl: string;
  readonly DisplayUrlName: string;
}

/**
 * TODO: If this interface is used in network communication with the API, it is
 * by definition a DTO so it should contain Dto in the name. Also, what is the
 * difference between this interface and the ones above for domain?
 */
export interface DomainData {
  domainName: string;
  domainDescription: string;
  subDomains: Domain[];
  datasetCollection: Dataset[];
}

/**
 * TODO: If this interface is used in network communication with the API, it is
 * by definition a DTO so it should contain Dto in the name.
 *
 * Domains and Subdomains contain Datasets. A dataset is a collection of
 * subdatasets.
 */
export interface Dataset {
  datasetId: number;
  domainId: number;
  name: string;
  description: string;
  uiName: string;
  uiDescription: string;
  ownerEmail: string;
  approxExtractTimeInMin: number;
  defaultExtractName: string;
  lastRefreshedDtTm: string;
  roleId: number;
  productId: number;
  shortName: string;
}

/**
 * TODO: Is this interface used in network communications with API? If so, it is
 * by definition a DTO so it should contain Dto in the name. Also please explain
 * what the exact use of it is and what is the difference with the interface
 * above.
 */
export interface DataSetInfo {
  domainName: string;
  domainDescription: string;
  ownerEmail: string;
}

/**
 * TODO: If this interface is used in network communication with the API, it is
 * by definition a DTO so it should contain Dto in the name.
 *
 * A SubDataset is the lowest common denominator in the DEP hierarchy. The user
 * will get one extract/file per subdataset.
 */
export interface SubDataset {
  subDatasetId: number;
  datasetId: number;
  name: string;
  description: string;
  uiName: string;
  uiDescription: string;
  defaultDatasetName: string;
  connectionString: string;
  serverName: string;
  databaseName: string;
  tableName: string;
  viewName: string;
  sprocName: string;
  jsonSprocParameters: string;
  defaultSelection: boolean;
  mandatoryFilter: string;
}

/**
 * TODO: What is a Sub Definition? Shouldn't this be a SubDataset? This can
 * probably be merged with the interface above.
 */
export interface SubDefinitionDto {
  subDatasetId: number;
  includeInExtract: boolean;
  rollingDateRange: string[];
  rollingDateType: number;
  includedFields: number[];
  datasetFields: DatasetFieldDto[];
}

/**
 * This DatasetField class is not the same one we have in the BE
 * TODO: refactor, "selectedFilterCodeId" and "userValue" shouldn't be here
 */
export interface DatasetField {
  datasetFieldId?: number;
  datasetFieldMultivalues?: DatasetFieldMultiValues[];
  datasetId?: number;
  dataType?: string;
  dbFieldName?: string;
  depFilterCodeId?: number;
  description?: string;
  fieldTextLength?: number;
  maxRange?: string;
  uiDescription?: string;
  uiFilterHelpText?: string;
  uiName?: string;
  subDatasetId?: number;
  uiHeaderText?: string;
  mandatoryFilter: boolean;
  commonFilter: boolean;
  mandatoryField: boolean;

  selectedFilterCodeId?: string;
  userValue: string;
}

export interface DatasetFieldUserDefinitionDto {
  selectedFilterCodeId: number;
  userValue: string[];
}

export interface DatasetFieldDto extends DatasetFieldUserDefinitionDto {
  datasetFieldId: number;
}

/**
 * A channel specifies a destination for an extract (e.g. VizSFTP or SDE). This
 * interface describes such destination per dataset.
 */
export interface DatasetChannelDto {
  dsChannelId: number;
  datasetId: number;
  depChannelId: number;
  uiName: string;
  uiHelpText?: string;
}

/**
 * Extracts can take the form of different file types (e.g. CSV, PSV, etc). This
 * interface describes such file types per dataset.
 */
export interface DatasetOutputFileTypeDto {
  dsOutputFileTypeId: number;
  datasetId: number;
  depOutputFileTypeId: number;
  uiName: string;
  uiHelpText?: string;
}

export interface DatasetOutputFileCompressionFormatDto {
  dsOutputFileCompressionFormatId: number;
  datasetId: number;
  depOutputFileCompressionFormatId: number;
  uiName: string;
  uiHelpText?: string;
}

/**
 * TODO: If this interface is used in network communication with the API, it is
 * by definition a DTO so it should contain Dto in the name.
 */
export interface DatasetFieldMultiValues {
  dsFldMultivalueId?: number;
  datasetFieldId?: number;
  value?: string;
  uiValue?: string;
  sortOrder?: number;
}

/**
 * After a user in DEP has selected the appropriate dataset, filters, delivery
 * method, etc, what the user saves is a Sechedule object. This interface
 * describes such object.
 */
export interface ScheduleDto {
  scheduleId: number | undefined;
  userId: number;
  datasetId: number;
  memberId: string;
  clonedFromId?: number;
  depScheduleStatusId: number;
  depFrequencyCodeId: number;
  name: string;
  description: string; //notes
  fileName: string;
  depOutputFileTypeId: number;
  depOutputFileCompressionFormatId: number;
  depChannelId: number;
  oneTimeDt: string;
  frequencyStartDt: string;
  frequencyEndDt: string;
  subDefinitions: SubDefinitionDto[];
  depScheduleStatusStatus?: string;
  storageAccountId?: number;
  commonDatasetFieldsUserDefinition: ScheduleCommonDatasetFieldUserDefinitionDto[];
  isDeleted?: boolean;
}

/**
 * After a user has selected schedule item then we use this object
 * to show details for each user extract / schedule
 */
export interface ScheduleTrackingDto {
  scheduleTrackingId?: number;
  scheduleId: number;
  depScheduleTrackingStatusId?: number;
  depScheduleStatusId: number;
  timestamp: Date;
  userId?: number;
  scheduleName?: string;
}

/**
 * After a user has selected schedule item then we use this object
 * to show pop up echedule details
 */
export interface ScheduleItemTrackingDto {
  scheduleTrackingId?: number;
  executionTime?: Date;
  startTime?: Date;
  deliveryTime?: Date;
  channelName?: string;
  fileName?: string;
  numberOfRecords?: number;
  includedFields?: string[];
  filters?: string[];
  domainName?: string;
  subDomainName?: string;
  memberShortName?: string;
  memberId?: number;
  destinationFileName?: string;
  domainDescription?: string;
  fileType?: string;
  trackingStatus?: string;
}

export interface ScheduleCommonDatasetFieldUserDefinitionDto extends DatasetFieldUserDefinitionDto {
  datasetFieldDbFieldName: string;
}

/**
 * This dto is used to get StorageAccount from db and bind to ddl
 * in the output tab
 */
export interface StorageAccount {
  storageAccountId?: number;
  userId?: number;
  name: string;
  description: string;
  serverNameIp: string;
  username: string;
  keyVaultId?: number;
  destinationpath: string;
  clientContact: string;
  active?: boolean;
  isDeleted?: string;
  statusId?: number;
  status?: string;
  password: string;
  type: StorageAccountType;
}

export enum StorageAccountType {
  MemberSftp = 1,
  AzureBlobStorage = 2,
  AmazonStorageBucket = 3,
  GoogleCloudPlatform = 4,
}

export interface StorageAccountSelectOption {
  storageAccountId?: number;
  name: string;
}

/**
 * This model is used to pass StorageAccount password update detail to api
 * in the output tab
 */
export interface StorageAccountPassword {
  StorageAccountId?: number;
  userId?: number;
  oldPassword: string;
  newPassword: string;
  keyVaultId?: number;
}

/**
 * Simplified Schedule DTO for the summary page.
 */
export interface ScheduleTrackingStatusUpdateDto {
  scheduleId: number;
  depScheduleTrackingStatusId: number;
  name: string;
}

/**
 * TODO: Why is this a class and not an interface like the rest?
 *
 * This class describes the attributes for a User of the Data Exchange Platform.
 */
export class UserDto {
  userId: number;
  userGuid: string;
  userName: string;
  iamMemberId: string;
  firstName: string;
  lastName: string;
  email: string;
  lastLoggedIn: Date;
}

/**
 Interface to receive resposne for user notifications
 */
export interface NotificationDto {
  notificationId: number;
  notificationTypeId: number;
  scheduleId: number;
  extractHistoryId: number;
  userId: number;
  createdDtTm: string;
  to: string;
  from: string;
  subject: string;
  body: string;
  miscJson: string;
}
/**
Interface to receive paginated resposne for notifications
 */
export interface NotificationCollectionDto {
  notifications: NotificationDto[];
  totalCount: number;
}

/**
Interface to receive paginated resposne for notifications
 */
export interface StorageAccountCollection {
  storageAccounts: StorageAccount[];
  totalCount: number;
}

/**
 Interface to receive resposne for IAM sub-products
 */
export interface SubProductsDto {
  productName: string;
  productType: string;
  websiteDescription: string;
  psgWebtagUrl: string;
}

/**
 Interface for DatasetRollingDateDto
 */
export interface DatasetRollingDateDto {
  datasetRollingDateFrequencyCodeId: number;
  datasetId: number;
  depRollingDateFrequencyCodeId: number;
  uiName: string;
  uiHelpText: string;
}

/**
 * TODO: Why is this a class and not an interface like the rest? Also why are
 * we declaring differently than the other classes in this file?
 * TODO: If this interface is used in network communication with the API, it is
 * by definition a DTO so it should contain Dto in the name.
 */
export class UserProductRoleMember {
  public UserId: string;
  public UserName: string;
  public product_id: number;
  public ProductName: string;
  public role_id: number;
  public RoleName: string;
  public MemberId: string;
  public MemberName: string;

  constructor(
    userId: string,
    userName: string,
    productId: number,
    productName: string,
    roleId: number,
    roleName: string,
    memberId: string,
    memberName: string
  ) {
    this.UserId = userId;
    this.UserName = userName;
    this.product_id = productId;
    this.ProductName = productName;
    this.role_id = roleId;
    this.RoleName = roleName;
    this.MemberId = memberId;
    this.MemberName = memberName;
  }
}

/**
 * TODO: If this interface is used in network communication with the API, it is
 * by definition a DTO so it should contain Dto in the name.
 *
 * API call binding object used in schedule component.
 */
export interface IDatasetFrequencyCode {
  depFrequencyCodeId: number;
  uiName: string;
  datasetFrequencyCodeId: number;
  datasetId: number;
  uiHelpText: string;
}

/**
 Interface for Member Product Role (iam member)
 */
export interface UserProductRole {
  hco_short_name: string;
  member_id: number;
  member_name: string;
  product_id: number;
  role_id: number;
  role_name: string;
  username: string;
}

/**
 Interface for passing data between components
 */
export interface PassingComponentData {
  scheduleId?: number;
  scheduleName?: string;
}
