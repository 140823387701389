<div style="margin-bottom: 40px;">
  <h4 style="margin:0px"><span>Schedules - {{scheduleName}}</span>
    <span style="float: right;" (click)="goBack()" matTooltip="Back" id="trackinglistGoBack"><i class="fas fa-chevron-circle-left backBtn"></i></span>
  </h4>
</div>
<div>
  <mat-table [dataSource]="dataSetSchedules" style="width:100%" class="mat-elevation-z8">
    <ng-container matColumnDef="scheduleTrackingId">
      <mat-header-cell *matHeaderCellDef class="center">ScheduleTrackingId</mat-header-cell>
      <mat-cell *matCellDef="let extract" class="center">
        <div class="cursor-pointer" (click)="viewPopupDetails(extract)">
          {{ extract.scheduleTrackingId }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="depScheduleTrackingStatusId">
      <mat-header-cell *matHeaderCellDef class="center">Schedule tracking status</mat-header-cell>
      <mat-cell *matCellDef="let extract" class="center">
        <div class="cursor-pointer" (click)="viewPopupDetails(extract)">
          {{ extract.depScheduleTrackingStatusId |
          depScheduleTrackingStatus }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="scheduleId">
      <mat-header-cell *matHeaderCellDef class="center">scheduleId</mat-header-cell>
      <mat-cell *matCellDef="let extract" class="center">
        <div class="cursor-pointer" (click)="viewPopupDetails(extract)">
          {{ extract.scheduleId }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="timestamp">
      <mat-header-cell *matHeaderCellDef class="center">Delivered date</mat-header-cell>
      <mat-cell *matCellDef="let extract" class="center">
        <div class="cursor-pointer" (click)="viewPopupDetails(extract)">
          {{ extract.timestamp | date: 'shortDate'}}
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="[ 'depScheduleTrackingStatusId', 'timestamp']"></mat-header-row>
    <mat-row *matRowDef="let row; columns: [ 'depScheduleTrackingStatusId',  'timestamp']"></mat-row>
  </mat-table>

  <mat-paginator #paginator [length]="totalItems" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
    (page)="onPageChange($event)"></mat-paginator>
</div>