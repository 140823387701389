import { Routes } from '@angular/router';
import { VizAuthGuard } from './guards';

import {
  DashboardComponent,
  HomePageComponent,
  MyNotificationsComponent,
  MySchedulesComponent,
  MySchedulesTrackingListComponent,
  StorageAccountComponent,
  StorageAccountsComponent,
  UserExtractComponent,
} from './pages';

export const APP_ROUTES: Routes = [
  {
    path: '',
    component: HomePageComponent,
  },
  {
    path: 'domain',
    loadChildren: () =>
      import('./domain/domain.module').then((m) => m.DomainModule), // this is in an NgModule
    // data: { breadCrumb: 'Home' },
  },
  {
    path: 'app',
    canLoad: [VizAuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'schedules',
        component: MySchedulesComponent,
      },
      {
        path: 'schedules/:scheduleId',
        component: UserExtractComponent,
      },
      {
        path: 'schedule/tracking/list',
        component: MySchedulesTrackingListComponent,
      },
      {
        path: 'my-notifications',
        component: MyNotificationsComponent,
      },
      {
        path: 'storage-accounts',
        component: StorageAccountsComponent,
      },
      {
        path: 'storage-accounts/:id',
        component: StorageAccountComponent,
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];
