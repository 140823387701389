import { Component, HostListener, Inject, OnInit } from '@angular/core';
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { SafeUrlPipe } from 'src/app/pipes/safe-url.pipe';
import { ComplianceNoticeService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-compliance-notice',
  standalone: true,
  imports: [MatDialogModule, SafeUrlPipe],
  template: `
    <iframe
      [src]="complianceUiUrl | safeUrl"
      style="height: 99%; width: 100%; border: none;"
    >
    </iframe>
  `,
})
export class ComplianceNoticeComponent implements OnInit {
  complianceUiUrl!: string;

  constructor(
    private complianceNoticeService: ComplianceNoticeService,
    private dialogRef: MatDialogRef<ComplianceNoticeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { productMetisId: string; roleId: string; tocProductName: string }
  ) { }

  ngOnInit() {
    this.complianceUiUrl =
      environment.complianceUrl +
      '/getnotificationsuser/' +
      this.data.productMetisId +
      '/' +
      this.data.roleId +
      '/' +
      this.data.tocProductName;
  }

  /* istanbul ignore next */
  @HostListener('window:message', ['$event'])
  handleEvent(event: MessageEvent) {
    if (event.origin !== environment.complianceUrl || !event.data) {
      return;
    }
    this.complianceNoticeService.notificationsAccepted = event.data;

    if (typeof event.data === 'boolean' && event.data === true) {
      this.dialogRef.close();
    }
  }
}
