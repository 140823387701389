import { Injectable } from '@angular/core';
import { filter, map, switchMap, tap } from 'rxjs';
import { UserProductRoleMember } from 'src/app/models';
import { AuthService, UserService } from 'src/app/services';

interface CachedUserRoles { roles: UserProductRoleMember[]; expirationTime: number }

@Injectable({providedIn: 'root'})
export class HomePageService {
  constructor(
    private readonly authService: AuthService,
    private readonly userService: UserService,
  ) { }

  // TODO: ktowe 1/28/2025, delete this comment or take action
  // This logic was taken from the home component. It really just needs to run once on app load so the home-component is a convenient place
  loadUserRolesIfExpiredOrNotPresent() {
    this.authService.authenticated$.pipe(
      filter(authStatus => authStatus === true),
      map(() => sessionStorage.getItem('userRoles')),
      filter((roles) => !roles || this.isExpired(roles) ? true : false),
      switchMap(() => this.userService.getLoggedInUserRoles()),
      tap((roles: UserProductRoleMember[] ) => {
        const expirationTime = new Date().getTime() + 10 * 60 * 1000;
        const dataWithExpiration: CachedUserRoles = {
          roles,
          expirationTime,
        };
        sessionStorage.setItem('userRoles', JSON.stringify(dataWithExpiration));
      })
    ).subscribe(); // its okay, this is a long lived observable
  }

  private isExpired(storedRolesJson: string): boolean {
    try {
      const storedRoles = JSON.parse(storedRolesJson) as CachedUserRoles;

      return storedRoles.expirationTime > new Date().getTime();
    } catch (err) {
      return true;
    }
  }
}
