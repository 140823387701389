import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appHeaderLessTabs]',
  standalone: true
})
export class HeaderLessTabsDirective implements OnInit {
  @Input() showHeaderTabs: boolean;

  constructor(private eleRef: ElementRef) {}

  ngOnInit(): void {
    if(!this.showHeaderTabs)
      this.eleRef.nativeElement.children[0].style.display = 'none';
  }
}
