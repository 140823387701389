/* eslint-disable max-len */
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { NotificationService } from 'src/app/services';
import { SharedModule } from 'src/app/shared';
import { FilterFormFieldSelectionComponent } from '../filter-form-field-selection/filter-form-field-selection.component';
import { FilterFormFieldTextOrDateWithSelectionComponent } from '../filter-form-field-text-or-date-with-selection/filter-form-field-text-or-date-with-selection.component';
import { DepFilterCodeIdType } from 'src/app/models';


@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [
    SharedModule,
    FilterFormFieldSelectionComponent,
    FilterFormFieldTextOrDateWithSelectionComponent,
  ],
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class FilterComponent implements OnInit, OnDestroy {
  @ViewChild('panelInner') panelInner;
  @Input() filterForm: FormGroup;

  depFilterCodeIdType = DepFilterCodeIdType;
  notificationSubscription: Subscription;

  constructor(private readonly notification: NotificationService) { }

  ngOnInit(): void {
    this.notificationSubscription = this.notification.currentMessage.subscribe(
      eventMessage => {
        if (eventMessage === 'open') {
          this.expandPanel();
        }
        if (eventMessage === 'close') {
          this.collapsePanel();
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.notificationSubscription) {
      this.notificationSubscription.unsubscribe();
    }
  }

  expandPanel() {
    this.panelInner?.open();
  }

  collapsePanel() {
    this.panelInner?.close();
  }
}
