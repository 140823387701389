import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DatasetFieldService } from 'src/app/services';
import { DateMultiFilterOptions, DepFilterCodeIdType, IntMultiFilterOptions, StringMultiFilterOptions } from 'src/app/models';
import { mapEnumToKeyValueArray, SharedModule } from 'src/app/shared';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-filter-form-field-text-or-date-with-selection',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './filter-form-field-text-or-date-with-selection.component.html',
  styleUrls: ['./filter-form-field-text-or-date-with-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterFormFieldTextOrDateWithSelectionComponent implements OnInit, OnDestroy {
  @Input() filterForm: FormGroup;

  isString = false;
  isInteger = false;
  isStringWithSelect = false;
  isIntegerWithSelect = false;
  isDate = false;
  isDateWithSelect = false;
  isDateRange = false;

  filterOptions: { key: string; value: string }[] = [];
  lengthMax = 1000;
  dateRangeForm = new FormGroup({
    start: new FormControl<Date | undefined>(undefined),
    end: new FormControl<Date | undefined>(undefined),
  });
  minDate: Date;

  textAreaRow: number;
  textAreaCol: number;
  hint: string;
  private subscription: Subscription;

  constructor(private readonly datasetFieldService: DatasetFieldService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    switch (this.filterForm.get('depFilterCodeId').value) {
      case DepFilterCodeIdType.STRING_EQUAL:
      case DepFilterCodeIdType.STRING_CONTAINS:
      case DepFilterCodeIdType.TEXT_MULTI_SELECTION:
      case DepFilterCodeIdType.MULTI_SELECTION:
        this.isString = true;
        this.setTextAreaConfigurations();
        this.setMaxLength();
        break;

      case DepFilterCodeIdType.INT_EQUAL:
        this.isInteger = true;
        this.setTextAreaConfigurations();
        this.setMaxLength();
        break;

      case DepFilterCodeIdType.STRING_MULTI:
        this.isStringWithSelect = true;
        this.setTextAreaConfigurations();
        this.filterOptions = mapEnumToKeyValueArray(StringMultiFilterOptions);
        this.setSelectedFilterCodeIdValueAsString();
        break;

      case DepFilterCodeIdType.INT_MULTI:
        this.isIntegerWithSelect = true;
        this.setTextAreaConfigurations();
        this.filterOptions = mapEnumToKeyValueArray(IntMultiFilterOptions);
        this.setSelectedFilterCodeIdValueAsString();
        break;

      case DepFilterCodeIdType.DATE_EQUAL:
        this.isDate = true;
        this.setMinDate();
        break;

      case DepFilterCodeIdType.DATE_MULTI:
        this.isDateWithSelect = true;
        this.setMinDate();
        this.filterOptions = mapEnumToKeyValueArray(DateMultiFilterOptions);
        this.setSelectedFilterCodeIdValueAsString();
        break;

      case DepFilterCodeIdType.DATE_RANGE:
        this.isDateRange = true;
        this.setMinDate();
        this.setDateRangeFormValues();
        break;

      default:
        throw new Error(`Invalid DepFilterCodeIdType=${this.filterForm.get('depFilterCodeId').value}`);
    }

    this.setHint();
    this.subscription = this.datasetFieldService.moveToNextOnIncompletedFiltersStepClicked$.subscribe(() => {
      this.showErrorMessages();
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  showErrorMessages() {
    this.filterForm.markAllAsTouched();
    this.cdr.detectChanges();
  }

  setMaxLength(){
    if (this.filterForm.controls.fieldTextLength.value) {
      this.lengthMax = this.filterForm.controls.fieldTextLength.value;
    }
  }

  setTextAreaConfigurations(){
    if (Number(this.filterForm.controls.fieldTextLength.value) > 300) {
      this.textAreaRow = 4;
      this.textAreaCol = 100;
    } else {
      this.textAreaRow = 1;
      this.textAreaCol = 50;
    }
  }

  setSelectedFilterCodeIdValueAsString(){
    this.filterForm.controls.selectedFilterCodeId.setValue(
      this.filterForm.controls.selectedFilterCodeId.value
        ? this.filterForm.controls.selectedFilterCodeId.value.toString()
        : this.filterOptions[0].key //'string' by default
    );
  }

  setDateRangeFormValues(){
    if(!this.filterForm.controls.userValue.value)
      return;

    const startEndDate = this.filterForm.controls.userValue.value.split(',');

    const startDate = new Date(startEndDate[0]);
    startDate.setDate(startDate.getDate());
    this.dateRangeForm.controls.start.setValue(startDate);

    const endDate = new Date(startEndDate[1]);
    endDate.setDate(endDate.getDate());
    this.dateRangeForm.controls.end.setValue(endDate);
  }

  setMinDate() {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDay = new Date().getDate();

    this.minDate = new Date(currentYear - 10, currentMonth, currentDay);
  }

  updateUserValueForDateRange() {
    const startDateString = this.formatDateValueToString(this.dateRangeForm.value.start);

    let endDateString = '';
    if(this.dateRangeForm.value.end)
      endDateString = this.formatDateValueToString(this.dateRangeForm.value.end);

    this.filterForm.controls.userValue.setValue([startDateString, endDateString]);

    if(this.filterForm.controls.isContainedInACommonFiltersTab.value)
      this.notifyCommonDatasetFieldChanged();
  }

  private formatDateValueToString(date: Date): string {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const dayOfTheMonth = date.getDate();

    return `${year}-${month}-${dayOfTheMonth}`;
  }

  setHint() {
    if (this.isString || this.isInteger || this.isStringWithSelect || this.isIntegerWithSelect) {
      this.hint = `${this.filterForm.controls.userValue.value ? this.filterForm.controls.userValue.value.length : 0} / ${this.lengthMax}`;
      this.filterForm.controls.userValue.valueChanges.subscribe(
        userValue => this.hint = `${userValue ? userValue.length : 0} / ${this.lengthMax}`
      );
    }

    if (this.isDate ||  this.isDateWithSelect || this.isDateRange) {
      this.hint = `MM/DD/YYYY ${this.isDateRange ? '– MM/DD/YYYY' : ''}`;
    }
  }

  keepOnlyNumbers(event) {
    return (event.charCode === 8 || event.charCode === 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  notifyCommonDatasetFieldChanged() {
    this.datasetFieldService.emitCommonDatasetFieldChanged(
      {
        dbFieldName: this.filterForm.controls.dbFieldName.value,
        selectedFilterCodeId: this.filterForm.controls.selectedFilterCodeId.value,
        userValue: this.filterForm.controls.userValue.value
      }
    );
  }
}
