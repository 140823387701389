/* eslint-disable max-len */
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ChangeDetectorRef
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { map, Observable, Subscription } from 'rxjs';
import { DatasetFieldMultiValues, DepFilterCodeIdType } from 'src/app/models';
import { DatasetFieldService } from 'src/app/services';
import { SharedModule } from 'src/app/shared';

@Component({
  selector: 'app-filter-form-field-selection',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './filter-form-field-selection.component.html',
  styleUrls: ['./filter-form-field-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterFormFieldSelectionComponent implements OnInit, OnDestroy {
  @Input() filterForm: FormGroup;

  isMultiple: boolean;
  stepFiltersCompleted$: Observable<boolean>;
  showErrors: boolean;
  private subscription: Subscription;
  currentValue: string;
  filterInput = new FormControl('');
  filteredValues: string[];
  isViewEnabled = true;

  constructor(private readonly datasetFieldService: DatasetFieldService, private cdr: ChangeDetectorRef) {
    this.stepFiltersCompleted$ = this.datasetFieldService.checkIfAllMandatoryFieldsAreTrue$();
    this.showErrors = false;
  }

  ngOnInit(): void {
    if (history.state.isCreate){
      this.isViewEnabled = false;
    } else {
      this.isViewEnabled = history.state.isView ?? true;
    }

    console.log('isViewEnabled:', this.isViewEnabled);

    this.datasetFieldService.datasetFieldMultiValuesChanged$.subscribe(
      datasetFieldMultiValuesByDatasetFieldIdAndBySubDatasetId => this.updateDatasetFieldMultiValues(datasetFieldMultiValuesByDatasetFieldIdAndBySubDatasetId)
    );

    this.isMultiple = this.filterForm.get('depFilterCodeId').value === DepFilterCodeIdType.MULTI_UI_SELECTION;

    if (this.isMultiple) {
      if (this.filterForm.controls.userValue?.value) {
        const selectedValuesAsArray = this.filterForm.controls.userValue.value.split(',');
        this.filterForm.controls.userValue.setValue(selectedValuesAsArray);
      } else {
        this.filterForm.controls.userValue.setValue([]);
      }
    }

    this.subscription = this.datasetFieldService.moveToNextOnIncompletedFiltersStepClicked$.subscribe(() => {
      this.showErrorMessages();
    });

    this.filteredValues = this.filterForm.controls.datasetFieldMultivalues.value.value;
    this.filterInput.valueChanges.pipe(
      map(value => {
        const options = this.filterForm.controls.datasetFieldMultivalues.value.value;
        this.filteredValues = options.filter(s => (s.uiValue as string).toLowerCase().includes(value.toLowerCase()));
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  showErrorMessages() {
    this.filterForm.markAllAsTouched();
    this.cdr.detectChanges();
  }

  private updateDatasetFieldMultiValues(
    datasetFieldMultiValuesByDatasetFieldIdAndBySubDatasetId: { [subDatasetId: number]: { [datasetFieldId: number]: DatasetFieldMultiValues[] } }
  ) {
    this.filterForm.controls.userValue.setValue([]);
    this.filterForm.controls.userValue.setErrors(null);
    this.filterForm.controls.userValue.markAsPristine();
    this.filterForm.controls.userValue.markAsUntouched();

    this.filterForm.patchValue(
      {
        datasetFieldMultivalues: {
          value: datasetFieldMultiValuesByDatasetFieldIdAndBySubDatasetId[this.filterForm.controls.subDatasetId.value][this.filterForm.controls.datasetFieldId.value]
        }
      }
    );
  }

  notifyCommonDatasetFieldChanged() {
    this.datasetFieldService.emitCommonDatasetFieldChanged(
      {
        dbFieldName: this.filterForm.controls.dbFieldName.value,
        selectedFilterCodeId: undefined,
        userValue: this.filterForm.controls.userValue.value
      }
    );
  }

  onOptionClick(event: any) {
    const selectedValue = event.option.viewValue;
    if (this.isMultiple) {
      const currentValues: string[] = this.filterForm.controls.userValue.value;
      if(currentValues.indexOf(selectedValue) >= 0) {
        currentValues.splice(currentValues.indexOf(selectedValue), 1);
      } else {
        currentValues.push(selectedValue);
      }
      this.filterForm.controls.userValue.setValue(currentValues);
    } else {
      this.filterForm.controls.userValue.setValue(selectedValue);
    }

    if (this.isMultiple === false) {
      if (selectedValue === this.currentValue) {
        this.filterForm.controls.userValue.setValue(null);
      } else {
        this.filterForm.controls.userValue.setValue(selectedValue);
      }
    }

    if (this.filterForm.controls.isContainedInACommonFiltersTab.value) {
      this.notifyCommonDatasetFieldChanged();
    }

    this.currentValue = this.filterForm.controls.userValue.value;
    this.filterInput.setValue('');
  }

  removeUserValue(value: any) {
    const currentValues = this.filterForm.controls.userValue.value;
    if (currentValues?.constructor.name === 'Array') {
      currentValues.splice(currentValues.indexOf(value), 1);
      this.filterForm.controls.userValue.setValue(currentValues);
    } else {
      this.filterForm.controls.userValue.setValue(null);
    }
  }
}
