<ng-container [formGroup]="scheduleStepForm" #scheduleForm="ngForm">

  <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
    (change)="radioChange($event)" formControlName="scheduleRadioValue">

    <table class="table">
      <tr>
        <td class="textCenter">START</td>
        <td class="tdVerticalLine"></td>
        <td class="textCenter">END</td>
      </tr>
      <tr>
        <td colspan="3" class="topHRPadding">
          <hr />
        </td>
      </tr>
      <tr>
        <td>
          <table>

            <ng-container *ngFor="let frequency of frequencies">
              <!-- {{frequency.depFrequencyCodeId}}{{frequency.uiName}} <br /> -->

              <ng-template #now [ngIf]="frequency.depFrequencyCodeId === depFrequencyCodeIdType.Now">
                <tr>
                  <td colspan="2" class="tdPadding">
                    <mat-radio-button [value]="frequency.depFrequencyCodeId">{{frequency.uiName}}</mat-radio-button>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="hrPadding">
                    <hr />
                  </td>
                </tr>
              </ng-template>

              <ng-template #daily [ngIf]="frequency.depFrequencyCodeId === depFrequencyCodeIdType.Daily">
                <tr>
                  <td class="tdRightPadding">
                    <mat-radio-button [value]="frequency.depFrequencyCodeId">{{frequency.uiName}}</mat-radio-button>
                  </td>
                  <td class="datePickerPaddingLeft">
                    <mat-form-field appearance="fill">
                      <input matInput [matDatepicker]="pickerDaily" [min]="minDate"
                        formControlName="selectedDateDaily">
                      <mat-hint>MM/DD/YYYY</mat-hint>
                      <mat-datepicker-toggle matSuffix [for]="pickerDaily"></mat-datepicker-toggle>
                      <mat-datepicker #pickerDaily></mat-datepicker>
                    </mat-form-field>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="hrPadding">
                    <hr />
                  </td>
                </tr>
              </ng-template>

              <ng-template #oneTime [ngIf]="frequency.depFrequencyCodeId === depFrequencyCodeIdType['One-time']">
                <tr>
                  <td class="tdRightPadding">
                    <mat-radio-button [value]="frequency.depFrequencyCodeId">{{frequency.uiName}}</mat-radio-button>
                  </td>
                  <td class="datePickerPaddingLeft">
                    <mat-form-field appearance="fill">
                      <input matInput [matDatepicker]="pickerOneTime" [min]="minDate"
                        formControlName="selectedDateOneTime">
                      <mat-hint>MM/DD/YYYY</mat-hint>
                      <mat-datepicker-toggle matSuffix [for]="pickerOneTime"></mat-datepicker-toggle>
                      <mat-datepicker #pickerOneTime></mat-datepicker>
                    </mat-form-field>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="hrPadding">
                    <hr />
                  </td>
                </tr>
              </ng-template>

              <ng-template #monthly [ngIf]="frequency.depFrequencyCodeId === depFrequencyCodeIdType.Monthly">
                <tr>
                  <td class="tdRightPadding">
                    <mat-radio-button [value]="frequency.depFrequencyCodeId">{{frequency.uiName}}</mat-radio-button>
                  </td>
                  <td class="datePickerPaddingLeft">
                    <mat-form-field appearance="fill">
                      <input matInput [matDatepicker]="pickerMonthly" [min]="minDate"
                        formControlName="selectedDatMonthly">
                      <mat-hint>MM/DD/YYYY</mat-hint>
                      <mat-datepicker-toggle matSuffix [for]="pickerMonthly"></mat-datepicker-toggle>
                      <mat-datepicker #pickerMonthly></mat-datepicker>
                    </mat-form-field>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="hrPadding">
                    <hr />
                  </td>
                </tr>
              </ng-template>

              <ng-template #quarterly [ngIf]="frequency.depFrequencyCodeId === depFrequencyCodeIdType.Quarterly">
                <tr>
                  <td class="tdRightPadding">
                    <mat-radio-button [value]="frequency.depFrequencyCodeId">{{frequency.uiName}}
                      <div style="font-size: small;">
                        (Subsequent will be<br />calculated for the<br />following quarter)
                      </div>
                    </mat-radio-button>
                  </td>
                  <td class="datePickerPaddingLeft">
                    <mat-form-field appearance="fill">
                      <input matInput [matDatepicker]="pickerQuarterly" [min]="minDate"
                        formControlName="selectedDatQuarterly">
                      <mat-hint>MM/DD/YYYY</mat-hint>
                      <mat-datepicker-toggle matSuffix [for]="pickerQuarterly"></mat-datepicker-toggle>
                      <mat-datepicker #pickerQuarterly></mat-datepicker>
                    </mat-form-field>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="hrPadding">
                    <hr />
                  </td>
                </tr>
              </ng-template>

              <ng-template [ngIf]="frequency.depFrequencyCodeId !== depFrequencyCodeIdType.Now &&
              frequency.depFrequencyCodeId !== depFrequencyCodeIdType.Daily &&
              frequency.depFrequencyCodeId !== depFrequencyCodeIdType['One-time'] &&
              frequency.depFrequencyCodeId !== depFrequencyCodeIdType.Monthly
              && frequency.depFrequencyCodeId !== depFrequencyCodeIdType.Quarterly">
                <tr>
                  <td colspan="2" class="tdPadding">
                    <mat-radio-button [value]="frequency.depFrequencyCodeId">{{frequency.uiName}}</mat-radio-button>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="hrPadding">
                    <hr />
                  </td>
                </tr>
              </ng-template>

            </ng-container>
          </table>
        </td>
        <td class="tdVerticalLine"></td>
        <td>
          <table>
            <tr>
              <td class="tdScheduleEnd"> Scheduled end</td>
              <td>
                <mat-card class="demo-inline-calendar-card" [ngClass]="{ 'disabled': isViewEnabled }">
                  <mat-calendar [minDate]="scheduleEndDateMin" [(selected)]="scheduledEndDate" [selected]="yearOutDate"
                    [startAt]="yearOutDate" [maxDate]="scheduleEndDateMaxMinusOne"
                    (selectedChange)="onScheduledEndDate($event)"></mat-calendar>
                </mat-card>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>

  </mat-radio-group>

</ng-container>
