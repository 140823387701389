<div style="margin-bottom: 40px;">
  <h4 style="margin:0px">Schedules</h4>

</div>
<div>
  <mat-table [dataSource]="dataSetSchedules" style="width:100%" class="mat-elevation-z8">

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef class="center">Name</mat-header-cell>
      <mat-cell *matCellDef="let extract" class="center"><a (click)="viewExtract(extract)" class="title">
        {{ extract.name }}
      </a></mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
      <mat-cell *matCellDef="let extract">{{ extract.description }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="depFrequencyCodeId">
      <mat-header-cell *matHeaderCellDef class="center">Frequency</mat-header-cell>
      <mat-cell *matCellDef="let extract" class="center">{{ extract.depFrequencyCodeId | depFrequency}}</mat-cell>
    </ng-container>    

    <ng-container matColumnDef="depScheduleStatusStatus">
      <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
      <mat-cell *matCellDef="let extract">{{ extract.depScheduleStatusStatus }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="frequencyStartDt">
      <mat-header-cell *matHeaderCellDef>Frequency Start Date</mat-header-cell>
      <mat-cell *matCellDef="let extract">{{ extract.frequencyStartDt | date: 'shortDate' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="frequencyEndDt">
      <mat-header-cell *matHeaderCellDef>Frequency End Date</mat-header-cell>
      <mat-cell *matCellDef="let extract">{{ extract.frequencyEndDt | date: 'shortDate' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions" class="center">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
      <mat-cell *matCellDef="let extract">
        <div class="button-container">
          <a data-test="grid-icon" [ngClass]="{'disabled-link': extract.depScheduleStatusId !== depScheduleStatusIdType.Draft}" class="edit-btn"
            (click)="editExtract(extract)" matTooltip="Edit Schedule">
            <i class="fas fa-pencil-alt"></i>
          </a>

          <a data-test="grid-icon"
            [ngClass]="{'disabled-link': (extract.depScheduleStatusId === depScheduleStatusIdType.Canceled || extract.depScheduleStatusId === depScheduleStatusIdType.Expired)}"
            class="delete-btn" (click)="cancelExtract(extract)" matTooltip="Cancel Schedule">
            <i class="fas fa-times"></i>
          </a>
          
          <a data-test="grid-icon"
            [ngClass]="{'disabled-link': (extract.depScheduleStatusId === depScheduleStatusIdType.Active || extract.depScheduleStatusId === depScheduleStatusIdType.Expired)}"
            class="delete-btn" (click)="deleteExtract(extract)" matTooltip="Delete Schedule">
            <i class='fas fa-trash'></i>
          </a>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="['name', 'description', 'depScheduleStatusStatus', 'depFrequencyCodeId', 'frequencyStartDt', 'frequencyEndDt', 'actions']"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: ['name', 'description', 'depScheduleStatusStatus', 'depFrequencyCodeId', 'frequencyStartDt', 'frequencyEndDt', 'actions']"></mat-row>
  </mat-table>

  <mat-paginator #paginator [length]="totalItems" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
    (page)="onPageChange($event)"></mat-paginator>
</div>