<ng-container [formGroup]="filterForm">

    <mat-expansion-panel #panelInner expanded (opened)="expandPanel()" (closed)="collapsePanel()">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{filterForm.controls.uiHeaderText.value ? filterForm.controls.uiHeaderText.value : filterForm.controls.uiName.value}}
        </mat-panel-title>
        <mat-panel-description>
          {{filterForm.controls.uiDescription.value}}
        </mat-panel-description>
      </mat-expansion-panel-header>
      
      <table>
        <tr>
          <td style="width: 225px">
            <label> {{filterForm.controls.uiName.value}}</label>
          </td>
          <td style="width: 500px">
            <app-filter-form-field-selection
              *ngIf="
                filterForm.get('depFilterCodeId').value === depFilterCodeIdType.SINGLE_UI_SELECTION || 
                filterForm.get('depFilterCodeId').value === depFilterCodeIdType.MULTI_UI_SELECTION
              "
              [filterForm]="filterForm"
            ></app-filter-form-field-selection>

            <app-filter-form-field-text-or-date-with-selection
              *ngIf="
                filterForm.get('depFilterCodeId').value === depFilterCodeIdType.STRING_EQUAL ||
                filterForm.get('depFilterCodeId').value === depFilterCodeIdType.STRING_CONTAINS ||
                filterForm.get('depFilterCodeId').value === depFilterCodeIdType.TEXT_MULTI_SELECTION ||
                filterForm.get('depFilterCodeId').value === depFilterCodeIdType.MULTI_SELECTION ||
                filterForm.get('depFilterCodeId').value === depFilterCodeIdType.INT_EQUAL ||
                filterForm.get('depFilterCodeId').value === depFilterCodeIdType.STRING_MULTI || 
                filterForm.get('depFilterCodeId').value === depFilterCodeIdType.INT_MULTI ||
                filterForm.get('depFilterCodeId').value === depFilterCodeIdType.DATE_EQUAL ||
                filterForm.get('depFilterCodeId').value === depFilterCodeIdType.DATE_RANGE ||
                filterForm.get('depFilterCodeId').value === depFilterCodeIdType.DATE_MULTI
              "
              [filterForm]="filterForm"
            ></app-filter-form-field-text-or-date-with-selection>            
          </td>
        </tr>
      </table>
    </mat-expansion-panel>
</ng-container>
