<ng-container [formGroup]="fieldsTabContentForm">

  <div class="dual-listbox">
    <div>
      <div>
        <mat-form-field >
          <mat-label>Search</mat-label>
          <input 
            matInput 
            placeholder="Search fields. . ." 
            formControlName="search"
          />
        </mat-form-field>
      </div>

      <label>Available fields:</label>
      <div>
        <mat-selection-list 
          class="field-selection-list" 
          [multiple]="true" 
          formControlName="selectedAvailableDatasetFields"
        >
          <mat-list-option 
            class="field-list-option"
            *ngFor="let availableDatasetField of availableDatasetFields;" 
            [value]="availableDatasetField"
            [ngClass]="{ 'selected-dataset-field': selectedAvailableDatasetFields.includes(availableDatasetField.uiName) }"
            (click)="toggleAvailableFieldSelection(availableDatasetField.uiName)"
            cdkDrag 
            [@slideStatus]="selectedAvailableDatasetFields.includes(availableDatasetField.uiName) ? 'inactive': 'active'"
          >
          <span> {{ availableDatasetField.uiName }} <span *ngIf="availableDatasetField.mandatoryField" class="redStar">*</span></span>
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>

    <div class="button-group">
      <button mat-raised-button class="move-button" [disabled]="isViewEnabled" (click)="moveAllDatasetFieldsToRight()">
        &gt;&gt;
      </button>

      <button mat-raised-button class="move-button" [disabled]="isViewEnabled" (click)="moveDatasetFieldsToRight()">
        &gt;
      </button>

      <br />

      <button mat-raised-button class="move-button" [disabled]="isViewEnabled" (click)="moveDatasetFieldsToLeft()">
        &lt;
      </button>

      <button mat-raised-button class="move-button" [disabled]="isViewEnabled" (click)="moveAllDatasetFieldsToLeft()">
        &lt;&lt;
      </button>
    </div>

    <div class="included-offset">
      <label>Included fields: <span class="redStar">{{requiredIncludedDatasetFieldsTab?.value}}</span></label>
      <div>
        <mat-selection-list 
          class="field-selection-list" 
          [multiple]="true" 
          formControlName="selectedIncludedDatasetFields"
          cdkDropList 
          (cdkDropListDropped)="drop($event)"
        >
          <mat-list-option 
            class="field-list-option"
            *ngFor="let includedDatasetField of includedDatasetFields;" 
            [value]="includedDatasetField"
            [ngClass]="{ 'selected-dataset-field': selectedIncludedDatasetFields.includes(includedDatasetField.uiName) }"
            (click)="toggleIncludedFieldSelection(includedDatasetField.uiName)"
            cdkDrag 
            [@slideStatus]="selectedIncludedDatasetFields.includes(includedDatasetField.uiName) ? 'inactive': 'active'"
          >
            <span>{{ includedDatasetField.uiName }} <span *ngIf="includedDatasetField.mandatoryField" class="redStar">*</span></span>
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
  </div>

</ng-container>
