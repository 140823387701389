<div data-test="title" class="viz-h3 justify-space-between">
  {{ datasetName }}
</div>

<br />

<div data-test="sub-title" class="viz-body-1">
  {{ datasetDescription }}
</div>

<br />

<mat-spinner class="floating-spinner" *ngIf="loading$ | async"></mat-spinner>

<ng-container *ngIf="(loading$ | async) === false && this.resetflag">
  <form [formGroup]="form" (change)="onChanges()">
    <mat-horizontal-stepper linear #stepper (selectionChange)="onStepChange($event)" >
      <mat-step [stepControl]="secondFormGroup" [completed]="stepSetupCompleted"><!-- Setup -->
        <ng-template matStepLabel>Setup</ng-template>
        <div class="name-tab">

          <ng-container *ngIf="datasetIsClientIdBased(dataset); else memberIdSelect">
            <mat-form-field>
              <br />
              <mat-label>Client</mat-label>
              <mat-select formControlName="memberId" (selectionChange)="updateDatasetFieldMultiValues()">
                <mat-option *ngFor="let client of clients | async" [value]="client.value">
                  {{client.uiValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
          <ng-template #memberIdSelect>
            <mat-form-field>
              <br />
              <mat-label>Member</mat-label>
              <mat-select formControlName="memberId" (selectionChange)="updateDatasetFieldMultiValues()">
                <mat-option *ngFor="let member of members | async" [value]="member.member_id">
                  {{ member.member_name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-template>

          <mat-form-field>
            <mat-label>Extract name</mat-label>
            <input
              matInput
              placeholder="Extract name"
              formControlName="extractName"
              (input)="updateFileNameOnOutputStepForm($event)"
            />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Notes</mat-label>
            <textarea
              matInput
              placeholder=""
              formControlName="notes"
            ></textarea>
          </mat-form-field>

          <mat-card
            class="example-card"
            *ngIf="dataSetRollingDates?.length > 1"
          >
            <mat-card-subtitle>Rolling dates</mat-card-subtitle>
            <mat-card-content>
              <mat-radio-button
                class="tdRightPadding"
                [checked]="isCheckedRollingDateOneTime"
                (change)="selectRollingDates()"
                [disabled]="isViewEnabled"
                >One time</mat-radio-button
              >
              <mat-form-field appearance="fill" class="datePickerPaddingLeft">
                <mat-label>Enter a date range</mat-label>
                <mat-date-range-input
                  [formGroup]="range"
                  [min]="minDate"
                  [rangePicker]="picker"
                  [disabled]="isDisableDateRange"
                >
                  <input
                    matStartDate
                    formControlName="start"
                    placeholder="Start date"
                    (dateChange)="onDateChange()"
                  />
                  <input
                    matEndDate
                    formControlName="end"
                    placeholder="End date"
                    (dateChange)="onDateChange()"
                  />
                </mat-date-range-input>
                <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              </mat-form-field>
              <br />
              <br />
              <mat-radio-button
                class="tdRightPadding"
                [checked]="isCheckedRollingDateRecurring"
                (change)="selectRecurringDates()"
                [disabled]="isViewEnabled"
                >Recurring</mat-radio-button
              >
              <mat-form-field class="datePickerPaddingLeft" appearance="fill">
                <mat-label>Rolling date</mat-label>
                <mat-select
                  formControlName="rollingDateType"
                  [disabled]="isDisableRollingDate"
                  (selectionChange)="rollingDateRecurringTypeChange()"
                >
                  <mat-option
                    *ngFor="let rollingDate of dataSetRollingDates"
                    [value]="rollingDate.depRollingDateFrequencyCodeId"
                  >
                    {{ rollingDate.uiName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-radio-button
                class="tdRightPadding tdTopPadding"
                [checked]="isCheckedRollingDateRecurring"
                (change)="selectNoDates()"
                *ngIf="!isViewEnabled"
                >None</mat-radio-button
              >
            </mat-card-content>
          </mat-card>
        </div>

        <div>
          <button mat-button matStepperNext>Next</button>
        </div>
      </mat-step>

      <mat-step label="Schedule" [stepControl]="secondFormGroup" [completed]="stepScheduleCompleted">
        <app-schedule
          formControlName="scheduleStepForm"
          [frequencies]="frequencies"
          [scheduleDepFrequencyCodeId]="scheduleDepFrequencyCodeId"
        ></app-schedule>
        <div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </mat-step>

      <mat-step label="Fields" [stepControl]="secondFormGroup" [completed]="stepFieldsCompleted">
        <mat-tab-group appHeaderLessTabs [showHeaderTabs]="subDatasets.length !== 1">
          <mat-tab
            *ngFor="let subDataset of subDatasets; let i = index"
          >
            <ng-template mat-tab-label >
              <div [id]="'fields-tab-header-' + i">
                {{subDataset.uiName}}
              </div>
            </ng-template>

            <app-fields-tab-content
              [datasetFields]="datasetFieldsBySubDatasetId.get(subDataset.subDatasetId)"
              [subDataset]="subDataset"
              [subDatasetDefinition]="getSubDatasetDefinition(subDataset.subDatasetId)"
              [requiredIncludedDatasetFieldsTab]="requiredIncludedDatasetFieldsTabs[i]"
              [formControlName]="'fieldsTabContentForm' + i"
            ></app-fields-tab-content>
          </mat-tab>
        </mat-tab-group>

        <div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button (click)="moveToNextStep('Fields')" matStepperNext>Next</button>
        </div>
      </mat-step>

      <mat-step label="Filters" [stepControl]="secondFormGroup" [completed]="stepFiltersCompleted">
        <mat-tab-group appHeaderLessTabs [showHeaderTabs]="subDatasets.length !== 1">
          <mat-tab
            *ngFor="let filtersTabLabel of getFiltersTabsLabels(); let i = index"
            [label]="filtersTabLabel"
          >
            <app-filters-tab-content
              [isCommon]="filtersTabLabel === commonTabLabel"
              [datasetFields]="getFiltersTabContentDatasetFields(filtersTabLabel)"
              [subdataset]="getFiltersTabContentSubDataset(filtersTabLabel)"
              [datasetFieldsUserDefinition]="getFiltersTabContentDatasetFieldsUserDefinition(filtersTabLabel)"
              [formControlName]="getFiltersTabContentFormControlName(filtersTabLabel, i)"
            ></app-filters-tab-content>
          </mat-tab>
        </mat-tab-group>

        <div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button (click)="moveToNextStep('Filters')" class="custom-disabled" matStepperNext>
            Next
          </button>
        </div>
      </mat-step>

      <mat-step label="Delivery" [stepControl]="secondFormGroup" [completed]="stepOutputCompleted">
        <br />
        <app-output-channel
          formControlName="outputStepForm"
          [datasetId]="datasetId"
          [schedule]="schedule"
          [channels]="channels"
          [fileTypes]="fileTypes"
          [fileCompressionFormats]="fileCompressionFormats"
        ></app-output-channel>
        <div>
          <button mat-button matStepperPrevious>Back</button>
          <button *ngIf="!isViewEnabled" mat-button matStepperNext>Next</button>
        </div>
      </mat-step>

      <mat-step *ngIf="!isViewEnabled" [editable]="false">
        <ng-template matStepLabel>Summary</ng-template>
        <div>
          <br />
          <label class="summary-header">Extract name</label>
          <hr />
          <ng-container *ngFor="let item of summaryStepView">
            <ng-container *ngIf="item.key === 'extractName'">
              <label class="summary-value padding-left20">{{item.value}}</label>
            </ng-container>
          </ng-container>

          <br />
          <br />
          <label class="summary-header">Notes</label>
          <hr />
          <ng-container *ngFor="let item of summaryStepView">
            <ng-container *ngIf="item.key === 'notes'">
              <label class="summary-value padding-left20">{{item.value}}</label>
            </ng-container>
          </ng-container>

          <!--This is temporary, the products which are live do not want to see it until the rolling date is in general availability-->
          <!--
          <br />
          <br />
          <label class="summary-header">Rolling date filter</label>
          <hr />
          <ng-container *ngFor="let item of summaryStepView">
            <ng-container *ngIf="item.key === 'rollingDateType'">
              <label class="summary-value padding-left20" *ngIf="item.value"
                >Recurring :</label
              >
              <label class="summary-value padding-left20">{{item.value}}</label>
            </ng-container>
            <ng-container *ngIf="item.key === 'rollingDateRange'">
              <label class="summary-value padding-left20" *ngIf="item.value"
                >One Time :</label
              >
              <label class="summary-value padding-left20">{{item.value}}</label>
            </ng-container>
          </ng-container>
          -->

          <br />
          <br />
          <label class="summary-header">Member</label>
          <hr />
          <ng-container *ngFor="let item of summaryStepView">
            <ng-container *ngIf="item.key === 'memberId'">
              <label class="summary-value padding-left20">{{this.memberShortName}}</label>
            </ng-container>
          </ng-container>

          <br />
          <br />
          <label class="summary-header">Schedule</label>
          <hr />
          <ng-container *ngFor="let item of summaryStepView">
            <ng-container *ngIf="item.key === 'scheduleRadioValue'">
              <label class="summary-label">Frequency:</label>
              <label class="summary-value">{{ item.value }}</label>
            </ng-container>
          </ng-container>
          <ng-container *ngFor="let item of summaryStepView">
            <ng-container
              *ngIf="
                item.key === 'selectedDateOneTime' &&
                item.value !== '' &&
                item.value !== null &&
                item.value !== undefined
              "
            >
              <br />
              <label class="summary-label">Selected date one time:</label>
              <label class="summary-value">{{item.value | date : "shortDate"}}</label>
            </ng-container>
          </ng-container>

          <ng-container *ngFor="let item of summaryStepView">
            <ng-container
              *ngIf="
                item.key === 'selectedDateDaily' &&
                item.value !== '' &&
                item.value !== null &&
                item.value !== undefined
              "
            >
              <br />
              <label class="summary-label">Selected date daily:</label>
              <label class="summary-value">{{item.value | date : "shortDate"}}</label>
            </ng-container>
          </ng-container>

          <ng-container *ngFor="let item of summaryStepView">
            <ng-container
              *ngIf="
                item.key === 'selectedDatQuarterly' &&
                item.value !== '' &&
                item.value !== null &&
                item.value !== undefined
              "
            >
              <br />
              <label class="summary-label">Selected date quarterly:</label>
              <label class="summary-value">{{item.value | date : "shortDate"}}</label>
            </ng-container>
          </ng-container>

          <ng-container *ngFor="let item of summaryStepView">
            <ng-container
              *ngIf="
                item.key === 'selectedDatMonthly' &&
                item.value !== '' &&
                item.value !== null &&
                item.value !== undefined
              "
            >
              <br />
              <label class="summary-label">Selected date monthly:</label>
              <label class="summary-value">{{item.value | date : "shortDate"}}</label>
            </ng-container>
          </ng-container>

          <br />
          <br />

          <label class="summary-header">Output</label>
          <hr />
          <ng-container *ngFor="let item of summaryStepView">
            <ng-container *ngIf="item.key === 'channelName' && item.value">
              <label class="summary-label">Channel:</label>
              <label class="summary-value">{{ item.value }} </label>
              <br />
            </ng-container>

            <ng-container *ngIf="item.key === 'fileType' && item.value">
              <label class="summary-label">File type:</label>
              <label class="summary-value">{{ item.value }}</label>
              <br />
            </ng-container>

            <ng-container *ngIf="item.key === 'fileCompressionFormat' && item.value">
              <label class="summary-label">File compression format</label>
              <label class="summary-value">{{ item.value }}</label>
              <br />
            </ng-container>

            <ng-container *ngIf="item.key === 'fileName' && item.value">
              <label class="summary-label">File name prefix:</label>
              <label class="summary-value">{{ item.value }}</label>
              <br />
            </ng-container>
          </ng-container>
          <br />

          <div style="display: flex; width: 100%">
            <div style="width: 50%">
              <label class="summary-header">Included fields</label>
              <hr />
              <div class="includedFields-container">
                <ng-container *ngFor="let item of summaryStepView">
                  <ng-container *ngIf="item.key === 'subDatasetNameField'">
                    <label class="summary-label">{{ item.value }}</label>
                    <br />
                  </ng-container>
                  <div *ngIf="item.key === 'includedField'">
                    <label class="summary-value included-field">
                      {{ item.value }}</label
                    >
                  </div>
                </ng-container>
              </div>
            </div>
            <div style="width: 50%">
              <label class="summary-header">Included filters</label>
              <hr />
              <div class="includedFields-container">
                <ng-container *ngFor="let item of summaryStepView">
                  <ng-container *ngIf="item.key === 'subDatasetNameFilter'">
                    <label class="summary-label">{{ item.value }}</label>
                  </ng-container>
                  <div *ngIf="item.key === 'datasetfilter'">
                    <label class="summary-value included-field"
                      >{{ item.value.uiName }}:
                      {{ item.value.userValue }}</label
                    >
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <br />
        <div>
          <button mat-button type="button" (click)="saveSchedule()">
            {{ saveDraftButtonText }}
          </button>
          <button mat-button type="button" (click)="submitSchedule()">
            Submit
          </button>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button (click)="resetForm()">Reset</button>
        </div>
        <div style="text-align: center; width: 100%">
          <label>{{ responseMessage }}</label>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </form>
</ng-container>
